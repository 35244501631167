import { userConstants, UserCRUDConstants } from "../constants/userConstants";
import { userService } from "../services/userService";
import { constants } from "../constants";
import { actionLoadReason, actionLoadOccupation, actionLoadStatus, actionLoadPractice } from "./lookupAction";
import { toast } from 'react-toastify';

export const actionLoginRequest = (userName, password) => {
    return {
        type: userConstants.LOGIN_REQUEST,
        payload: { userName: userName, password: password }
    };
}

export const actionLoginSuccess = (user) => {
    return {
        type: userConstants.LOGIN_SUCCESS,
        payload: user
    };
}

export const actionLoginFailure = (errData) => {
    return {
        type: userConstants.LOGIN_FAILURE,
        payload: errData
    };
}

export const actionChangePasswordRequest = (userName, newPassword, oldPassword, fromEmail) => {
    return {
        type: userConstants.CHANGE_PASSWORD_REQUEST,
        payload: { userName: userName, newPassword: newPassword, oldPassword: oldPassword, fromEmail: fromEmail }
    };
}

export const actionChangePasswordSuccess = (user) => {
    return {
        type: userConstants.CHANGE_PASSWORD_SUCCESS,
        payload: user
    };
}

export const actionChangePasswordFailure = (errData) => {
    return {
        type: userConstants.CHANGE_PASSWORD_FAILURE,
        payload: errData
    };
}

export const actionForgotPasswordRequest = (userName) => {
    return {
        type: userConstants.CHANGE_PASSWORD_REQUEST,
        payload: { userName: userName }
    };
}

export const actionForgotPasswordSuccess = (user) => {
    return {
        type: userConstants.CHANGE_PASSWORD_SUCCESS,
        payload: user
    };
}

export const actionForgotPasswordFailure = (errData) => {
    return {
        type: userConstants.CHANGE_PASSWORD_FAILURE,
        payload: errData
    };
}

export const actionResetPasswordRequest = (userName) => {
    return {
        type: userConstants.RESET_PASSWORD_REQUEST,
        payload: {userName: userName}
      };
}

export const actionResetPasswordSuccess = (user) => {
    return {
        type: userConstants.RESET_PASSWORD_SUCCESS,
        payload: user
      };
}

export const actionResetPasswordFailure = (errData) => {
    return {
        type: userConstants.RESET_PASSWORD_FAILURE,
        payload: errData
      };
}

export const actionLogoutRequest = (userId) => {
    return {
        type: userConstants.LOGOUT_REQUEST,
        payload: { userId: userId }
    };
}

export const actionLogoutSuccess = (user) => {
    return {
        type: userConstants.LOGOUT_SUCCESS,
        payload: user
    };
}

export const actionLogoutFailure = (errData) => {
    return {
        type: userConstants.LOGOUT_FAILURE,
        payload: errData
    };
}

export const actionGetUserRequest = (userId) => {
    return {
        type: UserCRUDConstants.GET_USER_REQUEST,
        payload: { userId: userId }
    };
}

export const actionGetUserSuccess = (user) => {
    return {
        type: UserCRUDConstants.GET_USER_SUCCESS,
        payload: user
    };
}

export const actionGetUserFailure = (errData) => {
    return {
        type: UserCRUDConstants.GET_USER_FAILURE,
        payload: errData
    };
}

export const actionGetUserByUserNameRequest = (userId) => {
    return {
        type: UserCRUDConstants.GET_USER_BY_USERNAME_REQUEST,
        payload: { userId: userId }
    };
}

export const actionGetUserByUserNameSuccess = (user) => {
    return {
        type: UserCRUDConstants.GET_USER_BY_USERNAME_SUCCESS,
        payload: user
    };
}

export const actionGetUserByUserNameFailure = (errData) => {
    return {
        type: UserCRUDConstants.GET_USER_BY_USERNAME_FAILURE,
        payload: errData
    };
}

export const actionImageUploadRequest = (userId) => {
    return {
        type: userConstants.IMAGE_UPLOAD_REQUEST,
        payload: { userId: userId }
    };
}

export const actionImageUploadSuccess = (user) => {
    return {
        type: userConstants.IMAGE_UPLOAD_SUCCESS,
        payload: user
    };
}

export const actionImageUploadFailure = (errData) => {
    return {
        type: userConstants.IMAGE_UPLOAD_FAILURE,
        payload: errData
    };
}

export const actionPageRedirect = (callback, page) => {
    if( callback ) {
        callback(page);
    }    
    return {
        type: constants.PAGE_REDIRECT,
        payload: ''
    };
}

export const actionClearState = () => {
    localStorage.clear();
    return {
        type: constants.CLEAR_STATE,
        payload: ''
    };
}

export const actionSetSuccessMessage = (message) => {
    return {
        type: constants.SET_SUCCESS_MESSAGE,
        payload: message
    };
}

export const actionLogin = (userName, password, callback) => {
    return (dispatch) => {
        dispatch(actionLoginRequest(userName, password));
        userService.login(userName, password).then(response => {
            if(response.status == 200 || response.status == 201){
                response.json().then(parsedData=>{
                    if(null != parsedData && parsedData.approved === false ) {
                        dispatch(actionLoginFailure(parsedData.message))
                        //dispatch(actionPageRedirect(callback, constants.LOGIN_PAGE));
                    }else if(null != parsedData && parsedData.changePasswordRedirectRequired) {
                        dispatch(actionSetSuccessMessage(''))
                        dispatch(actionPageRedirect(callback, constants.CHANGE_PASSWORD_PAGE));
                    } else {
                        dispatch(actionLoginSuccess(parsedData));
                        const allowedRoles = [constants.SUPER_ADMIN_ROLE, constants.USER_ADMIN_ROLE];
                        if(allowedRoles.includes(parsedData.data.roleId)) {
                            dispatch(actionPageRedirect(callback, constants.DASHBOARD_PAGE));
                        } else {
                            dispatch(actionPageRedirect(callback, constants.LIST_PATIENT_PAGE));
                        }
                    }
                });
            } else {
                response.json().then(parsedData => {
                    dispatch(actionLoginFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}

export const actionChangePassword = (userName,oldPassword, newPassword, fromEmail, callback, redirectToLogin, rCallback) => {
    return (dispatch, getState) => {
        dispatch(actionChangePasswordRequest(userName?userName:getState().user.userName, newPassword, oldPassword, fromEmail))
        userService.changePassword(userName?userName:getState().user.userName, newPassword, oldPassword, fromEmail).then(response => {
            if (response.status == 200) {
                response.json().then(parsedData => {                  
                    if(redirectToLogin && getState().user.detail.firstName && getState().user.detail.firstName.trim().length > 0 ) {
                        toast("Redirecting to login",{
                            onClose:  setTimeout(function() { cleanStateAndRedirect(parsedData,dispatch,callback);},5000)
                        })                        
                       
                    } else {
                        cleanStateAndRedirect(parsedData,dispatch,callback);
                        callback()
                    }
                })
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionChangePasswordFailure(parsedData.message));                    
                    callback();
                });
            }
            
        }).catch(err => {
        });
    }
}

export const cleanStateAndRedirect = (parsedData,dispatch,callback) =>{
    console.log("(((((((((((((((((((((((((((((((((((((((((((((())))))))))))))))))))))))))))))")
    dispatch(actionChangePasswordSuccess(parsedData));
    dispatch(actionClearState());
    dispatch(actionPageRedirect(callback, constants.LOGIN_PAGE));
}

export const cleanStateAndRedirectToLogin = (parsedData, dispatch, callback) =>{    
    toast("Invalid session.Redirecting to login",{
        onClose:  setTimeout(function() { 
            dispatch(actionClearState());
            dispatch(actionPageRedirect(callback, constants.LOGIN_PAGE));
        },4000)
    });    
}

export const cleanStateAndBackToLogin = (parsedData, dispatch, callback) =>{ 
    dispatch(actionClearState());
    callback();   
}

export const actionForgotPassword = (userName, callback) => {
    return (dispatch) => {
        dispatch(actionForgotPasswordRequest(userName))
        userService.forgotPassword(userName).then(response => {
            if (response.status == 200) {
                response.json().then(parsedData => {
                    dispatch(actionPageRedirect(callback, constants.LOGIN_PAGE))
                    dispatch(actionClearState());
                    dispatch(actionForgotPasswordSuccess(parsedData));                   
                    dispatch(actionSetSuccessMessage(parsedData.message))
                    
                })
            } else {
                response.json().then(parsedData => {
                    dispatch(actionForgotPasswordFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}

export const actionLogout = (callback) => {
    return (dispatch, getState) => {
        if (!getState().user.detail) {
            dispatch(actionClearState());
            dispatch(actionPageRedirect(callback, constants.LOGIN_PAGE));
            return;
        }
        const userId = getState().user.detail.userId;
        dispatch(actionLogoutRequest(userId))
        userService.logout(userId).then(response => {
            if (response.status == 200) {
                cleanStateAndBackToLogin(response, dispatch, callback);
            } else {
                response.json().then(parsedData => {
                    dispatch(actionLogoutFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}

export const actionGetUser = (userId, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionGetUserRequest(userId))
        userService.getUser(userId).then(response => {
            if (response.status == 200) {
                response.json().then(parsedData => {
                    dispatch(actionGetUserSuccess(parsedData))
                    callback()
                })
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionGetUserFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}

export const actionImageUpload = (file, user, callback, redirectToLoginCallback) => {
    return (dispatch) => {
        dispatch(actionImageUploadRequest(user.detail.userId))
        userService.uploadImage(file, user).then(response => {
            if (response.status == 200) {
                response.json().then(parsedData => {
                    dispatch(actionImageUploadSuccess(parsedData))
                    if(callback)
                        callback()
                })
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, redirectToLoginCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionImageUploadFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}

export const actionAuthenticate = () => {
    return (dispatch, getState) => {
        const userName = getState().user.detail.emailAddress
        dispatch(actionGetUserByUserNameRequest(userName))
        userService.getUserByUserName(userName).then(response => {
            if (response.status == 200) {
                response.json().then(parsedData => {
                    dispatch(actionGetUserByUserNameSuccess(parsedData))
                })
            } else {
                response.json().then(parsedData => {
                    dispatch(actionGetUserByUserNameFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}

export const actionResetPassword = (callback) => {
    return (dispatch, getState) => {
        const userName = getState().user.userEntity.emailAddress
        dispatch(actionResetPasswordRequest(userName))
        userService.resetPassword(userName).then(response => {
            if(response.status == 200){
                response.json().then(parsedData=>{
                    dispatch(actionResetPasswordSuccess(parsedData))
                    callback()
                })
            } else {
                response.json().then(parsedData=>{
                    dispatch(actionResetPasswordFailure(parsedData.message));
                });
            }
        }).catch(err=>{
        });
    }
}
export const actionLoadLookUp = () => {
    return (dispatch) => {
        dispatch(actionLoadReason())
        dispatch(actionLoadOccupation())
        dispatch(actionLoadPractice())
        dispatch(actionLoadStatus())
    }
}
export const actionUserMenuRequest = (menuKey) =>{
    return {
        type: UserCRUDConstants.USER_MENU_REQUEST,
        payload: menuKey
    };
}
export const actionUserMenu = (menuKey, callback) =>{
    /* let searchUser= {
        query: '',
        pagesize: 10,
        page: 0,
        userStatusType: parseInt(menuKey)
      }
    return actionSearchUsers(searchUser, menuKey, undefined, ()=>{

    }); */
    
    return (dispatch) => {
        dispatch(actionUserMenuRequest(menuKey));
        //callback(constants.LIST_USER_PAGE);
        dispatch(actionPageRedirect(callback, constants.LIST_USER_PAGE));
    }
     
   
}

export const actionaddNewUserRequest = (user) => {
    return {
        type: UserCRUDConstants.ADD_USER_REQUEST,
        payload: user
    };
}

export const actionaddNewUserSuccess = (user) => {
    return {
        type: UserCRUDConstants.ADD_USER_SUCCESS,
        payload: user
    };
}

export const actionaddNewUserFailure = (errData) => {
    return {
        type: UserCRUDConstants.ADD_USER_FAILURE,
        payload: errData
    };
}

export const addNewUser = (user, callback, redirectToLoginCallback) => {
    return (dispatch) => {
        dispatch(actionaddNewUserRequest(user));
        userService.addNewUser(user).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    dispatch(actionaddNewUserSuccess(parsedData));
                    callback(constants.LIST_USER_PAGE);
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, redirectToLoginCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionaddNewUserFailure(parsedData.message));
                    callback("");
                });
            }
        }).catch(err => {
        });
    }
}

export const actionSignupUserRequest = (user) => {
    return {
        type: UserCRUDConstants.SIGNUP_USER_REQUEST,
        payload: user
    };
}

export const actionSignupUserSuccess = (user) => {
    return {
        type: UserCRUDConstants.SIGNUP_USER_SUCCESS,
        payload: user
    };
}

export const actionSignupUserFailure = (errData) => {
    return {
        type: UserCRUDConstants.SIGNUP_USER_FAILURE,
        payload: errData
    };
}
export const signupUser = (user, callback) => {
    return (dispatch) => {
        dispatch(actionSignupUserRequest(user));
        userService.signupUser(user).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    dispatch(actionSignupUserSuccess(parsedData));
                    callback(constants.LOGIN_PAGE);
                });
            } else {
                response.json().then(parsedData => {
                    dispatch(actionSignupUserFailure(parsedData.message));
                    callback("");
                });
            }
        }).catch(err => {
        });
    }
}

export const actionSearchUsersRequest = (user) => {
    return {
        type: UserCRUDConstants.SEARCH_USER_REQUEST,
        payload: user
    };
}

export const actionSearchUsersSuccess = (user) => {
    return {
        type: UserCRUDConstants.SEARCH_USER_SUCCESS,
        payload: user
    };
}

export const actionSearchUsersFailure = (errData) => {
    return {
        type: UserCRUDConstants.SEARCH_USER_FAILURE,
        payload: errData
    };
}

export const actionSearchUsers = (searchObject, callback, redirectToLoginCallback) => {
    return (dispatch, getState) => {
        if (getState().user.token){
            dispatch(actionSearchUsersRequest(searchObject));
        userService.searchUsers(searchObject).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionSearchUsersSuccess(parsedData));
                    //callback(constants.DASHBOARD_PAGE);
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                   // dispatch(actionSearchUsersFailure(parsedData.message));
                    cleanStateAndRedirectToLogin(parsedData, dispatch, redirectToLoginCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionSearchUsersFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
        }      

    }
}

export const actionDeleteUserByIdRequest = (user) => {
    return {
        type: UserCRUDConstants.DELETE_USER_REQUEST,
        payload: user
    };
}

export const actionDeleteUserByIdSuccess = (user) => {
    return {
        type: UserCRUDConstants.DELETE_USER_SUCCESS,
        payload: user
    };
}

export const actionDeleteUserByIdFailure = (errData) => {
    return {
        type: UserCRUDConstants.DELETE_USER_FAILURE,
        payload: errData
    };
}

export const actionDeleteUserById = (userId, callback, redirectToLoginCallback) => {
    return (dispatch) => {
        dispatch(actionDeleteUserByIdRequest(userId));
        userService.deleteUserById(userId).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionDeleteUserByIdSuccess(parsedData));
                    callback(response);
                    //callback(constants.DASHBOARD_PAGE);
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, redirectToLoginCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionDeleteUserByIdFailure(parsedData.message));
                    callback(response);
                });
            }
        }).catch(err => {
            callback(err);
        });
    }
}

export const actionDisableUserByIdRequest = (user) => {
    return {
        type: UserCRUDConstants.DISABLE_USER_REQUEST,
        payload: user
    };
}

export const actionDisableUserByIdSuccess = (user) => {
    return {
        type: UserCRUDConstants.DISABLE_USER_SUCCESS,
        payload: user
    };
}

export const actionDisableUserByIdFailure = (errData) => {
    return {
        type: UserCRUDConstants.DISABLE_USER_FAILURE,
        payload: errData
    };
}
export const actionDisableUserById = (userId, callback, redirectToLoginCallback) => {
    return (dispatch) => {
        dispatch(actionDisableUserByIdRequest(userId));
        userService.disableUserById(userId).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionDisableUserByIdSuccess(parsedData));
                    callback(response);
                    //callback(constants.DASHBOARD_PAGE);
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, redirectToLoginCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionDisableUserByIdFailure(parsedData.message));
                    callback(response);
                });
            }
        }).catch(err => {
            callback(err);
        });
    }
}

export const actionEnableUserByIdRequest = (user) => {
    return {
        type: UserCRUDConstants.DISABLE_USER_REQUEST,
        payload: user
    };
}

export const actionEnableUserByIdSuccess = (user) => {
    return {
        type: UserCRUDConstants.ENABLE_USER_SUCCESS,
        payload: user
    };
}

export const actionEnableUserByIdFailure = (errData) => {
    return {
        type: UserCRUDConstants.ENABLE_USER_FAILURE,
        payload: errData
    };
}
export const actionEnableUserById = (userId, callback, redirectToLoginCallback) => {
    return (dispatch) => {
        dispatch(actionEnableUserByIdRequest(userId));
        userService.enableUserById(userId).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionEnableUserByIdSuccess(parsedData));
                    callback(response);
                    //callback(constants.DASHBOARD_PAGE);
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, redirectToLoginCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionEnableUserByIdFailure(parsedData.message));
                    callback(response);
                });
            }
        }).catch(err => {
            callback(err);
        });
    }
}
export const actionAproveUserByIdRequest = (user) => {
    return {
        type: UserCRUDConstants.APPROVE_USER_REQUEST,
        payload: user
    };
}

export const actionApproveUserByIdSuccess = (user) => {
    return {
        type: UserCRUDConstants.APPROVE_USER_SUCCESS,
        payload: user
    };
}

export const actionApproveUserByIdFailure = (errData) => {
    return {
        type: UserCRUDConstants.APPROVE_USER_FAILURE,
        payload: errData
    };
}
export const actionApproveUserById = (userId, callback, redirectToLoginCallback) => {
    return (dispatch) => {
        dispatch(actionAproveUserByIdRequest(userId));
        userService.approveUserById(userId).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionApproveUserByIdSuccess(parsedData));
                    callback(response);
                    //callback(constants.DASHBOARD_PAGE);
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, redirectToLoginCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionApproveUserByIdFailure(parsedData.message));
                    callback(response);
                });
            }
        }).catch(err => {
            callback(err);
        });
    }
}
export const actionRejectUserByIdRequest = (user) => {
    return {
        type: UserCRUDConstants.REJECT_USER_REQUEST,
        payload: user
    };
}

export const actionRejectUserByIdSuccess = (user) => {
    return {
        type: UserCRUDConstants.REJECT_USER_SUCCESS,
        payload: user
    };
}

export const actionRejectUserByIdFailure = (errData) => {
    return {
        type: UserCRUDConstants.REJECT_USER_FAILURE,
        payload: errData
    };
}
export const actionRejectUserById = (userId, callback, redirectToLoginCallback) => {
    return (dispatch) => {
        dispatch(actionRejectUserByIdRequest(userId));
        userService.rejectUserById(userId).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionRejectUserByIdSuccess(parsedData));
                    callback(response);
                    //callback(constants.DASHBOARD_PAGE);
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, callback, redirectToLoginCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionRejectUserByIdFailure(parsedData.message));
                    callback(response);
                });
            }
        }).catch(err => {
            callback(err);
        });
    }
}
export const actionEditUserRequest = (userId) => {
    return {
        type: UserCRUDConstants.GET_EDITUSER_REQUEST,
        payload: { userId: userId }
    };
}

export const actionEditUserSuccess = (user) => {
    return {
        type: UserCRUDConstants.GET_EDITUSER_SUCCESS,
        payload: user
    };
}

export const actionEditUserFailure = (errData) => {
    return {
        type: UserCRUDConstants.GET_EDITUSER_FAILURE,
        payload: errData
    };
}


export const actionEditGetUser = (userId,callback, redirectToLoginCallback) => {
    return (dispatch) => {
        dispatch(actionEditUserRequest(userId))
        userService.getUser(userId).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    dispatch(actionEditUserSuccess(parsedData))   
                    callback(parsedData);                
                })
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, redirectToLoginCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionEditUserFailure(parsedData.message));
                    callback(response);
                });
            }
        }).catch(err => {
        });
    }
}

export const actionUpdateUserRequest = (userId) => {
    return {
        type: UserCRUDConstants.UPDATE_USER_REQUEST,
        payload: { userId: userId }
    };
}

export const actionUpdateUserSuccess = (user) => {
    return {
        type: UserCRUDConstants.UPDATE_USER_SUCCESS,
        payload: user
    };
}

export const actionUpdateUserFailure = (errData) => {
    return {
        type: UserCRUDConstants.UPDATE_USER_FAILURE,
        payload: errData
    };
}

export const actionUpdateUser = (userId,userObject,redirectPage,callback, rCallback) => {
    return (dispatch, getState) => {        
        dispatch(actionUpdateUserRequest(userObject))
        userService.updateUser(userId,userObject).then(response => {
            if (response.status == 200) {
                response.json().then(parsedData => {
                    dispatch(actionUpdateUserSuccess(parsedData))
                    dispatch(actionPageRedirect(callback,redirectPage));
                })
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionUpdateUserFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}