import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { actionChangePassword } from '../actions/userAction';
import { ChangePassword } from '../components/ChangePassword';


class ChangePasswordContainer extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string
  }
  
  render() {
    return (
      <ChangePassword
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        changePassword={this.props.changePassword}
        params={this.props.location.search}
        loading={this.props.loading}
      />
    )
  }
}
const mapStateToProps = (state) => {
  console.log('State in ChangePasswordContainer : ', state); 
  
  const { errorMessage, successMessage, loading } = state.user;
  return {
    errorMessage,
    successMessage,
    loading
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (userName,oldPassword, newPassword, fromEmail, callback, redirectToLogin) => dispatch(actionChangePassword(userName,oldPassword, newPassword, fromEmail, callback, redirectToLogin))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContainer)