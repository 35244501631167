import React from "react";
import PropTypes from 'prop-types'
import { Link, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import NoEntity from '../../components/NoEntity';
import Modal from 'react-modal';
import { modalcustomStyles } from '../../styles/customStyles';
import { constants } from "../../constants";

class PatientsList extends React.Component {

  constructor(props) {
    super(props)
    console.log("props in component PatientList-->",props)
    this.state = {
      modalIsOpen:false,
      searchObject : {
        patientId:null,
        firstName: '',
        lastName: '',
        currentPatientStatusId: props.statusId && props.statusId.trim().length > 0?parseInt(props.statusId):null,
        createdBy: (this.allowedRoles.includes(this.props.roleId)) ? null : this.props.userId,
        isDeleted : 0,
        page:0,
        pageSize:10
      },
      patientList:[],
      navPage:0
    }
  }
  allowedRoles=[constants.SUPER_ADMIN_ROLE,constants.USER_ADMIN_ROLE];
  static contextTypes = {
    router: PropTypes.object
  }
  componentDidMount() {
    const { match: { params } } = this.props; 
    let searchObject = this.state.searchObject;
    searchObject.page = params.page ? parseInt(params.page):0;
    this.setState({searchObject});
    this.props.fetch(searchObject,this.updateData, this.redirectToTargetWithoutToast);
  }
  redirectToTargetWithoutToast = (target) => {
    this.context.router.history.push(target)
  }
  statusName = (patientData) => {
    const className = (patientData.currentPatientStatusId === 1) ? 'new_patient' : (patientData.currentPatientStatusId === 2) ? 'visited_patient' : (patientData.currentPatientStatusId === 3) ? 'appoinment_sheduled' : 'patient_contacted';  
    const patientStatus = this.props.patientStatuses.find((status) => status.PatientStatusId === patientData.currentPatientStatusId)
    if(patientStatus){
      return <span className={className}>{patientStatus.PatientStatusName}</span>
    }
    return <span>NA</span>
  }
  filterByStatus = (e) => {
    this.state.searchObject.currentPatientStatusId = e.target.value == constants.ALL_STRING ? null : e.target.value;
    this.state.searchObject.page = 0;
    this.state.searchObject.pageSize = 10;
    this.props.fetch(this.state.searchObject,this.updateData, this.redirectToTargetWithoutToast);
  }
  handleChangeSearchPatients = (e) => {
    this.state.searchObject.firstName = e.target.value
    this.state.searchObject.lastName = e.target.value
    this.state.searchObject.emailAddress = e.target.value
  }
  keyPress(e) {
    if (e.keyCode === 13) {
      this.searchPatients(e);
    }
  }
  searchPatients = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.state.searchObject.page = 0;
    this.state.searchObject.pageSize = 10;
    this.props.fetch(this.state.searchObject,this.updateData, this.redirectToTargetWithoutToast);
    
  }
  updateData = () =>{
    this.setState({patientList : this.props.patientsList });
  }
  deletePatient = (e) => {
    this.props.deletePatient(this.state.patientId, this.deleteCallback, this.redirectToTargetWithoutToast);
    this.closeModal();
  }
  deleteCallback = () => {
    if(this.props.errorMessage) {
      toast.error(this.props.errorMessage);
      return
    }
    if(this.props.successMessage) {
      toast.success(this.props.successMessage);
    }
    this.props.fetch(this.state.searchObject,this.updateData);
  }
  openModal(patientId) {
    this.setState({ modalIsOpen: true, patientId: patientId });
  }
  closeModal() {
    this.setState({ modalIsOpen: false});
  }
  handlePageClick = data => {
    this.state.searchObject.page = data.selected;
    this.setState(this.state);
    this.props.fetch(this.state.searchObject,this.updateData, this.redirectToTargetWithoutToast);
  }
  
  render() {
    let endIndex = this.state.searchObject.page * this.state.searchObject.pageSize + this.state.searchObject.pageSize;
    endIndex = (endIndex > this.props.total) ? this.props.total : endIndex;
    return (
      <div>
        <div className="search_panal flex_btwn">
          <div className="col-md-6">
            <form className="form-inline md-form form-sm active-cyan-2 search_bx">
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Search Patients" onChange={(e) => this.handleChangeSearchPatients(e)} onKeyDown={(e) => this.keyPress(e)}/>
                <div className="input-group-append">
                  <button className="btn search-btn" type="submit" onClick={(e)=> this.searchPatients(e)}><img src={require('../../images/search.svg')} /></button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-5 flex_end">
            <div className="form-group margin_0 flex_box custom-filter">
              <label htmlFor="exampleFormControlSelect1" className="mr-3 mb-0">Status</label>
              <select className="form-control" id="patientfilter" placeholder="Patient Status" onChange = {(e) => this.filterByStatus(e)}>
              <option value={null}>All</option>
              { this.props.patientStatuses.map((status, i)=> {
                  return <option key={i} value={status.PatientStatusId}>{status.PatientStatusName}</option>
              })}
              </select>
            </div>
            <div className="col-md-6 flex_end">
              <Link to="/catalogue/add-patient"><button type="button" className="btn btn-primary flex_box">
                <img className="mr-2 w_13" src={require('../../images/add.svg')} />Add Patient</button></Link>
            </div>
            </div>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Referred by</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            { (this.state.patientList && this.state.patientList.length > 0) ?
              this.state.patientList.map(patientData => 
                <tr key={patientData.patientId}>
                  <td><Link to={constants.VIEW_PATIENT_PAGE.replace(':patientId', patientData.patientId+"/"+this.state.searchObject.page)}>{patientData.firstName}&nbsp;{patientData.lastName}</Link></td>
                  <td>{patientData.age}</td>
                  <td>{patientData.gender}</td>
                  <td>{patientData.referredByFirstName ? patientData.referredByFirstName+' '+patientData.referredByLastName : 'NA'}</td>
                  <td>{this.statusName(patientData)}</td>
                  <td><Link to={"/catalogue/add-patient/"+patientData.patientId+"/"+this.state.searchObject.page}><img className="cursor w-30" title="Edit" src={require('../../images/edit.svg')} /></Link>&nbsp;&nbsp;
                  {this.allowedRoles.includes(this.props.roleId) && 
                    <img className="cursor w-30" title="Delete" onClick={(e) => this.openModal(patientData.patientId)} src={require('../../images/delete.svg')} />
                  }
                  </td>
                </tr>)
                :
                <NoEntity message={"No Patients Found.."} colspan={6}/>
            }
          </tbody>
        </table>
        { (this.state.patientList && this.state.patientList.length > 0) &&
          <div className="flex_btwn custom_pagination">
          <div className="total_count">Shows {(this.state.searchObject.page * this.state.searchObject.pageSize)+1} - {endIndex} of {this.props.total} Users </div>
            <ReactPaginate
              previousLabel={'<<'}
              nextLabel={'>>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.props.total / this.state.searchObject.pageSize}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination pagination-sm'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
              initialPage={this.state.searchObject.page}
            />
          </div>
        }
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={()=>this.closeModal()}
            style={modalcustomStyles}
            contentLabel="Delete User">
            <span className="modal-close" onClick={()=>this.closeModal()}><img src={require('../../images/close.svg')} /></span>
            <div className="flex_box deletealert flex_column">
              <span>Are you sure you want to delete the patient?</span>
            </div>
            <div className="flex_box">
            <div className="pad_15 text-center">
                <button type="button" className="btn btn-primary cursor mr-2" onClick={(e) => this.deletePatient(e)}>Yes</button>
                <button type="button" className="btn btn-default m-w-90 cursor" onClick={()=>this.closeModal()}>No</button>
              </div>
            </div>

          </Modal>
        </div>
      </div>
    );
  }

}
export default withRouter(PatientsList);