export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

    IMAGE_UPLOAD_REQUEST: 'IMAGE_UPLOAD_REQUEST',
    IMAGE_UPLOAD_SUCCESS: 'IMAGE_UPLOAD_SUCCESS',
    IMAGE_UPLOAD_FAILURE: 'IMAGE_UPLOAD_FAILURE'
};
export const UserCRUDConstants = {

    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',

    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',

    SIGNUP_USER_REQUEST: 'SIGNUP_USER_REQUEST',
    SIGNUP_USER_SUCCESS: 'SIGNUP_USER_SUCCESS',
    SIGNUP_USER_FAILURE: 'SIGNUP_USER_FAILURE',

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    DISABLE_USER_REQUEST: 'DISABLE_USER_REQUEST',
    DISABLE_USER_SUCCESS: 'DISABLE_USER_SUCCESS',
    DISABLE_USER_FAILURE: 'DISABLE_USER_FAILURE',

    ENABLE_USER_REQUEST: 'ENABLE_USER_REQUEST',
    ENABLE_USER_SUCCESS: 'ENABLE_USER_SUCCESS',
    ENABLE_USER_FAILURE: 'ENABLE_USER_FAILURE',

    APPROVE_USER_REQUEST: 'APPROVE_USER_REQUEST',
    APPROVE_USER_SUCCESS: 'APPROVE_USER_SUCCESS',
    APPROVE_USER_FAILURE: 'APPROVE_USER_FAILURE',

    REJECT_USER_REQUEST: 'REJECT_USER_REQUEST',
    REJECT_USER_SUCCESS: 'REJECT_USER_SUCCESS',
    REJECT_USER_FAILURE: 'REJECT_USER_FAILURE',

    SEARCH_USER_REQUEST: 'SEARCH_USER_REQUEST',
    SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
    SEARCH_USER_FAILURE: 'SEARCH_USER_FAILURE',

    GET_USER_BY_USERNAME_REQUEST: 'GET_USER_BY_USERNAME_REQUEST',
    GET_USER_BY_USERNAME_SUCCESS: 'GET_USER_BY_USERNAME_SUCCESS',
    GET_USER_BY_USERNAME_FAILURE: 'GET_USER_BY_USERNAME_FAILURE',

    GET_EDITUSER_REQUEST: 'GET_EDITUSER_REQUEST',
    GET_EDITUSER_SUCCESS: 'GET_EDITUSER_SUCCESS',
    GET_EDITUSER_FAILURE: 'GET_EDITUSER_FAILURE',

    USER_MENU_REQUEST: 'USER_MENU_REQUEST'
};