import React from 'react';
import { Link, withRouter } from "react-router-dom";
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types'
import { constants } from '../../constants';
import { UserAPI } from '../../constants/apiConstants';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { modalcustomStyles } from '../../styles/customStyles';
import { Icon, Spin, Input } from 'antd';

class UserDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            showOtherInput: false,
            showOccupationList:true,
            isApprove: false,
            isReject: false,
            approvalStatus: 0,
            practiceNameId: 0
        }
        this.deleteUser = this.deleteUser.bind(this);
        this.disableUser = this.disableUser.bind(this);
        this.enableUser = this.enableUser.bind(this);
        this.approveUser = this.approveUser.bind(this);
        this.rejectUser = this.rejectUser.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    rolesList = [
        { value: 2, label: 'Admin' },
        { value: 3, label: 'Doctor' }
    ];
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidMount() {
        const { match: { params } } = this.props;        
        if (params.userId) {
            this.props.fetch(params.userId, this.callbackGetUser, this.redirectToTarget);
        }
    }
    redirectToTargetWithoutToast = (target) => {
        this.context.router.history.push(target)
      }
    callbackGetUser = (resData) => {
        console.log(this.props.userEntity)
        if(this.props.userEntity.occupationId === constants.OTHER_OCCUPATION_ID) {
            this.setState({showOtherInput : true, showOccupationList:false, isReject: (this.props.userEntity.approvalStatus === 0), isApprove: (this.props.userEntity.approvalStatus !==1)});
        }
        if ( this.props.userEntity && this.props.userEntity.practiceNameId ){
            let list = this.props.practices.filter(p => p.PracticeNameId === this.props.userEntity.practiceNameId && p.PracticeName === this.props.userEntity.practiceName);
            if ( list.length <= 0 ){
                this.state.practiceNameId = 0;                
            } else{
                this.state.practiceNameId = this.props.userEntity.practiceNameId;   
            }

            this.setState(this.state)
        }     
    }
    openModal(userId, isDelete, isEnable, isApprove, isReject) {
        this.setState({ modalIsOpen: true, userId: userId, isDelete: isDelete, isEnable: isEnable, isApprove: isApprove, isReject: isReject });
    }
    
    closeModal() {
        this.setState({ modalIsOpen: false });        
    }
    deleteUser = (e) => {
        this.props.deleteUserById(this.state.userId, this.callbackDeleteUserById, this.redirectToTarget);
        this.closeModal();
    }
    callbackDeleteUserById = (responseCallback) => {
        toast.dismiss();
        toast.success("User Deleted Successfully.",{toastId: 1});
        this.redirectToTarget(constants.LIST_USER_PAGE);
    }
    disableUser = (e) => {
        this.props.disableUserById(this.props.userEntity.userId, this.callbackDisableUserById, this.redirectToTarget);
        this.closeModal();
    }
    enableUser = (e) => {
        this.props.enableUserById(this.props.userEntity.userId, this.callbackEnableUserById, this.redirectToTarget);
        this.closeModal();
    }
    approveUser = (e) => {
        this.props.approveUserById(this.props.userEntity.userId, this.callbackApproveUserById, this.redirectToTarget);
        this.closeModal();
    }
    rejectUser = (e) => {
        this.props.rejectUserById(this.props.userEntity.userId, this.callbackRejectUserById, this.redirectToTarget);
        this.closeModal();
    }
    callbackApproveUserById = (responseCallback) => {
        toast.dismiss();
        toast.success("User Approved Successfully.", { toastId: 4 });
        this.props.fetch(this.props.userEntity.userId, this.callbackGetUser, this.redirectToTarget);
    }
    callbackRejectUserById = (responseCallback) => {
        toast.dismiss();
        toast.success("User Rejected Successfully.", { toastId: 5 });
        this.props.fetch(this.props.userEntity.userId, this.callbackGetUser, this.redirectToTarget);
    }
    callbackDisableUserById = (responseCallback) => {
        toast.dismiss();
        toast.success("User Disabled Successfully.",{toastId: 2});
        this.props.fetch(this.props.userEntity.userId, this.callbackGetUser, this.redirectToTarget);
    }
    callbackEnableUserById = (responseCallback) => {
        toast.dismiss();
        toast.success("User Enabled Successfully.",{toastId: 3});
        this.props.fetch(this.props.userEntity.userId, this.callbackGetUser, this.redirectToTarget);
    }
    redirectToTarget = (target) => {       
        this.props.history.push(target)
    }
    getDetailComponent = () => {
        const {userId, firstName, lastName, practiceName, practiceNameId, emailAddress, roleId, phoneNumber, occupationId, otherOccupation, isDisabled, approvalStatus} = this.props.userEntity;
        return <div className="search_panal">
        <div className="panal_header flex_btwn">
            <div className="panal_header_nav d-flex">
                <Link to={"/catalogue/users"}  className="breadcrumb-link"><img className="back-btn mr-3" src={require('../../images/back.svg')} />
                <div className="title-txt primary">User Detail</div>
                </Link>
            </div>
            <div>
            
                  <Link to={"/catalogue/add-user/"+userId}><img className="cursor w-30" title="Edit" src={require('../../images/edit.svg')} /></Link>&nbsp;
                  { approvalStatus === 1 && <img className="cursor w-30" title="Delete" onClick={(e) => this.openModal(userId,true,false,false,false)} src={require('../../images/delete.svg')} />}&nbsp;
                  { isDisabled === 0 && approvalStatus === 1 && <img style={{width:'25px',height:'25px'}} title="Disable" className="cursor" onClick={(e) => this.openModal(userId, false,false,false,false)} src={require('../../images/user_disable.svg')}/>}
                  { isDisabled === 1 && approvalStatus === 1 && <img style={{width:'25px',height:'25px'}} title="Enable" className="cursor" onClick={(e) => this.openModal(userId, false,true,false,false)} src={require('../../images/user_enable.svg')} />}
                  { approvalStatus !== 1 &&
                    <img style={{ width: '25px', height: '25px' }} title="Approve" className="cursor"  onClick={(e) => this.openModal(userId, false, false, true, false)} src={require('../../images/checkMark-01.svg')} />
                  }
                  { approvalStatus === 0 &&
                    <img style={{ width: '25px', height: '25px' }} title="Reject" className="cursor"   onClick={(e) => this.openModal(userId, false, false, false, true)} src={require('../../images/disable-01.svg')} />
                  }
            </div>
        </div>
    
        <div className="row pad_15 edit_patient_view">
            <div className="form-group col-md-4 label-strong">
                <label htmlFor="fistname">First Name</label>
                <input type="text" className="form-control" id="fistname" aria-describedby="fistname" value={firstName} readOnly/>
            </div>
            <div className="form-group col-md-4 label-strong">
                <label htmlFor="lastname">Last Name</label>
                <input type="text" className="form-control" id="lastname" aria-describedby="lastname" value={lastName} readOnly/>
            </div>
            <div className="form-group col-md-4 label-strong">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control" id="email" aria-describedby="email" value={emailAddress} readOnly/>
            </div>
            <div className="form-group col-md-4 label-strong" >
                <label htmlFor="roleSelect">Role</label>
                <select className="form-control" id="role" value={roleId} disabled>
                    { this.rolesList.map((role)=> {
                        return <option value={role.value}>{role.label}</option>
                    })}
                </select>
            </div>
            <div className="form-group col-md-4 label-strong">
                <label htmlFor="phonenumer">Phone Number</label>
                <MaskedInput
                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    className="form-control"
                    guide={false}
                    id="phonenumber"
                    aria-describedby="phonenumber"
                    value={phoneNumber}
                    readOnly />
            </div>
            { this.state.showOccupationList &&
            <div class="form-group col-md-4 label-strong">
                <label htmlFor="occupation">Occupation</label>
                <select class="form-control" id="sel1" value={occupationId} disabled>
                { this.props.occupations.map((occupation)=> {
                    return <option value={occupation.OccupationId}>{occupation.OccupationName}</option>
                })}
                </select>
            </div>
            }
            { this.state.showOtherInput &&
            <div className="form-group col-md-4 label-strong">
                <label htmlFor="others">Occupation</label>
                <input type="text" className="form-control" id="others" aria-describedby="practice" value={otherOccupation} />
            </div>
            }
            <div class="form-group col-md-4 label-strong">
                <label htmlFor="practiceName">Name of the Practice</label>
                <Input type="text" className="form-control" id="practiceName" aria-describedby="practiceName" 
                       prefix={(!practiceName ||(this.state.practiceNameId && parseInt(this.state.practiceNameId) > 0)?"":<Icon type="exclamation" title="Unmapped Name of Practice"/>)} 
                       value={practiceName} readOnly/>
            </div>
        </div>
        {/*
         <div>
            <div className="panal_header flex_btwn">
                <div className="panal_header_nav">
                    <span className="title-txt">Profile Image</span>
                </div>
            </div>
            <div className="image_panel pad_15">
                <div className="item_image">
                    {this.props.userEntity.profilePicPath ?
                        <img src={`${UserAPI.DownloadImage}?userid=${this.props.userEntity.userId}&filename=${this.props.userEntity.profilePicPath}`} />
                        :
                        <img src={require('../../images/profilesample.jpg')} />
                    }
                </div>
            </div>
        </div>
        */}
       <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={modalcustomStyles}
            contentLabel="Delete User">
            <span className="modal-close" onClick={this.closeModal}><img src={require('../../images/close.svg')} /></span>
            <div className="flex_box deletealert flex_column">
              { this.state.isDelete === true &&
              <span>Are you sure you want to delete the user?</span>
              }
              { this.state.isEnable === true &&
                <span>Are you sure you want to enable the user?</span>
              }
              { this.state.isEnable === false && this.state.isDelete === false  && this.state.isApprove === false && this.state.isReject === false &&
                <span>Are you sure you want to disable the user?</span>
              }
              {this.state.isApprove === true &&
                <span>Are you sure you want to approve the user?</span>
              }
              {this.state.isReject === true &&
                <span>Are you sure you want to reject the user?</span>
              }
            </div>
            <div className="flex_box">
            <div className="pad_15 text-center">
                { this.state.isDelete === true &&
                   <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.deleteUser(e)}>Yes</button>
                }
                { this.state.isDelete === false && this.state.isEnable === false && this.state.isApprove === false && this.state.isReject === false &&
                  <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.disableUser(e)}>Yes</button>
                }  
                { this.state.isEnable === true &&
                  <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.enableUser(e)}>Yes</button>
                } 
                {this.state.isApprove === true &&
                  <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.approveUser(e)}>Yes</button>
                }
                {this.state.isReject === true &&
                  <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.rejectUser(e)}>Yes</button>
                }            
                <button type="button" className="btn btn-default cursor" onClick={this.closeModal}>No</button>
              </div>
            </div>

          </Modal>
        </div>
    </div>
    }    
    render() {        
        return this.props.userEntity ?
        this.getDetailComponent()
        :
        null;
    }
}
export default withRouter(UserDetailComponent);