import React from 'react'
import { connect } from 'react-redux'
import { addNewUser,actionEditGetUser,actionUpdateUser} from '../../actions/index';
import PropTypes from 'prop-types'
import AddUserPage from '../../components/User/Add';
import { constants } from "../../constants";
import { actionResetPassword, actionImageUpload } from '../../actions/userAction';
import { actionLoadOccupation, actionLoadPractice, actionNewPractice, actionDeletePractice, actionUpdatePractice } from '../../actions/lookupAction';

class AddUserContainer extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    errors: PropTypes.object,
    detail: PropTypes.object,
    occupations: PropTypes.array,
    practices: PropTypes.array,
    loading: PropTypes.bool
  }
  render() {
    return (
      <AddUserPage
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        addNewUser={this.props.addNewUser}
        editGetUser ={this.props.editGetUser}
        updateUser={this.props.updateUser}
        userEntity={this.props.userEntity}
        resetPassword = {this.props.resetPassword}
        uploadImage = {this.props.uploadImage}
        refresh= {this.props.refresh}
        loadOccupation = {this.props.loadOccupation}
        occupations = {this.props.occupations}
        loadPractice = {this.props.loadPractice}
        practices = {this.props.practices}
        addNewPractice= {this.props.addNewPractice}
        updatePractice = {this.props.updatePractice}
        deletePractice = {this.props.deletePractice}
        loading = {this.props.loading}
      />
    )
  }
}
const mapStateToProps = (state) => {
  console.log('State in AddUserContainer : ', JSON.stringify(state.user));
  const { errorMessage, successMessage,userEntity, loading } = state.user == null ? null : state.user;
  const {occupations} = state.lookup;
  const {practices} = state.lookup;
  return {
    errorMessage,
    successMessage,
    userEntity,
    occupations,
    practices,
    loading
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNewUser: (userObject, callback, rCallback) => dispatch(addNewUser(userObject, callback, rCallback)),
    editGetUser : (userId,callback, rCallback) => dispatch(actionEditGetUser(userId, callback, rCallback)),
    updateUser : (userId,userObject,callback, rCallback) => dispatch(actionUpdateUser(userId,userObject,constants.LIST_USER_PAGE, callback, rCallback)),
    resetPassword: (callback, rCallback) => dispatch(actionResetPassword(callback, rCallback)),
    uploadImage: (file, user, callback, rCallback) => dispatch(actionImageUpload(file, user, callback, rCallback)),
    refresh: (userId,callback, rCallback) => dispatch(actionEditGetUser(userId,callback, rCallback)),
    loadOccupation: (callback, rCallback) => dispatch(actionLoadOccupation(callback, rCallback)),
    loadPractice: (callback, rCallback) => dispatch(actionLoadPractice(callback, rCallback)),
    addNewPractice: (practiceName, callback, rCallback) => dispatch(actionNewPractice(practiceName,callback, rCallback)),
    updatePractice: (practiceName, practiceNameId, callback, rCallback) => dispatch(actionUpdatePractice(practiceName,practiceNameId, callback, rCallback)),
    deletePractice: (practiceNameId, callback, rCallback) => dispatch(actionDeletePractice(practiceNameId,callback, rCallback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserContainer)