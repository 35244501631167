import React from "react"
import { Route, Redirect } from 'react-router-dom'

import AuthorizedLayoutContainer from "../containers/AuthorizedLayoutContainer";
import { constants } from "../constants";

const PrivateRoute = props => {
  console.log('PrivateRoute---', props);
  console.log("Location",props.location)
  const allowedRoles = [constants.SUPER_ADMIN_ROLE, constants.USER_ADMIN_ROLE];
  const adminPages = [constants.DASHBOARD_PAGE, constants.LIST_USER_PAGE, constants.USER_EDIT_PAGE, constants.USER_ADD_PAGE, constants.EDIT_PATIENT_PAGE];
  try {
    if (props.store.getState().user.token != null && props.store.getState().user.token != undefined) {
      if(allowedRoles.includes(props.store.getState().user.detail.roleId) || !adminPages.includes(props.path)) {
        return <Route path={props.path} render={props.render} ><AuthorizedLayoutContainer children={props.children} pageTitle={props.pageTitle}/></Route>
      } else {
        return <Redirect to={constants.LIST_PATIENT_PAGE}/>
      }
    } else {
      return <Redirect to={constants.LOGIN_PAGE} />
    }
  } catch (error) {
      return null;
  }
}

export default PrivateRoute;