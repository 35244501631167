import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PatientDetailComponent from '../../components/Patient/Detail';
import { actionGetPatient, actionGetImage, actionDeletePatient, actionLoadPatientStatusHistory } from '../../actions/patientAction';

class PatientDetailContainer extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    patientEntity: PropTypes.object,
    referralReasons: PropTypes.array,
    patientStatuses: PropTypes.array,
    referredBy: PropTypes.object,
    patientStatusHistory: PropTypes.array
  }

  render() {
    return (
      <PatientDetailComponent 
      patientStatuses = {this.props.patientStatuses}
      referralReasons = {this.props.referralReasons}
      referredBy = {this.props.referredBy}
      patientEntity = {this.props.patientEntity}
      patientStatusHistory = {this.props.patientStatusHistory}
      loadPatientStatusHistory = {this.props.loadPatientStatusHistory}
      fetch = {this.props.fetch} 
      user = {this.props.user}
      getImage = {this.props.getImage}
      deletePatientById = {this.props.deletePatientById}/>
    )
  }
}
const mapStateToProps = (state) => {
  const {patientStatuses, referralReasons} = state.lookup
  const referredBy = state.user.detail
  const {patientEntity} = state.patient
  const patientStatusHistory = state.patient.patientStatusHistory
  const user = state.user.detail
  return {
    patientStatuses,
    referralReasons,
    referredBy,
    patientEntity,
    user,
    patientStatusHistory
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch : (patientId, callback, rCallback) => dispatch(actionGetPatient(patientId, callback, rCallback)),
    getImage: (url, callback, rCallback) => dispatch(actionGetImage(url, callback, rCallback)),
    deletePatientById: (patientId, callback, rCallback) => dispatch(actionDeletePatient(patientId, callback, rCallback)),
    loadPatientStatusHistory : (patientId, callback, rCallback) => dispatch(actionLoadPatientStatusHistory(patientId, callback, rCallback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetailContainer)