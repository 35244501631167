import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import randomColor from "randomcolor";
import SVGIcon from "../styles/SVGicon";
import { NavLink } from 'react-router-dom';
import { Menu, Icon } from 'antd';

const { SubMenu } = Menu;
const waitingSVG =()=>(
    <svg height="12pt" viewBox="-88 -16 533 533.33331" width="12pt" xmlns="http://www.w3.org/2000/svg">
    <path d="m313.042969 83.46875v-58.46875h27.621093c6.90625 0 12.5-5.59375 12.5-12.5s-5.59375-12.5-12.5-12.5h-332.746093c-6.90625 0-12.5 5.59375-12.5 12.5s5.59375 12.5 12.5 12.5h27.625v58.46875c.179687 35.859375 14.800781 70.132812 40.550781 95.085938l79.949219 77.296874-76.425781 71.921876c-26.558594 25.078124-41.601563 60-41.574219 96.53125v50.695312h-30.125c-6.90625 0-12.5 5.59375-12.5 12.5s5.59375 12.5 12.5 12.5h332.746093c6.90625 0 12.5-5.59375 12.5-12.5s-5.59375-12.5-12.5-12.5h-26.371093v-49.765625c-.066407-37.171875-15.71875-72.613281-43.140625-97.703125l-78.582032-71.796875 79.9375-77.179687c25.746094-24.957032 40.359376-59.226563 40.535157-95.085938zm-23.75 341.765625v49.765625h-226.25v-50.695312c-.015625-29.636719 12.199219-57.960938 33.75-78.304688l77.691406-72.972656 79.84375 72.949218c22.234375 20.359376 34.921875 49.109376 34.964844 79.257813zm-115-186.640625-80.804688-77.992188c-20.902343-20.242187-32.777343-48.042968-32.945312-77.132812v-58.46875h227.5v58.46875c-.171875 29.089844-12.046875 56.890625-32.949219 77.132812zm0 0"/>
  </svg>
);
const WaitingIcon = props => <Icon component={waitingSVG} {...props} />;
class LeftSideBar extends React.Component {
  rootSubmenuKeys = ['sub1'];
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props) {
    super(props);
    this.state={
      openKey:[]
    };
    this.handleTitleClick= this.handleTitleClick.bind(this);
    this.handleMenuItemClick= this.handleMenuItemClick.bind(this);
    this.redirectToTarget = this.redirectToTarget.bind(this);
  }

  redirectToTarget = (target) => {
    this.context.router.history.push(target)
  }
  handleTitleClick = (e) => {
    console.log("handleTitleClick-->",e)
    if( e.key == 'sub1'){
      this.props.userMenuAction(1, this.redirectToTarget);  
      this.setState({openKey:['3']});    
    }

  }
  handleMenuItemClick = (e) => {
    //item, key, keyPath, domEvent
    if( e.key == '1'){
      this.context.router.history.push("/dashboard")
      this.setState({openKey:['1']}); 
    } else if( e.key == '2'){
      this.context.router.history.push("/catalogue/patients?statusId=")
      this.setState({openKey:['2']}); 
    } else if( e.key == '3'){
      this.props.userMenuAction(1, this.redirectToTarget);
      this.setState({openKey:['3']}); 
    } else if( e.key == '4'){
      this.props.userMenuAction(0, this.redirectToTarget);
      this.setState({openKey:['4']}); 
    } else if( e.key == '5'){
      this.props.userMenuAction(2, this.redirectToTarget);
      this.setState({openKey:['5']}); 
    }
    

  }
  render() {
    return <div className="row ">{/*border_btm */}

      <div className="col-md-12 pad_0 ">
        <div className="menu_title"><Link to="/user/profile" className="menu_title">My Account</Link></div>        
      </div>
      <Menu
        style={{ width: 256 }}
        defaultSelectedKeys={[]}
        defaultOpenKeys={[]}
        selectedKeys={this.state.openKey}
        mode="inline"
        theme="dark"
        //onSelect={this.handleMenuItemClick}
        onClick={this.handleMenuItemClick}
      >
        {this.props.allowedRoles.includes(this.props.roleId) &&
        <Menu.Item key="1">
          <SVGIcon name="dashboard" width={25} fill={randomColor()} />
          <span>DASHBOARD</span>
        </Menu.Item>
        }
        
        <Menu.Item key="2">
          <SVGIcon name="referred" width={25} fill={randomColor()} />
          <span>PATIENTS</span>
        </Menu.Item>
        {this.props.allowedRoles.includes(this.props.roleId) &&
        <SubMenu
          key="sub1"
          title={
            <span>
              <SVGIcon name="doctor" width={25} fill={randomColor()} />
              <span>USERS</span>
            </span>
          }
          onTitleClick={this.handleTitleClick}
        >
          <Menu.Item key="3"><Icon type="check" /><span>Approved Users</span></Menu.Item>
          <Menu.Item key="4"><WaitingIcon width={25} /><span>Waiting for Approval</span></Menu.Item>
          <Menu.Item key="5"><Icon type="stop" /><span>Rejected</span></Menu.Item>
        </SubMenu>
        }

      </Menu>
    </div>
  }
}
export default LeftSideBar;