export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: '#d9f0f5'
    }
};

export const modalcustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        width: '40%'
    }
}
export const fullScreencustomStyles = {
	    content: {
	        top: '50%',
	        left: '50%',
	        right: 'auto',
	        bottom: 'auto',
	        marginRight: '-50%',
	        transform: 'translate(-50%, -50%)',
	        background: '#fff',
	        width: '100%',
	        height: '100%'
	    }
}
