export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state')
        if(serializedState != null){
            const stateObj = JSON.parse(serializedState);
            stateObj.user.errorMessage = '';
            stateObj.user.successMessage = '';
            return stateObj
        }
    } catch(err) {

    }
    return undefined;
}

export const saveState = (state) => {
    try{
        //state.user.errorMessage = '';
        if(state && state.user && state.user.password){
            state.user.password='';
        }
        localStorage.setItem('state', JSON.stringify(state));
    } catch(err) {

    }
}