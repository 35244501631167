import React from 'react'
import PropTypes from 'prop-types'
import logo from '../images/logo.svg';
import logo1 from '../images/logo2.png';
import { Link } from "react-router-dom";
import {Icon} from 'antd'
export class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        console.log('Props in ForgotPasswordComponent : ', props);
        console.log('State in ForgotPasswordComponent : ', this.state);
        this.state = {
            userName: '',
            errors: {
                userName: ''
            }
        }
    }

    //Used for page navigation
    static contextTypes = {
        router: PropTypes.object
    }

    redirectToTarget = (target) => {
        this.context.router.history.push(target)
    }

    handleChange = (event, key) => {
        this.state[key] = event.target.value
        this.setState(this.state)
        this.setErrors();
    }

    forgotPassword = () => {
        this.setErrors();
        if (this.state.errors['userName'] == '') {
            this.props.forgotPassword(this.state.userName, this.redirectToTarget)
        }
    }

    setErrors = () => {
        this.state.errors["userName"] = '';
        if (this.state.userName == '' || this.state.userName == undefined) {
            this.state.errors["userName"] = 'Enter User Name';
        }
        this.setState(this.state);
    }

    render() {
        return (
            <div className="login">
      <div className="bg_overlay"></div>
      <div className="container flex_box height_100">
        <div className="row login_container">
          <div className="col-md-12 login_section">
            <div className="row height_100">
              <div className="col-md-4 login_left">
              <div>
                <div className="logo-1">
                <Link to ="/login"><img src={logo} alt="Avant logo" /></Link>
                </div>
                <div className="logo-2">
                <Link to ="/login"><img src={logo1} alt="Avant logo" /></Link>
                </div>
              </div>
                
                <div className="login_footer"><span>contact@avant.com</span></div>
              </div>
              <div className="col-md-8 login_right ">
                 {/*  <span><Icon type="home"  className="mr-3" onClick={() => this.redirectToTarget('/login')}/> Home</span> */}
                  <div className="d-flex align-items-center justify-content-center flex-column h-100">
              {this.props.errorMessage != '' &&
                                <div className="error-msg">
                                    <span>{this.props.errorMessage}</span>
                                </div>
                            }
                            {this.props.successMessage != '' &&
                                <div className="success-msg">
                                    <span>{this.props.successMessage}</span>
                                </div>
                            }
                            <div className="text-center">                               
                            <h2>Forgot Password</h2>
                            </div>
                            <p>The new password will be send to the entered e-mail address.</p>
                            <form>
                                <div className="form-group forget-placeholder">
                                    <input type="text" className="form-control" placeholder="Your e-mail address" value={this.state.userName} onChange={(e) => this.handleChange(e, 'userName')} />
                                    {this.state.errors["userName"] != '' &&
                                        <span className="field-error">{this.state.errors["userName"]}</span>
                                    }
                                </div>
                                <div className="pad_15 flex_end">
                                    <button type="button" className="cancel-btn-simple m-w-15 mr-2 btn btn-default"onClick={() => this.redirectToTarget('/login')}>Cancel</button>                                                     
                                    <button type="button" className="reset-btn btn btn-default m-w-15" onClick={() => this.forgotPassword()}>Reset Password</button>
                                </div>
                            </form>
              </div>
                            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
            )
    }
}

export default ForgotPassword
