import { PatientAPI } from "../constants/apiConstants";
import { httpService } from './httpService'

const searchPatients = (searchObject) => {
    return httpService.httpPost(PatientAPI.Filter, searchObject);
}
const patientExist = (emailAddress) => {
    return httpService.httpGet(PatientAPI.Exist.replace('{emailAddress}', emailAddress));
}
const addPatient = (patientObject) => {
    return httpService.httpPost(PatientAPI.Add, patientObject);
}
const getPatient = (patientId) => {
    return httpService.httpGet(PatientAPI.Get.replace(':patientId', patientId));
}
const updatePatient = (patientObject) => {
    return httpService.httpPut(PatientAPI.Update.replace(':patientId', patientObject.patientId), patientObject);
}
const deletePatient = (patientId) => {
    console.log('DELETE  PATIENT  API : ')
    return httpService.httpDeletewithBody(PatientAPI.Delete.replace(':patientId', patientId), JSON.stringify({patientId: patientId}));
}
const uploadImage = (file, patient) => {
    console.log('UPLOAD PATIENT IMAGE API : ')
    const data = new FormData()
    data.append('images', file)
    return httpService.httpImagePost(PatientAPI.Upload.replace('{patientId}', patient.patientId), data, patient.patientId);
}
const deleteImage = (imgDetail) => {
    console.log('DELETE PATIENT IMAGE API : ')
    return httpService.httpDeletewithBody(PatientAPI.DeleteImage, imgDetail);
}
const getPatientImage = (url) => {
    console.log("GET Image API:",url)
    return httpService.httpImageGet(url);
}
const loadStatusHistory = (patientId) => {
    console.log("GET Patient Status History API:",patientId)
    return httpService.httpGet(PatientAPI.StatusHistory.replace(':patientId', patientId));
}
export const patientService = {
    searchPatients,
    addPatient,
    getPatient,
    updatePatient,
    deletePatient,
    patientExist,
    uploadImage,
    deleteImage,
    getPatientImage,
    loadStatusHistory
}