import React from 'react'
import { connect } from 'react-redux'
import { actionLogout, actionAuthenticate, actionResetPassword, actionUserMenu } from '../actions/userAction';
import AuthorizedLayout from '../layouts/AuthorizedLayout';


class AuthorizedLayoutContainer extends React.Component {

  render() {
    return (
      <AuthorizedLayout
         logout = {this.props.logout}
         children = {this.props.children}
         resetPassword = {this.props.resetPassword}
         userMenu = {this.props.userMenu}
         name = {this.props.name}
         roleId = {this.props.roleId}
         userId = {this.props.userId}
         userName = {this.props.userName}
         pageTitle = {this.props.pageTitle}
         />
    )
  }
}
const mapStateToProps = (state) => {
  const roleId = state.user.detail != null ? state.user.detail.roleId : 1;
  const {userId, firstName, lastName} = state.user.detail;
  const userName = state.user.detail.emailAddress;
  return {
    name: firstName + ' ' + lastName,
    roleId: roleId,
    userId,
    userName
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout : (callback) => dispatch(actionLogout(callback)),
    resetPassword : (callback) => dispatch(actionResetPassword(callback)),
    authenticate : () => dispatch(actionAuthenticate()),
    userMenu: (menuKey, callback) => dispatch(actionUserMenu(menuKey, callback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedLayoutContainer)