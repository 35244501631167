import React from 'react'
import PropTypes from 'prop-types'

import user from '../images/avatar.svg';
import logo from '../images/logo.svg';
import logo1 from '../images/logo2.png';
import bell from '../images/notification-bell.svg';
import IdleTimer from 'react-idle-timer'
import { ToastContainer } from 'react-toastify';
import { constants } from '../constants';
import Websocket from 'react-websocket';
import { apiConstants } from '../constants/apiConstants';
import { Alert, Statistic, Row, Col } from 'antd';

const { Countdown } = Statistic;

export class Header extends React.Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            notificationCount: 0,
            hasMessage: false,
            alertVisible: true,
            deadline: Date.now() + 1000 * 60 * apiConstants.UI_IDLE_TIMEOUT
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }
    refWebSocket;
    msgObj;
    static contextTypes = {
        router: PropTypes.object
    }

    static propTypes = {
        userId: PropTypes.number,
        userName: PropTypes.string,
        roleId: PropTypes.number,
        pageTitle: PropTypes.string,
        name: PropTypes.string
    }

    handleClose = () => {
        this.setState({ alertVisible: false });
    }

    redirectToTarget = (target) => {
        this.context.router.history.push(target)
    }
    logout = () => {        
        this.props.logout(()=>{            
            this.redirectToTarget(constants.LOGIN_PAGE);            
        });
        localStorage.clear();
    }
    viewProfile = () => {
        this.redirectToTarget(constants.PROFILE_PAGE)
    }
    resetPassword = () => {
        this.props.resetPassword(this.openModal);
    }
    handleNotification = (message) => {
        console.log('NOTIFICATION DATA:::::');
        try {
            console.log(message);
            this.msgObj = JSON.parse(message);
            this.setState({ notificationCount: this.msgObj.data.length });
            this.setState({ hasMessage: (this.msgObj.data.length > 0) ? true : false });
        } catch (error) {
            //message may not be json
        }
    }
    onSocketOpen = () => {
        console.log('OPENSOCKET')
        this.refWebSocket.sendMessage(JSON.stringify({ userId: this.props.userId, userName: this.props.userName, msgType: 'LOGIN' }))
    }
    getMessage = (e) => {
        console.log('GETMESSAGE')
        this.setState({ hasMessage: false })
        this.refWebSocket.sendMessage(JSON.stringify({ userId: this.props.userId, userName: this.props.userName, msgType: 'LOGIN' }))
    }
    readMessage = () => {
        console.log('READMESSAGE')
        this.setState({ notificationCount: 0 });
        this.msgObj = { data: [] }
        this.setState({ hasMessage: false });
        this.refWebSocket.sendMessage(JSON.stringify({ userId: this.props.userId, userName: this.props.userName, msgType: 'MSG_READ' }))
    }
    _onAction(e) {
        this.setState({deadline: Date.now() + 1000 * 60 * apiConstants.UI_IDLE_TIMEOUT});
    }

    _onActive(e) {
    }

    _onIdle(e) {
        console.log("Timeout triggered")
        this.logout();
    }
    render() {
        return (
            <div className="row dashboard_header">
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={0}
                    timeout={parseInt(apiConstants.UI_IDLE_TIMEOUT)*60*1000} />
                <ToastContainer autoClose={5000} />
                <div className="col-md-2 col-lg-2 left flex_box">
                    <div id="sidebar-logo">
                        <div className="sidelogo-1">
                            <img src={logo} alt="Avant logo" />
                        </div>
                        <div className="sidelogo-2">
                            <img src={logo1} alt="Avant logo" />
                        </div>
                    </div>
                </div>
                <div className="col-md-10 col-lg-10 right flex_btwn box-shadow">
                    <div className="page_heading">{this.props.pageTitle}</div>
                    { /* this.state.alertVisible ? (
                      <Countdown title=" " prefix="Session expires in" value={this.state.deadline} valueStyle={{fontSize: '12px'}}/>                     
                    ) : null */}
                    <div className="profile flex_box">
                        <div className="dropdown notify-sec">
                            <button type="button" className="btn drop_dn dropdown-toggle btn-width" data-toggle="dropdown" onClick={(e) => this.getMessage(e)}>
                                <img src={bell} alt="" />
                                <span className="labelcount">{this.state.notificationCount}</span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right p_5">
                                {this.state.hasMessage ?
                                    <div>
                                        <div className="sec_notify">{
                                            this.msgObj.data.map((message, i) => {
                                                return <a key={i} className="dropdown-item" href="#" title=""><h4>{message}</h4></a>
                                            })}
                                        </div>
                                        <a className="clear-btn" href="#" title="" onClick={() => this.readMessage()}>Clear All</a>
                                    </div>
                                    :
                                    'No Messages'
                                }
                            </div>
                        </div>
                        <span className="flex_box"><img className="img-avatar" src={user} />
                            <div className="dropdown">
                                <button type="button" className="btn drop_dn dropdown-toggle" data-toggle="dropdown">
                                    {this.props.name}
                                </button>
                                <div className="dropdown-menu dropdown-menu-right log-menu">
                                    {this.props.roleId != 1 &&
                                        <a className="dropdown-item" href="#" onClick={() => this.viewProfile()}><span>Profile</span><img src={require('../images/profile.svg')} /></a>
                                    }
                                    <a className="dropdown-item" href="#" onClick={() => this.logout()}><span>Logout</span><img src={require('../images/logout.svg')} /></a>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <Websocket url={apiConstants.WEB_SOCK_URL} onOpen={this.onSocketOpen.bind(this)}
                    onMessage={this.handleNotification.bind(this)}
                    ref={Websocket => {
                        this.refWebSocket = Websocket;
                    }} />
            </div>
        )
    }
}
export default Header;
