import React from 'react';
import PropTypes from 'prop-types'
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { modalcustomStyles } from '../../styles/customStyles';
import { toast } from 'react-toastify';
import * as EmailValidator from 'email-validator';
import MaskedInput from 'react-text-mask';
import { constants } from '../../constants/index';
import { PatientAPI } from '../../constants/apiConstants';
import { isValidAge } from '../../utils/common';
import Select, { components } from 'react-select';
import  MultiSelectReact  from 'multi-select-react';
import {Icon, Modal as AntModal, Button, Timeline,Col, Tooltip, Input, DatePicker } from 'antd';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
const { TextArea } = Input;

class PatientDetailComponent extends React.Component {

  constructor(props) {
    console.log("details------------",props)
    super(props)
    this.state = {
      patientEntity: {
        firstName:'',
        lastName:'',
        emailAddress:'',
        phoneNumber:'',
        age:'',
        gender:'Male',
        currentPatientStatusId:1,
        referralReasonId:[1],
        notes:'',
        createdBy: this.props.referredBy.userId,
        createdByName: this.props.referredBy.firstName+' '+this.props.referredBy.lastName,
        preferredContactMethod: 'Both',
        statusNotes: '',
        adminNotes : '',
        statusDate : '',
      },
      patientId:null,
      emailChecked:false,
      phoneChecked:false,
      hasError: false,
      showAddConfirmModal: false,
      dragModelOpen: false,
      showImgViewModel: false,
      showStatusNotes: false,
      showStatusDate: false,
      canUpload: false,
      isUploading: false,
      existingReferralReasonIds:[],
      imageData:null,
      modalIsOpen: false,
      modalVisible: false,
      prevPage: 0
    }
    this.handleCancel = this.handleCancel.bind(this);
    this.showStatusModal = this.showStatusModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  allowedRoles=[constants.SUPER_ADMIN_ROLE,constants.USER_ADMIN_ROLE];
  patientImages = [];
  isEdit = false;
  isNextClicked = false;
  genderList = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
  ];
  referralReasons = this.props.referralReasons.map((reason)=> {
    return { label: reason.ReferralReasonName, value:reason.ReferralReasonId}
  })
  //Used for page navigation
  static contextTypes = {
    router: PropTypes.object
  }
  redirectToTarget = (target) => {
      if (this.props.errorMessage) {
          toast.error(() => <div>{this.props.errorMessage}</div>)
          return
      }
      if (this.props.successMessage) {
          toast.success(() => <div>{this.props.successMessage}</div>)
      }
      this.context.router.history.push(target)
  }
  redirectToTargetWithoutToast = (target) => {
    this.context.router.history.push(target)
  }
  componentDidMount() {
    this.getPatient();   
  }
  handleCancel(){
    this.setState({
      modalVisible: false
    });
  }
  showStatusModal(){
    this.setState({
      modalVisible: true
    });
  }
  getPatient = (id) => {
    const { match: { params } } = this.props;
    let patientId = params.patientId? params.patientId: id;
    let prevPage = params.cPage;
    this.setState({patientId:patientId, prevPage:prevPage});
    if (params.patientId) {
      this.isEdit = true;
    }
    if(!patientId){
      return
    }
    let dateStatuses = [3,4,6,8];
    this.props.fetch(patientId, () => {
      this.state.patientEntity = this.props.patientEntity;
	    this.state.patientEntity.createdByName = this.props.patientEntity.referredByFirstName + ' ' + this.props.patientEntity.referredByLastName;
      if(this.referralReasons){
        this.referralReasons.forEach(rr=>{
          if(this.state.patientEntity.referralReasonId.includes(rr.value)){
            this.setState({existingReferralReasonIds:this.state.existingReferralReasonIds.concat(rr)})
          }
        })
      }
      if ( dateStatuses.includes(parseInt(this.state.patientEntity.currentPatientStatusId))) {
        this.setState({showStatusDate: true, showStatusNotes: false})
      } else if ( parseInt(this.state.patientEntity.currentPatientStatusId) === 1 ){
        this.setState({showStatusDate: false, showStatusNotes: false})
      } else{
        this.setState({showStatusDate: false, showStatusNotes: true})
      }
      this.state.canUpload = true;
      console.log(this.state.existingReferralReasonIds)
      this.setState(this.state);
      this.props.loadPatientStatusHistory(this.state.patientEntity.patientId, undefined, this.redirectToTargetWithoutToast);
    }, this.redirectToTargetWithoutToast)
  }
  deletePatient = (e) => {
    this.props.deletePatientById(this.state.patientId, this.deleteCallback, this.redirectToTargetWithoutToast);
    this.closeModal();
  }
  deleteCallback = () => {
    if(this.props.errorMessage) {
      toast.error(this.props.errorMessage);
      return
    }
    if(this.props.successMessage) {
      toast.success(this.props.successMessage);
    }
    this.props.history.push(constants.LIST_PATIENT_PAGE);
  }
  openModal(patientId) {
    this.setState({ modalIsOpen: true, patientId: patientId });
  }
  loadImage= () =>{    
    this.setState({imageData:null});
    this.props.getImage(this.state.selectedImg, (data) => {      
      this.setState({imageData:data});
    }, this.redirectToTargetWithoutToast)
  }
  getImage = (imgDetail) => {
    this.setState({selectedImg:PatientAPI.GetImage.replace('{patientId}',imgDetail.patientId).replace('{fileName}',imgDetail.relativePath)})
    this.setState({showImgViewModel: true})
  }
  closeModal = () => {
    this.setState({ showImgViewModel: false });
    this.setState({selectedImg:''});
    this.setState({ modalIsOpen: false });  
  }

  enableFullScreen = () => {
    var image = new Image();
    image.src = this.state.imageData;
    var w = window.open("");
    console.log(image.outerHTML);
    w.document.write(`
    		  <div align='center'>
    	          <img style='width:auto;height: calc(100vh - 16px)' alt={"Loading..."} src=${this.state.imageData} />
    	      </div>
    		  `);
  }

  downloadImage = () => {
    var a = document.createElement('a');
    a.href = this.state.imageData;
    a.target = '_blank';
    a.download = "image.png";
    document.body.appendChild(a);
    a.click();
  }

  printImage = () => {

    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    console.log(printContents);
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
	      <html>
	        <head>
	          <title>Avant</title>
	          <style>
	          </style>
	        </head>
	    <body onload="window.print();window.close()">
	    <div align='center'>
    	          <img style='width:auto;height: calc(100vh - 16px)' alt={"Loading..."} src=${this.state.imageData} />
    	      </div>
	    </body>
	      </html>`
    );
    popupWin.document.close();
  }

  getTimeLineItems(statusList){
    let timeLineItemList = [];

    statusList.forEach(status=>{
      let timeData = new Date(status.UpdatedOn);
      let description = "-"
      let dateStatuses = [3,4,6,8];
      let templateIfDataAvailable = ":status on :date"
      let templateIfNoDataAvailable = "No Date Given"
      if( status.StatusActionDate && status.StatusActionDate.trim().length > 0 && dateStatuses.includes(parseInt(status.CurrentPatientStatusId))){
        let dateData = moment(status.StatusActionDate).toDate().toLocaleDateString() + " at "+ moment(status.StatusActionDate).toDate().toLocaleTimeString();
        description = templateIfDataAvailable.replace(":status",status.CurrentPatientStatusName).replace(":date",dateData)
      } else if  ( status.StatusNotes && status.StatusNotes.trim().length > 0 ){
        description = status.StatusNotes
      }
     
      timeLineItemList.push(
        <Timeline.Item dot={"."}>
          <div>
            <span><b>{status.CurrentPatientStatusName}</b></span><span style={{float: 'right'}}>{timeData.toLocaleTimeString()} <br/>{status.UpdatedDate}</span>
          </div>
          <div>
            <span className='status-notes-description'> {description} </span>
          </div>
        </Timeline.Item>
      );
    })
    return timeLineItemList;
  }
  
  render() {
    const { firstName, lastName, emailAddress, phoneNumber, age, gender, currentPatientStatusId, referralReasonId, notes, createdByName, statusNotes, statusDate, adminNotes } = this.state.patientEntity;
    // const Option = props => { return ( <div> <components.Option {...props}> <input type="checkbox" checked={props.isSelected} onChange={(e)=> {return e;}}/> <label>{props.label}</label> </components.Option> </div> ); };
    return (<div className="edit_patient_view">
    <div className="search_panal">
      <div className="panal_header flex_btwn">
        <div className="panal_header_nav d-flex">
          <Link to={"/catalogue/patients/"+this.state.prevPage} className="breadcrumb-link"><img className="back-btn mr-3" src={require('../../images/back.svg')} />
          <div className="title-txt primary">Patient Details</div>
          </Link>
          
        </div>
        <div>
        <Link to={"/catalogue/add-patient/"+this.state.patientId+"/"+this.state.prevPage}><img className="cursor" title="Edit" src={require('../../images/edit.svg')} /></Link>&nbsp;&nbsp;
                  {this.allowedRoles.includes(this.props.user.roleId) && 
                    <img className="cursor" title="Delete" onClick={(e) => this.openModal(this.state.patientId)} src={require('../../images/delete.svg')} />
                  }
        </div>
      </div>

      <div className="row pad_15">
        <div className="form-group col-md-4 label-strong">
          <label htmlFor="firstname">First Name</label>
          <input type="text" className="form-control" id="firstname" aria-describedby="firstname" value={firstName} readOnly />
        </div>
        <div className="form-group col-md-4 label-strong">
          <label htmlFor="lastname">Last Name</label>
          <input type="text" className="form-control" id="lastname" aria-describedby="lastname" value={lastName} readOnly />
        </div>
        <div className="form-group col-md-4 label-strong">
          <label htmlFor="age">Age</label>
          <input type="number" className="form-control" id="age" aria-describedby="age" value={age} readOnly />
        </div>
        <div className="form-group col-md-4 label-strong">
          <label htmlFor="gender">Gender</label>
          <select className="form-control" id="patientGender" value={gender} disabled>
            { this.genderList.map((gender)=> {
                return <option value={gender.value}>{gender.label}</option>
            })}
          </select>
        </div>
        <div className="form-group col-md-4 not_allowded ">
          <label htmlFor="referred">Referred by</label>
          <input type="text" className="form-control cursor-disable" id="referred" aria-describedby="referred" value={createdByName} disabled={true} />
        </div>
        <div className="form-group col-md-4 disable-component label-strong">
          <label htmlFor="sel1">Reason for Referral</label>
          <Select className="form-control custom_multiselect" isMulti
            options={this.state.existingReferralReasonIds} value={this.state.existingReferralReasonIds}
          />
        </div>
        <div className="form-group col-md-4 label-strong">
          <label htmlFor="status">Status</label>
          <select className="form-control" id="patientStatus" value={currentPatientStatusId} disabled>
            { this.props.patientStatuses.map((status)=> {
                return <option value={status.PatientStatusId}>{status.PatientStatusName}</option>
            })}
          </select>
         
        </div>
        {this.state.showStatusNotes && (this.props.user.roleId == 1 || this.props.user.roleId == 2) &&
            <div className="form-group col-md-8">
              <label htmlFor="statusNotes" >Status Note</label>
              <Input.Group>
                <Col xs={23}>
                  <TextArea className="form-control field-strong col-md-6" id="statusNotes"
                            aria-describedby="statusNotes" placeholder="Enter Status Notes" value={statusNotes}
                            onChange={(e) => this.handleChange(e, 'statusNotes')} disabled
                  />
                </Col>
                <Col xs={1}>
                  <Tooltip title="Check Status History">
                    <Icon type="history" onClick={this.showStatusModal}/>
                  </Tooltip>
                </Col>
              </Input.Group>
              
            </div>
          }

          {this.state.showStatusDate && (this.props.user.roleId == 1 || this.props.user.roleId == 2) &&
            <div className="form-group col-md-4">
              <label htmlFor="statusDate" >Selected Date</label>
              <Input.Group>
                <Col xs={30}>
                  <div>
                    <DatePicker disabled showTime className="form-control field-strong m-h-40" onChange={(e) => this.handleDateChange(e, 'statusDate')} value={moment(statusDate)} allowClear={false}  placeholder="Enter / Pick a Date" />
                  </div>
                </Col>
                <Col xs={1}>
                  <Tooltip title="Check Status History">
                    <Icon type="history" onClick={this.showStatusModal}/>
                  </Tooltip>
                </Col>
              </Input.Group>
              
            </div>
          }
        {/*
        <div className="form-group col-md-4">
          <label htmlFor="sel1">Reason for Referral</label>
          <select className="form-control" id="referralReason" value={referralReasonId} disabled>
            { this.props.referralReasons.map((reason)=> {
                return <option value={reason.ReferralReasonId}>{reason.ReferralReasonName}</option>
            })}
          </select>
        </div>*/
        }

        
        <div className="form-group col-md-4 label-strong">
          <label htmlFor="emailAddress">Email Address</label>
          <input type="text" className="form-control" id="emailAddress" aria-describedby="emailAddress" value={emailAddress} disabled />
        </div>
        <div className="form-group col-md-4 label-strong">
          <label htmlFor="referred">Phone Number</label>
          <MaskedInput
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              className="form-control"
              guide={false}
              id="phonenumber"
              aria-describedby="phonenumber"
              value={phoneNumber}
              disabled
          />
        </div>        
        <div className="form-group col-md-8 label-strong">
          <label htmlFor="symptoms">Symptoms/Notes</label>
          <textarea type="text" className="form-control m-h-40" id="symptoms" aria-describedby="symptoms" value={notes} readOnly />
        </div>
        {(this.props.user.roleId == 1 || this.props.user.roleId == 2) &&
            <div className="form-group col-md-8 label-strong">
              <label htmlFor="adminNotes" >Admin Notes</label>
              <textarea type="text" className="form-control field-strong m-h-40" id="adminNotes" aria-describedby="adminNotes" placeholder="Notes" value={adminNotes} onChange={(e) => this.handleChange(e, 'adminNotes')} disabled/>
            </div>
          }
      </div>
      <div>
        <div className="panal_header flex_btwn">
          <div className="panal_header_nav">
            <span>Images</span>
          </div>
        </div>
        { this.state.patientEntity.imageDetails && this.state.patientEntity.imageDetails.length > 0 ?
          <div className="image_panel pad_15 overflow-box">
          {this.state.patientEntity.imageDetails.map((patientImg)=> {
            return <div className="item_image">
                      <img src={patientImg.imageThumbnail} onClick = {()=> this.getImage(patientImg)}/>
                    </div>
          })}
          </div>
          :
          <span className="margin-left-20">No Images</span>
        }
      </div>
    </div>
    <div>
      <Modal
        isOpen={this.state.showImgViewModel}
        onAfterOpen={this.loadImage}
        onRequestClose={this.closeModal}
        style={modalcustomStyles}
        contentLabel="View Image"
      >
        <div className="model-header">
          <h2>View Image<Icon type="download" title='Download' onClick={this.downloadImage}/>
              <Icon type="printer" title='Print' onClick={this.printImage}/>
              <Icon type="fullscreen" onClick={this.enableFullScreen}  title='FullScreen'/></h2>
          <span onClick={this.closeModal}><img src={require('../../images/close.svg')} /></span>
        </div>
        <div className="flex_box custom_footer_body flex_column" id="print-section">
          {this.state.selectedImg ?
            <img className="loading box-auto" alt={""} src={this.state.imageData} />
            :
            <img src={require('../../images/image-file.svg')} />
          }
        </div>
      </Modal>
    </div>
    <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={modalcustomStyles}
            contentLabel="Delete User">
            <span className="modal-close" onClick={this.closeModal}><img src={require('../../images/close.svg')} /></span>
            <div className="flex_box deletealert flex_column">             
              <span>Are you sure you want to delete the user?</span>              
            </div>
            <div className="flex_box">
            <div className="pad_15 text-center">               
                  <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.deletePatient(e)}>Yes</button>        
                  <button type="button" className="btn btn-default cursor" onClick={this.closeModal}>No</button>
              </div>
            </div>

          </Modal>
        <AntModal id="statusHistoryModal" visible={this.state.modalVisible}
          title= {  <span className="pad_15 text-center">Status History <ReactToPrint
          trigger={() => <Icon type="printer"/>}
          content={() => this.statuscomponentRef}
        /></span>}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel} className="btn btn-primary m-w-90 cursor mr-2">
              Close
            </Button>,
           
          ]}
          style={{ overflowY: 'auto', height: '80%'}}>
            { this.props.patientStatusHistory &&
            <Timeline ref={el => (this.statuscomponentRef = el)}>
              {this.getTimeLineItems(this.props.patientStatusHistory)}
            </Timeline>           
            }
        </AntModal>
        </div>
  </div>)
  }
} 
  
export default withRouter(PatientDetailComponent);