import React from "react";
import PropTypes from 'prop-types'
import { toast } from 'react-toastify';
import { UserAPI } from "../../constants/apiConstants";
import { constants } from "../../constants";
import { withRouter } from "react-router-dom";

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword:'',
            newPassword:'',
            confirmPassword:'',
            errors:{
                oldPassword:'',
                newPassword:'',
                confirmPassword:''
            },
            isUploading: false,
            showOtherInput: false,
            showOccupationList:true
        }
        this.fetchFile = this.fetchFile.bind(this);
    }
    showChangePassword = false;
    //Used for page navigation
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidMount() {
        this.props.fetch(this.props.detail.userId, this.getUserCallBack, this.redirectToTargetWithoutToast);
    }
    getUserCallBack = () => {
        if(this.props.detail.occupationId == constants.OTHER_OCCUPATION_ID) {
            this.setState({showOtherInput: true,showOccupationList:false})
        }
    }
    redirectToTargetAfterUpload = (target) => {
        this.context.router.history.push(target)
        this.setState({isUploading: false});
    }
    redirectToTargetWithoutToast = (target) => {
        this.context.router.history.push(target)
    }
    showChangePasswordView = () => {
        this.state.errors = {
            oldPassword:'',
            newPassword:'',
            confirmPassword:''
        }
        this.showChangePassword = !this.showChangePassword;
        this.setState(this.state);
    }

    handleChange = (event, key) => {
        this.state[key] = event.target.value
        this.setState(this.state)
        this.setErrors();
    }

    changePassword = () => {
        this.setErrors();
        if (!this.state.errors['newPassword'] && !this.state.errors['confirmPassword'] && !this.state.errors['oldPassword']) {
            this.props.changePassword(this.props.detail.emailAddress,this.state.oldPassword, this.state.newPassword,0, this.clearState, true, this.redirectToTargetWithoutToast)
        }
    }

    clearState = () => {
        if(this.props.errorMessage) {
            toast.error(() => <div>{this.props.errorMessage}</div>)
            return
        }
        if(this.props.successMessage) {
            toast.success(() => <div>{this.props.successMessage}</div>)
        }
        this.state = {
            oldPassword:'',
            newPassword:'',
            confirmPassword:'',
            errors:{
                oldPassword:'',
                newPassword:'',
                confirmPassword:''
            }
        }
        this.showChangePassword = false;
        this.setState(this.state);
        
    }
    fetchFile(e) {
        this.refs.fileUploader.click();
    }
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        this.setState({isUploading: true});
        this.props.uploadImage(file, {detail:this.props.detail}, this.uploadCallBack, this.redirectToTargetWithoutToast);
    }

    uploadCallBack = () => {
        this.props.fetch(this.props.detail.userId, ()=> {
            this.redirectToTargetAfterUpload(constants.PROFILE_PAGE);
        }, this.redirectToTargetWithoutToast)
    }

    setErrors = () => {
        this.state.errors = {
            newPassword: '',
            confirmPassword: '',
            oldPassword:''
        }
        if (!this.state.oldPassword) {
            this.state.errors["oldPassword"] = 'Enter old password';
        }
        if (!this.state.newPassword) {
            this.state.errors["newPassword"] = 'Enter new password';
        }
        if(this.state.oldPassword && this.state.newPassword && this.state.oldPassword == this.state.newPassword) {
            this.state.errors["newPassword"] = 'New password must be different';
        }
        if (!this.state.confirmPassword) {
            this.state.errors["confirmPassword"] = 'Enter confirm password';
        }
        if (this.state.confirmPassword && this.state.newPassword != this.state.confirmPassword) {
            this.state.errors["confirmPassword"] = 'Password doesn\'t match';
        }
        this.setState(this.state);
    }

    render() {
        const { firstName, lastName, emailAddress, roleId, occupationName, phoneNumber, otherOccupation, practiceName } = this.props.detail;
        return (
            <div className="search_panal">
                <div className="panal_header flex_btwn">
                    <div className="panal_header_nav d-flex">
                        <span className="title-txt">User Details</span>
                    </div>
                </div>
                <div className="row pad_15 edit_patient_view">
                    <div className="form-group col-md-4">
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" className="form-control" id="firstName" aria-describedby="fistname" value={firstName} readOnly />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" className="form-control" id="lastName" aria-describedby="lastname" value={lastName} readOnly />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="emailAddress">Email</label>
                        <input type="text" className="form-control" id="emailAddress" aria-describedby="emailaddress" value={emailAddress} readOnly />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="exampleFormControlSelect1">Role</label>
                        <select className="form-control role-arrow" id="exampleFormControl" value={roleId} disabled={true}>
                            <option value="1">Super Admin</option>
                            <option value="2">Admin</option>
                            <option value="3">Doctor</option>
                        </select>
                    </div>                    
                    <div className="form-group col-md-4">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input type="text" className="form-control" id="phoneNumber" aria-describedby="phoneNumber" value={phoneNumber} readOnly />
                    </div>    
                    { this.state.showOccupationList &&                
                    <div className="form-group col-md-4">
                        <label htmlFor="occupationName">Occupation</label>
                        <input type="text" className="form-control" id="occupationName" aria-describedby="occupationName" value={occupationName} readOnly />
                    </div>
                    }
                    <div className="form-group col-md-4">
                        <label htmlFor="phoneNumber"> Name of Practice</label>
                        <input type="text" className="form-control" id="practicename" value={practiceName} readOnly />
                    </div>
                    { this.state.showOtherInput &&
                    <div className="form-group col-md-4">
                        <label htmlFor="others">Occupation</label>
                        <input type="text" className="form-control" id="others" aria-describedby="otherOccupation" placeholder="Enter Other Occupation" value={otherOccupation} readOnly />
                    </div>
                    }
                </div>
                <div className="row pad_15 edit_patient_view">
                    <div className="form-group col-md-4">
                        <span id="changePasswordBtn" className="cursor changetext" onClick={() => this.showChangePasswordView()}>Change Password</span>
                    </div>
                </div>
                <div className="row pad_15 edit_patient_view">
                { this.showChangePassword &&
                <div className="w-100">
                    <form>
                    <div className="d-flex align-content-center">
                    <div className="form-group col-md-4">
                        <label htmlFor="oldpassword">Old Password</label>
                        <input type="Password" className="form-control" id="oldpassword" value={this.state.oldPassword} onChange={(e) => this.handleChange(e, 'oldPassword')} />
                        {this.state.errors["oldPassword"] &&
                            <span className="field-error">{this.state.errors["oldPassword"]}</span>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="newpassword">New Password</label>
                        <input type="Password" id="newpassword" aria-describedby="password" className="form-control" value={this.state.newPassword} onChange={(e) => this.handleChange(e, 'newPassword')} />
                        {this.state.errors["newPassword"] != '' && 
                            <span className="login-error field-error">{this.state.errors["newPassword"]}</span>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="practiceName">Confirm New Password</label>
                        <input type="Password" id="confirm-password" aria-describedby="confirm-password" className="form-control" placeholder="" value={this.state.confirmPassword} onChange={(e) => this.handleChange(e, 'confirmPassword')} />
                        {this.state.errors["confirmPassword"] != '' &&
                            <span className="login-error field-error">{this.state.errors["confirmPassword"]}</span>
                        }
                    </div>

                    </div>
                    
                    <div className="text-center">
                      <button type="button" className="reset-btn btn btn-primary m-w-140" onClick={() => this.changePassword()} >Change</button>
                    </div>
                  </form>
                  </div>
                }
                {
                    /*
                    <div className="w-100">
                    <div className="panal_header flex_btwn">
                        <div className="panal_header_nav">
                            <span className="title-txt">Profile Image</span>
                        </div>
                    </div>
                    <div className="image_panel pad_15">
                        <div className="item_image">
                            {this.props.detail.profilePicPath ?
                                <img src={`${UserAPI.DownloadImage}?userid=${this.props.detail.userId}&filename=${this.props.detail.profilePicPath}`} />
                                :
                                <img src={require('../../images/profilesample.jpg')} />
                            }
                            {this.state.isUploading ?
                                <div className="img-loading">
                                    <span className="spinner-border text-light"></span>
                                </div>
                                :
                                <span className="img-subupload">
                                    <img src={require('../../images/img-upload.svg')} onClick={() => this.fetchFile()}/>
                                    <input type="file" id="file" ref="fileUploader" className="hidden" onChange={this.onChangeFile.bind(this)}/>
                                </span>
                            }
                        </div>
                    </div>
                </div>
                    */
                }
                </div>
            </div>
        )
    }
}

export default withRouter(ProfileComponent);