import React from 'react';
import PropTypes from 'prop-types'
import { Link, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as EmailValidator from 'email-validator';
import MaskedInput from 'react-text-mask';
import { constants } from '../../constants';
import { UserAPI } from '../../constants/apiConstants';
import { Input, Tooltip, Icon, Modal, Button, Steps, Table, Form, Popconfirm } from 'antd';
import Highlighter from 'react-highlight-words';
const { Step } = Steps;



class AddUserPage extends React.Component {
    constructor(props) {
        super(props);
        this.userId = this.props.match.params.userId;
        this.state = {
            selectedRole: null,
            selectedRowKeys: [],
            showEditPractice: false,
            newPracticeName:'',
            newPracticeNameId:0,
            callPracticeUpdate: false,
            userEntity: {
                firstName: '',
                lastName: '',
                emailAddress: '',
                practiceName: '',
                practiceNameId: 0,
                roleId: 3,
                createdBy: 1,
                updatedBy: null,
                phoneNumber: '',
                occupationId: 2,
                otherOccupation: '',
                profilePicPath: '',
                isDisabled: 0
            },
            hasError: false,
            showOtherInput: false,
            showOccupationList: true,
            isUploading: false,
            visible: false,
            nameExists: false,
            current: 0
        }
        this.callbackEditGetUser = this.callbackEditGetUser.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.practiceNameTable = this.practiceNameTable.bind(this);
        this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.selectRow = this.selectRow.bind(this);
        this.onSelectedRowKeysChange = this.onSelectedRowKeysChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handlePracticeEdit = this.handlePracticeEdit.bind(this);
        this.addNewPractice = this.addNewPractice.bind(this);
    }
    isEdit = false;
    rolesList = [
        { value: 2, label: 'Admin' },
        { value: 3, label: 'Doctor' }
    ];
    //Used for page navigation
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.loadOccupation(() => {
            console.log('OCCUPATION LOADED ::::', this.props.occupations);
        }, this.redirectToTargetWithoutToast)
        this.props.loadPractice(() => {
            console.log('PRACTICE LOADED ::::', this.props.practices);
        }, this.redirectToTargetWithoutToast)
        if (params.userId) {
            this.isEdit = true;
            this.props.editGetUser(params.userId, this.callbackEditGetUser, this.redirectToTargetWithoutToast);
        }

    }
    redirectToTarget = (target) => {
        if (this.props.errorMessage) {
            toast.error(() => <div>{this.props.errorMessage}</div>)
            return
        }
        if (this.props.successMessage) {
            toast.success(() => <div>{this.props.successMessage}</div>)
        }
        this.context.router.history.push(target)
    }
    redirectToTargetWithoutToast = (target) => {       
        this.props.history.push(target)
    }
    handleChange = (event, key) => {
        this.state.userEntity[key] = (key.toLowerCase() === constants.PRACTICE_NAME) ? event.target.value.trim() : event.target.value;
        if (key.toLowerCase() == constants.OCCUPATION_ID && parseInt(event.target.value) === constants.OTHER_OCCUPATION_ID) {
            this.state.showOtherInput = true
            this.state.showOccupationList = false
        } else if (key === constants.IS_DISABLED) {
            this.state.userEntity.isDisabled = event.target.checked ? 1 : 0;
        } else if (key.toLowerCase() == constants.OCCUPATION_ID) {
            this.state.showOtherInput = false;
            this.state.userEntity.otherOccupation = '';
        }
        if (key.toLowerCase() === constants.PRACTICE_NAME_ID) {
            let list = this.props.practices.filter(p => p.PracticeNameId === parseInt(event.target.value));
            let practiceName = list && list.length > 0 ? list[0].PracticeName : this.state.userEntity.practiceName;
            this.state.userEntity.practiceName = practiceName;
        }
        if (key.toLowerCase() === constants.PRACTICE_NAME) {
            let list = this.props.practices.filter(p => p.PracticeName === event.target.value);
            let practiceNameId = list && list.length > 0 ? list[0].PracticeNameId : undefined;
            if (practiceNameId) {
                this.setState({ nameExists: true });
                this.state.userEntity.practiceNameId = practiceNameId;
            } else {
                this.setState({ nameExists: false });
            }

        }

        if (key.toLowerCase() === constants.NEW_PRACTICE_NAME) {
            if( this.state.newPracticeName !== event.target.value ){
                this.state.newPracticeName = event.target.value;
                this.state.callPracticeUpdate = true;
            }
        }
        this.setState(this.state);
    }
    handleChangeSelect = (selectedOption, key) => {
        this.state.selectedRole = selectedOption;
        this.state.userEntity[key] = selectedOption.value;
        this.setState(this.state);
        console.log(`Option selected:`, selectedOption);
    }
    addUserSubmit = () => {
        this.setErrors();
        console.log(this.state.hasError);
        if (!this.state.hasError) {
            const { match: { params } } = this.props;
            if (params.userId) {
                this.state.userEntity.userId = params.userId;
                this.setState(this.state);
                this.props.updateUser(params.userId, this.state.userEntity, this.redirectToTarget, this.redirectToTargetWithoutToast);
            }
            else {
                this.props.addNewUser(this.state.userEntity, this.redirectToTarget, this.redirectToTargetWithoutToast);
            }
        }
    }

    setErrors = () => {
        var error = " Required fields:  ";
        var errorContent = "";
        if (!this.state.userEntity.firstName || this.state.userEntity.firstName.trim().length <= 0) {
            errorContent += ',First Name';
            this.state.hasError = true;
        }
        if (!this.state.userEntity.lastName || this.state.userEntity.lastName.trim().length <= 0) {
            errorContent += ',Last Name';
            this.state.hasError = true;
        }
        if (!this.state.userEntity.emailAddress) {
            errorContent += ',Email Address';
            this.state.hasError = true;
        }
        if (this.state.showOtherInput && !this.state.userEntity.otherOccupation) {
            errorContent += ',Other Occupation';
            this.state.hasError = true;
        }
        if (errorContent) {
            toast.error(() => <div>{error + errorContent.substring(1)}</div>);
            return
        }
        if (this.state.userEntity.emailAddress && !EmailValidator.validate(this.state.userEntity.emailAddress)) {
            errorContent = ' Enter valid email address';
            this.state.hasError = true;
            toast.error(() => <div>{errorContent.substring(1)}</div>);
            return
        }
        this.state.hasError = false;
    }

    callbackEditGetUser(responseCallback) {
        console.log(this.props.userEntity);
        console.log('callback for edit user' + responseCallback);
        this.state.userEntity = this.props.userEntity;
        this.state.selectedRole = this.state.userEntity.roleId.toString();
        if (this.state.userEntity.occupationId === constants.OTHER_OCCUPATION_ID) {
            this.state.showOtherInput = true;
            this.state.showOccupationList = false;
            console.log(this.state.userEntity)
        }
        if ( this.props.userEntity && this.props.userEntity.practiceNameId ){
            let list = this.props.practices.filter(p => p.PracticeNameId === this.props.userEntity.practiceNameId && p.PracticeName === this.props.userEntity.practiceName);
            if ( list.length <= 0 ){
                this.state.userEntity.practiceNameId = 0;
            }
        }
        this.setState(this.state);
    }
    resetPassword = () => {
        this.props.resetPassword(() => {
            console.log(this.props.userEntity.emailAddress);
            toast.success(() => <div>New password has been sent to: {this.props.userEntity.emailAddress}</div>)
        }, this.redirectToTargetWithoutToast)
    }
    fetchFile() {
        this.refs.fileUploader.click();
    }
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        this.setState({ isUploading: true });
        this.props.uploadImage(file, { detail: this.props.userEntity }, this.refreshPage, this.redirectToTargetWithoutToast);
    }
    resetOccupation = () => {
        this.setState({
            showOtherInput: false,
            showOccupationList: true
        });
    }
    refreshPage = () => {
        this.props.refresh(this.userId, this.callbackEditGetUser, this.redirectToTargetWithoutToast)
        this.setState({ isUploading: false });
    }
    showModal = () => {
        this.setState({
            visible: true
        });
    };
    addNewPractice = () => {
        let list = this.props.practices.filter(p => p.PracticeName === this.state.userEntity.practiceName);
        let practiceNameId = list && list.length > 0 ? list[0].PracticeNameId : undefined;

        if (!practiceNameId && this.state.userEntity.practiceName && this.state.userEntity.practiceName.trim().length > 0) {
          this.props.addNewPractice(this.state.userEntity.practiceName, () => {
            if (this.props.errorMessage) {
              toast.error(() => <div>{this.props.errorMessage}</div>)
              return
            }
            if (this.props.successMessage) {
              toast.success(() => <div>{this.props.successMessage}</div>)
            }
          }, this.redirectToTargetWithoutToast);
        } else if ( !this.state.userEntity.practiceName || this.state.userEntity.practiceName.trim().length <= 0) {
          toast.info(() => <div>{constants.PRACTICE_NAME_CANT_EMPTY}</div>)
          return
        } else {
          toast.error(() => <div>{constants.PRACTICE_NAME_ALREADY_EXISTS}</div>)
          return
        }
    
      };
    handleOk = e => {
        if (this.state.current === 0) {
            this.setState({ current: 1 });
        } else {
            this.setState({
                visible: false,
            });
        }

    };

    handleCancel = e => {
        this.setState({
            visible: false,
            showEditPractice: false
        });
        //this.props.editGetUser(this.state.userEntity.userId, this.callbackEditGetUser, this.redirectToTargetWithoutToast);
    };

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    selectRow = (record) => {
        this.setState({ selectedRowKeys: record.key });
    }
    onSelectedRowKeysChange = (sRowKeys, sRows) => {
        let practice = this.props.practices[sRowKeys]
        let userEntity = this.state.userEntity;
        userEntity.practiceName = practice.PracticeName;
        userEntity.practiceNameId = practice.PracticeNameId;
        this.setState({ selectedRowKeys: sRowKeys, userEntity: userEntity });
    }

    handlePracticeEdit = (enable, callUpdate, cellData) => {

        if (cellData) {
            this.setState({ newPracticeName: cellData.practicename, newPracticeNameId: cellData.practiceid });
        }
        if (callUpdate && this.state.callPracticeUpdate && this.state.newPracticeName && this.state.newPracticeName.trim().length > 0) {
            const { newPracticeName, newPracticeNameId } = this.state;
            let list = this.props.practices.filter(p => p.PracticeName === newPracticeName && p.PracticeNameId !== newPracticeNameId);
            let practiceNameId = list && list.length > 0 ? list[0].PracticeNameId : undefined;
            if (!practiceNameId) {
                this.props.updatePractice(newPracticeName, newPracticeNameId, () => {
                    if (this.props.errorMessage) {
                        toast.error(() => <div>{this.props.errorMessage}</div>)
                        return
                    }
                    if (this.props.successMessage) {
                        toast.success(() => <div>{this.props.successMessage}</div>)
                    }
                }, this.redirectToTargetWithoutToast);
            } else if (newPracticeName && newPracticeName.trim().length > 1) {
                toast.error(() => <div>{constants.PRACTICE_NAME_ALREADY_EXISTS}</div>)
                return
            }
        }
        this.setState({ showEditPractice: enable });
    };

    handleDelete = (practiceRow) => {
        console.log(practiceRow.key)
        this.props.deletePractice(practiceRow.practiceid, () => {
            if (this.props.errorMessage) {
                toast.error(() => <div>{this.props.errorMessage}</div>)
                return
            }
            if (this.props.successMessage) {
                toast.success(() => <div>{this.props.successMessage}</div>)
            }
        }, this.redirectToTargetWithoutToast);
    };
    practiceNameTable = (practiceData) => {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectedRowKeysChange,
            type: 'radio'
        };
        const columns = [
            
            {
                title: 'Name of Practice',
                dataIndex: 'practicename',
                key: 'practicename',
                width: '60%',
                ...this.getColumnSearchProps('practicename'),
            },
            {
                title: 'Options',
                dataIndex: 'options',
                render: (text, record) =>
                    practiceData.length >= 1 ? (
                        <div>
                            <Popconfirm title="Sure to delete?" onConfirm={() =>this.handleDelete(record)}>
                                <Icon type="delete" title="Delete"/>
                            </Popconfirm>
                            <Icon type="edit" title="Edit" onClick={()=>this.handlePracticeEdit(true, false, record)}/>                        
                        </div>
                    ) : null,
            }];
        let data = [];
        practiceData.map((practice, i) => {
            data.push({
                key: i,
                practicename: practice.PracticeName,
                practiceid: practice.PracticeNameId
            });

        })
        return <Table columns={columns} dataSource={data} pagination={{ pageSize: 5 }} size="small" rowSelection={rowSelection} />;
    }

    render() {
        const { firstName, lastName, emailAddress, practiceName, practiceNameId, roleId, phoneNumber, occupationId, otherOccupation, isDisabled } = this.state.userEntity;
        const openSelectIcon = <Icon type="select" onClick={this.showModal} title="Open the Practice Name Dialog"/>
        const addIcon = <Icon type="plus-circle" onClick={this.addNewPractice} title="Add New Practice"/>
        const {newPracticeName} = this.state;

        return (
            <div className="search_panal">
                <div className="panal_header flex_btwn">
                    <div className="panal_header_nav d-flex">
                        <Link to={"/catalogue/users"} className="breadcrumb-link"><img className="back-btn mr-3" src={require('../../images/back.svg')} />
                            <div className="title-txt primary">{this.isEdit ? 'Edit ' : 'Add New '}User</div>
                        </Link>
                    </div>
                </div>

                <div className="row pad_15 edit_patient_view">
                    <div className="form-group col-md-4">
                        <label htmlFor="fistname">First Name</label>
                        <input type="text" className="form-control field-strong" id="fistname" aria-describedby="fistname" placeholder="Enter First Name" value={firstName} onChange={(e) => this.handleChange(e, 'firstName')} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="lastname">Last Name</label>
                        <input type="text" className="form-control field-strong" id="lastname" aria-describedby="lastname" placeholder="Enter Last Name" value={lastName} onChange={(e) => this.handleChange(e, 'lastName')} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="email">Email</label>
                        <input type="email" className="form-control field-strong" id="email" aria-describedby="age" placeholder="Enter Email ID" value={emailAddress} onChange={(e) => this.handleChange(e, 'emailAddress')} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="roleSelect">Role</label>
                        <select className="form-control field-strong" id="role" placeholder="Select Role" value={roleId} onChange={(e) => this.handleChange(e, 'roleId')}>
                            {this.rolesList.map((role, i) => {
                                return <option key={i} value={role.value}>{role.label}</option>
                            })}
                        </select>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="phonenumer">Phone Number</label>
                        <MaskedInput
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            className="form-control field-strong"
                            placeholder="Enter a phone number"
                            guide={false}
                            id="phonenumber"
                            aria-describedby="phonenumber"
                            value={phoneNumber}
                            onChange={(e) => this.handleChange(e, 'phoneNumber')}
                        />
                    </div>
                    {this.state.showOccupationList &&
                        <div className="form-group col-md-4">
                            <label htmlFor="occupation">Occupation</label>
                            <select className="form-control field-strong" id="sel1" defaultValue={'2'} value={occupationId} onChange={(e) => this.handleChange(e, 'occupationId')}>
                                {this.props.occupations.map((occupation, i) => {
                                    return <option key={i} value={occupation.OccupationId}>{occupation.OccupationName}</option>
                                })}
                            </select>
                        </div>
                    }
                    {this.state.showOtherInput &&
                        <div className="form-group col-md-4">
                            <label htmlFor="others">Occupation</label>
                            <div className="flex_center">
                                <input type="text" className="form-control field-strong" id="others" aria-describedby="practice" placeholder="Enter Other Occupation" value={otherOccupation} onChange={(e) => this.handleChange(e, 'otherOccupation')} />
                                <a href="#" className="flex_box close-others" onClick={(e) => this.resetOccupation()}><img src={require('../../images/close.svg')} /></a>
                            </div>
                        </div>
                    }
                    <div className="form-group col-md-4">
                        <label htmlFor="practice">Name of the Practice</label>
                        {/* <input type="text"   onChange = {(e) => this.handleChange(e, 'practiceName')}/> */}
                        <Input className="form-control field-strong" id="practice" aria-describedby="practice"
                            placeholder="Enter the name of practice" value={practiceName}
                            title={(!practiceName ||(practiceNameId && parseInt(practiceNameId) > 0)? practiceName:"Unmapped Name of Practice")}
                            suffix={openSelectIcon} 
                            prefix={(!practiceName ||(practiceNameId && parseInt(practiceNameId) > 0)?"":<Icon type="exclamation" title="Unmapped Name of Practice"/>)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="disable">Disable</label>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input field-strong" id="disable" aria-describedby="disable" placeholder="Disable User" checked={isDisabled} onChange={(e) => this.handleChange(e, 'isDisabled')} />
                            <label class="custom-control-label" for="disable"></label>
                        </div>

                        {/* <input type="checkbox" className="form-control" id="disable" aria-describedby="disable" placeholder="Disable User" checked={isDisabled} onChange = {(e) => this.handleChange(e, 'isDisabled')}/> */}
                    </div>
                </div>
                {this.isEdit &&
                    <div className="row pad_15 edit_patient_view">
                        <div className="form-group col-md-4">
                            <span htmlFor="restpassword" className="cursor changetext" onClick={() => this.resetPassword()}>Reset Password</span>
                        </div>
                    </div>
                }
                { /*this.isEdit &&
                <div>
                    <div className="panal_header flex_btwn">
                        <div className="panal_header_nav">
                            <span className="title-txt">Profile Image</span>
                        </div>
                    </div>
                    <div className="image_panel pad_15">
                        <div className="item_image">
                            {this.state.userEntity.profilePicPath ?
                                <img src={`${UserAPI.DownloadImage}?userid=${this.state.userEntity.userId}&filename=${this.state.userEntity.profilePicPath}`} />
                                :
                                <img src={require('../../images/profilesample.jpg')} />
                            }
                            {this.state.isUploading ?
                                <div className="img-loading">
                                    <span className="spinner-border text-light"></span>
                                </div>
                                :
                                <span className="img-subupload">
                                    <img src={require('../../images/img-upload.svg')} onClick={() => this.fetchFile()}/>
                                    <input type="file" id="file" ref="fileUploader" className="hidden" onChange={this.onChangeFile.bind(this)}/>
                                </span>
                            }
                        </div>
                    </div>
                </div>
                        */}
                <div className="pad_15 flex_end">
                    <Link className="btn btn-default cancel-btn m-w-15 mr-2" to={{ pathname: `/catalogue/users` }}>Cancel</Link>
                    <button type="button" className="btn btn-primary m-w-15" onClick={() => this.addUserSubmit()}>Save</button>
                </div>
                <Modal
                    title="Name of Practice"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="Ok" type="primary" className="btn btn-primary m-w-15" shape='round' onClick={this.handleOk}>
                            {this.state.current === 0 ? "Next" : "Done"}
                        </Button>
                    ]}
                >
                    <Steps
                        type="navigation"
                        size="small"
                        current={this.state.current}
                        onChange={this.onChange}
                    >
                        <Step key={0} title="Add New" onClick={(e) => this.setState({ current: 0 , showEditPractice: false})} />
                        <Step key={1} title="Select from the List" onClick={(e) => this.setState({ current: 1 })} description="" />
                        {/* <Step key={2} title="Edit/Delete" onClick={(e) => this.setState({ current: 2 })} description="" /> */}
                    </Steps>

                    {this.state.current === 0 &&
                        <div>
                            <label htmlFor="practice">Add a new Name of the Practice to the list</label>
                            <Input className="form-control field-strong" id="practice" aria-describedby="practice"
                                value={practiceName}
                                suffix={this.state.nameExists === false ? addIcon : ''} onChange={(e) => this.handleChange(e, 'practiceName')} />
                        </div>
                    }
                    {/* this.state.current === 1 &&
                        <div>
                            <label htmlFor="choosePractice">Choose from the List of Practice Names</label>
                            <select id="choosePractice" className="form-control field-strong" id="sel1" value={practiceNameId} onChange={(e) => this.handleChange(e, 'practiceNameId')}>
                                {this.props.practices.map((practice, i) => {
                                    return <option key={i} value={practice.PracticeNameId}>{practice.PracticeName}</option>
                                })}
                            </select>
                        </div> */
                    }
                    {this.state.current === 1 && this.state.showEditPractice === false &&
                        <div>
                            <label htmlFor="choosePractice">Choose from the List of Practice Names</label>
                            {
                                this.practiceNameTable(this.props.practices)
                            }
                        </div>
                    }
                    {this.state.current === 1 && this.state.showEditPractice === true &&
                        <div>
                            <label htmlFor="editPractice">Provide the new Name of Practice</label>
                            <Input className="form-control field-strong" id="editPractice" aria-describedby="editPractice"
                                value={newPracticeName}
                                suffix={<div><Icon type="save" onClick={()=>this.handlePracticeEdit(false, true)} title="Save"/><Icon type="clear" onClick={()=>this.handlePracticeEdit(false)}/></div>}
                                onChange={(e) => this.handleChange(e, 'newPracticeName')}
                                />
                            
                        </div>
                    }

                </Modal>
            </div >
        );
    }
}
export default withRouter(AddUserPage);