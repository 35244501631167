import {
    dashboardConstants
} from "../constants/dashboardConstants";

export const DefaultDashboardState = {
    data: [],
    errorMessage: '',
    successMessage: ''
}

const dashboard = (state = DefaultDashboardState, action) => {
    //console.log(action);
    switch (action.type) {
        case dashboardConstants.PATIENT_STATUS_SUMMARY:
            //console.log(action);
            return {
                ...state
            }
        case dashboardConstants.PATIENT_STATUS_SUMMARY_SUCCESS:
            //console.log(action);
            return {
                ...state,
                data: action.payload.data
            }
        default:
            return state;
    }
}
export default dashboard