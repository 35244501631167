import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import LoginPage from '../components/LoginPage';
import { actionLogin } from '../actions/index';
import { constants } from '../constants';
import { ForgotPassword } from '../components/ForgotPassword';
import SignUp from '../components/User/SignUp'
import { actionForgotPassword, actionClearState, actionLoadLookUp } from '../actions/userAction';
import { actionLoadOccupation } from '../actions/lookupAction';
import { signupUser } from '../actions/index';

class LoginContainer extends React.Component {

  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    detail: PropTypes.object,
    token: PropTypes.string,
    occupations: PropTypes.array
  }

  render() {
    if (this.props.token != null && this.props.token != undefined) {
      return <Redirect to="/dashboard" />
   }
   if (this.props.location.pathname == constants.FORGOT_PASSWORD_PAGE) {
     return (
       <ForgotPassword
         successMessage={this.props.successMessage}
         errorMessage={this.props.errorMessage}
         forgotPassword={this.props.forgotPassword}
       />
     )
   }
   if (this.props.location.pathname == constants.USER_SIGN_UP) {
    return (
      <SignUp
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        loadOccupation = {this.props.loadOccupation}
        occupations = {this.props.occupations}
        signupUser={this.props.signupUser} 
      />
    )
  }
   return (
     <LoginPage
       successMessage={this.props.successMessage}
       errorMessage={this.props.errorMessage}
       login={this.props.login}
       redirectToForgotPassword={this.props.redirectToForgotPassword}
       loadLookUp = {this.props.loadLookUp}
     />
   )
  }
}
const mapStateToProps = (state) => {
  console.log('State in LoginContainer : ', state);
  const { errorMessage, successMessage, detail, token } = state.user;
  const {occupations} = state.lookup;
  return {
    errorMessage,
    successMessage,
    detail,
    token,
    occupations
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (userName, password, callback) => dispatch(actionLogin(userName, password, callback)),
    redirectToForgotPassword: () => dispatch(actionClearState()),
    forgotPassword: (userName, callback) => dispatch(actionForgotPassword(userName, callback)),
    loadLookUp: () => dispatch(actionLoadLookUp()),
    loadOccupation: (callback) => dispatch(actionLoadOccupation(callback)),
    signupUser: (userObject, callback) => dispatch(signupUser(userObject, callback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)