import { lookupConstants } from "../constants/lookupConstants";

const DefaultLookupState = {
    patientStatuses:[],
    roles:[],
    occupations:[],
    referralReasons:[],
    practices:[]
}

const lookup = (state = DefaultLookupState, action) => {
    switch (action.type) {
        case lookupConstants.LOAD_STATUS_SUCCESS:
          return {
            ...state,
            patientStatuses: action.payload.data
          }
        case lookupConstants.LOAD_STATUS_FAILURE:
          return {
            ...state,
            patientStatuses: []
          }
        case lookupConstants.LOAD_REASONS_SUCCESS:
          return {
            ...state,
            referralReasons: action.payload.data
          }
        case lookupConstants.LOAD_REASONS_FAILURE:
          return {
            ...state,
            referralReasons: []
          }
        case lookupConstants.LOAD_OCCUPATION_SUCCESS:
          return {
            ...state,
            occupations: action.payload.data
          }
        case lookupConstants.LOAD_OCCUPATION_FAILURE:
          return {
            ...state,
            occupations: []
          }
        case lookupConstants.LOAD_PRACTICE_SUCCESS:
            return {
              ...state,
              practices: action.payload.data
            }
        case lookupConstants.LOAD_PRACTICE_FAILURE:
            return {
              ...state,
              practices: []
            }
        case lookupConstants.ADD_PRACTICE_SUCCESS:
            return {
              ...state,
              practices: action.payload.data
            }
        case lookupConstants.ADD_PRACTICE_FAILURE:
            return {
              ...state,
              practices: []
            }
        case lookupConstants.DELETE_PRACTICE_SUCCESS:
            return {
                ...state,
                practices: action.payload.data
            }
        case lookupConstants.DELETE_PRACTICE_FAILURE:
            return {
                ...state,
                practices: []
            }
        case lookupConstants.UPDATE_PRACTICE_SUCCESS:
            return {
                  ...state,
                  practices: action.payload.data
            }
        case lookupConstants.UPDATE_PRACTICE_FAILURE:
            return {
                  ...state,
                  practices: []
            }
        default:
            return state
    }
}
export default lookup