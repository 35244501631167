import {
    loadState
} from "../utils/localStorage";

const httpGet = (apiURL, queryParams) => {
    console.log(applyTemplate(apiURL, queryParams));
    return fetch((queryParams ? applyTemplate(apiURL, queryParams) : `${apiURL}`), {
        method: 'GET',
        headers: httpAuthHeaders()
    });
}
const httpPostBeforeAuth = (apiURL, postObject) => {
    return fetch(`${apiURL}`, {
        method: 'POST',
        body: JSON.stringify(postObject),
        headers: httpHeaders()
    });
}
const httpPost = (apiURL, postObject) => {
    return fetch(`${apiURL}`, {
        method: 'POST',
        body: JSON.stringify(postObject),
        headers: httpAuthHeaders()
    });
}
const httpPut = (apiURL, putObject) => {
    return fetch(`${apiURL}`, {
        method: 'PUT',
        body: JSON.stringify(putObject),
        headers: httpAuthHeaders()
    });
}
const httpDelete = (apiURL) => {
    return fetch(`${apiURL}`, {
        method: 'DELETE',
        headers: httpAuthHeaders()
    });
}
const httpDeletewithBody = (apiURL, postData) => {
    return fetch(`${apiURL}`, {
        method: 'DELETE',
        headers: httpAuthHeaders(),
        body: postData
    });
}
const httpImagePost = (apiURL, fileData, userId) => {
    return fetch(`${apiURL}`, {
        method: 'POST',
        body: fileData,
        headers: httpHeadersForUpload(userId)
    });
}
const httpImageGet = (apiURL, queryParams) => {
    console.log(applyTemplate(apiURL, queryParams));
    return fetch((queryParams ? applyTemplate(apiURL, queryParams) : `${apiURL}`), {
        method: 'GET',
        headers: httpAuthHeadersForGetImage()
    });
}
const applyTemplate = (inputString, templateObj) => {
    var outputString = '';
    if (inputString && templateObj) {
        outputString = inputString;
        Object.keys(templateObj).forEach(key => {
            const templateKey = '{' + key + '}';
            const templateValue = templateObj[key];
            outputString = outputString.replace(templateKey, templateValue);
        });
    }

    return outputString;
}
const httpHeaders = () => {
    return ({
        "Content-Type": "application/json"
    });
}
const httpAuthHeaders = () => {
    return ({
        "Content-Type": "application/json",
        "x-access-token": (loadState()) ? loadState().user.token : '',
        "user-id": (loadState()) ? loadState().user.detail.userId : ''
    });
}
const httpHeadersForUpload = (userId) => {
    return ({
        "x-access-token": (loadState()) ? loadState().user.token : '',
        "user-id": (loadState()) ? loadState().user.detail.userId : ''
    });
}
const httpAuthHeadersForGetImage = () => {
    return ({
        "x-access-token": (loadState()) ? loadState().user.token : '',
        "user-id": (loadState()) ? loadState().user.detail.userId : ''
    });
}
export const httpService = {
    httpGet,
    httpPost,
    httpPut,
    httpDelete,
    httpImagePost,
    httpImageGet,
    httpDeletewithBody,
    httpPostBeforeAuth
};