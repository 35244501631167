import React from 'react'
import { connect } from 'react-redux'
import { actionSearchUsers, actionDeleteUserById, actionDisableUserById, actionEnableUserById, actionApproveUserById, actionRejectUserById } from '../../actions/index';
import { actionLoadPractice, actionNewPractice, actionDeletePractice, actionUpdatePractice } from '../../actions/lookupAction';
import PropTypes from 'prop-types'
import UserListComponent from '../../components/User/List';

class UserListContainer extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    errors: PropTypes.object,
    userList: PropTypes.array,
    total: PropTypes.number,
    loading: PropTypes.bool,
    userStatus: PropTypes.number,
    practices: PropTypes.array
  }
  render() {
    
    return (
      <UserListComponent
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        userList={this.props.userList}
        total={this.props.total}
        loading={this.props.loading}
        searchUsers={this.props.populateUsers}
        deleteUserById={this.props.deleteUserById}
        approveUserById={this.props.approveUserById}
        rejectUserById={this.props.rejectUserById}
        disableUserById={this.props.disableUserById}
        enableUserById={this.props.enableUserById}
        userStatus={this.props.userStatus}
        practices={this.props.practices}
        loadPractice={this.props.loadPractice}
        addNewPractice={this.props.addNewPractice}
        updatePractice={this.props.updatePractice}
        deletePractice={this.props.deletePractice}
      />
    )
  }
}
const mapStateToProps = (state) => {
  const { userList, total, deleteReponse, disableResponse, enableResponse, loading, approveResponse, rejectResponse, userStatus } = state.user;
  const { errorMessage, successMessage } = state.user == null ? null : state.user;
  const {practices} = state.lookup;
  return {
    errorMessage, successMessage, userList, total, deleteReponse, disableResponse, enableResponse, approveResponse, rejectResponse, loading, userStatus, practices
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    populateUsers: (searchObject, callback, rCallback) => dispatch(actionSearchUsers(searchObject, callback, rCallback)),
    deleteUserById: (userId, callback, rCallback) => dispatch(actionDeleteUserById(userId, callback, rCallback)),
    disableUserById:(userId,callback, rCallback)=>dispatch(actionDisableUserById(userId, callback, rCallback)),
    enableUserById:(userId,callback, rCallback)=>dispatch(actionEnableUserById(userId, callback, rCallback)),
    approveUserById:(userId,callback, rCallback)=>dispatch(actionApproveUserById(userId, callback, rCallback)),
    rejectUserById:(userId,callback, rCallback)=>dispatch(actionRejectUserById(userId, callback, rCallback)),
    loadPractice: (callback, rCallback) => dispatch(actionLoadPractice(callback, rCallback)),
    addNewPractice: (practiceName, callback, rCallback) => dispatch(actionNewPractice(practiceName,callback, rCallback)),
    updatePractice: (practiceName, practiceNameId, callback, rCallback) => dispatch(actionUpdatePractice(practiceName,practiceNameId, callback, rCallback)),
    deletePractice: (practiceNameId, callback, rCallback) => dispatch(actionDeletePractice(practiceNameId,callback, rCallback))
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer)