import {
  userConstants,
  UserCRUDConstants
} from "../constants/userConstants";
import {
  constants
} from "../constants";

const DefaultUserState = {
  userName: '',
  password: '',
  detail: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    roleId: 1
  },
  errorMessage: '',
  successMessage: '',
  loading: false,
  userList: [],
  userStatus:1
}

const user = (state = DefaultUserState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        userName: action.payload.userName,
        password: action.payload.password,
        errorMessage: '',
        successMessage: ''
      }
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        detail: action.payload.data,
        successMessage: action.payload.message,
        errorMessage: '',
        token: action.payload.token
      }
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        detail: {},
        errorMessage: action.payload,
        successMessage: ''
      }
    case UserCRUDConstants.ADD_USER_REQUEST:
      return{
        ...state,
        loading: true,
        errorMessage: '',
        successMessage: ''
      }
    case UserCRUDConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.message,
        errorMessage: '',
        loading: false
      }
    case UserCRUDConstants.ADD_USER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        loading: false
      }
    case UserCRUDConstants.SIGNUP_USER_SUCCESS:
      return {
          ...state,
          successMessage: action.payload.message,
          errorMessage: ''
        }
    case UserCRUDConstants.SIGNUP_USER_FAILURE:
      return {
          ...state,
          errorMessage: action.payload,
          successMessage: ''
        }
    case UserCRUDConstants.SEARCH_USER_REQUEST:
      return {
        ...state,
        query: action.payload.query,
        page: action.payload.page,
        errorMessage: '',
        loading: true,
        successMessage: ''
      }
    case UserCRUDConstants.SEARCH_USER_SUCCESS:
      return {
        ...state,
        userList: action.payload.data,
        total: action.payload.total,
        loading: false,
        successMessage: action.payload.message,
        errorMessage: ''
      }
    case UserCRUDConstants.SEARCH_USER_FAILURE:
      return {
        ...state,
        userList: [],
        loading: false,
        errorMessage: action.payload,
        successMessage: ''
      }
    case UserCRUDConstants.DELETE_USER_REQUEST:
      return {
        ...state,
        userId: action.payload,
        errorMessage: '',
        successMessage: ''
      }
    case UserCRUDConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteReponse: action.payload.data,
        successMessage: action.payload.message,
        errorMessage: ''
      }
    case UserCRUDConstants.DELETE_USER_FAILURE:
      return {
        ...state,
        deleteReponse: {},
        errorMessage: action.payload,
        successMessage: ''
      }
      case UserCRUDConstants.DISABLE_USER_REQUEST:
      return {
        ...state,
        userId: action.payload,
        errorMessage: '',
        successMessage: ''
      }
    case UserCRUDConstants.DISABLE_USER_SUCCESS:
      return {
        ...state,
        deleteReponse: action.payload.data,
        successMessage: action.payload.message,
        errorMessage: ''
      }
    case UserCRUDConstants.DISABLE_USER_FAILURE:
      return {
        ...state,
        deleteReponse: {},
        errorMessage: action.payload,
        successMessage: ''
      }
      //Approve user
      case UserCRUDConstants.APPROVE_USER_REQUEST:
      return {
        ...state,
        userId: action.payload,
        errorMessage: '',
        successMessage: ''
      }
      case UserCRUDConstants.APPROVE_USER_SUCCESS:
      return {
        ...state,
        approveReponse: action.payload.data,
        successMessage: action.payload.message,
        errorMessage: ''
      }
    case UserCRUDConstants.APPROVE_USER_FAILURE:
      return {
        ...state,
        approveReponse: {},
        errorMessage: action.payload,
        successMessage: ''
      }
      //Reject user
      case UserCRUDConstants.REJECT_USER_REQUEST:
      return {
        ...state,
        userId: action.payload,
        errorMessage: '',
        successMessage: ''
      }
      case UserCRUDConstants.REJECT_USER_SUCCESS:
      return {
        ...state,
        rejectReponse: action.payload.data,
        successMessage: action.payload.message,
        errorMessage: ''
      }
    case UserCRUDConstants.REJECT_USER_FAILURE:
      return {
        ...state,
        rejectReponse: {},
        errorMessage: action.payload,
        successMessage: ''
      }
    // Change Password
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        loading: true
      }
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.message,
        errorMessage: '',
        loading: false
      }
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        loading: false
      }
    // Reset password by super admin
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      }
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.message,
        errorMessage: ''
      }
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: ''
      }
    // Forgot password
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      }
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.message,
        errorMessage: ''
      }
    case userConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: ''
      }
    // Get User
    case UserCRUDConstants.GET_USER_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      }
    case UserCRUDConstants.GET_USER_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.message,
        errorMessage: '',
        detail: action.payload.data
      }
    case UserCRUDConstants.GET_USER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: ''
      }
    case UserCRUDConstants.GET_USER_BY_USERNAME_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      }
    case UserCRUDConstants.GET_USER_BY_USERNAME_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.message,
        errorMessage: '',
        detail: action.payload.data
      }
    case UserCRUDConstants.GET_USER_BY_USERNAME_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: ''
      }
    case constants.PAGE_REDIRECT:
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
    }
    case constants.LOGOUT_REQUEST:
      return{
        ...state,
        errorMessage: '',
        successMessage: ''
    }    
    case constants.LOGOUT_FAILURE:
      return{
        errorMessage: '',
        successMessage: ''
    }
    
    case constants.CLEAR_STATE:
      return DefaultUserState;
    case constants.SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.payload
      }
      case UserCRUDConstants.GET_EDITUSER_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        loading: true
      }
    case UserCRUDConstants.GET_EDITUSER_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.message,
        errorMessage: '',
        userEntity: action.payload.data,
        loading: false
      }
    case UserCRUDConstants.GET_EDITUSER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        loading: false
      }
    case UserCRUDConstants.USER_MENU_REQUEST :
      return {
        ...state,
        userStatus : action.payload
      }
    default:
      return state;

  }
}
export default user