import React from "react";
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
export default class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    data: PropTypes.array
  }

  redirectToTargetWithoutToast = (target) => {
    this.context.router.history.push(target)
  }

  componentDidMount() {
    this.props.patientStatusSummary(this.redirectToTargetWithoutToast);
  }

  getStatusName = (patientStatus) => {
    if (patientStatus.PatientStatusName === 'New Patient') {
      return 'REFERRALS RECEIVED';
    }
    return patientStatus.PatientStatusName.toUpperCase();
  }
  getTotalCount = () =>{
    let total = 0;
    this.props.data.forEach(pss=> total = total+pss.PatientCount);
    return total
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row dashboard_height">
        <div className="col-md-12 boxset-holder">
        <div className="countbox-set">
          <h3>{this.getTotalCount()}</h3>
          <h6 className="mt-4 mb-4 text-uppercase">Total Referrals</h6>
        </div>
        {
                this.props.data ? this.props.data.map((patientStatus, i) =>
                <div key={i} className="countbox-set">
                <Link to={{
                    pathname: `/catalogue/patients`,
                    search:"?statusId="+patientStatus.PatientStatusId
                  }} >
                    <div>
                      <h3>{patientStatus.PatientCount}</h3>
                      <h6 className="mt-4 mb-4 text-uppercase">{this.getStatusName(patientStatus)}</h6>
                    </div>
                    </Link>
                </div>
         ) : null
        }
        
        </div>
          {/* <div className="col-md-12">
            <div className="row mt-3">
              {
                this.props.data ? this.props.data.map((patientStatus, i) =>                    
                  <div className="col-md-3" key={i}>
                   <Link to={{
                    pathname: `/catalogue/patients`,
                    search:"?statusId="+patientStatus.PatientStatusId
                  }} > 
                    <div className="box flex_box flex_column">                                   
                      <span>{patientStatus.PatientCount}</span>
                     <h6 className="mt-4 mb-4 text-uppercase">{this.getStatusName(patientStatus)}</h6>
                    </div>
                    </Link>   
                  </div>                               
                ) : null
              }
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
