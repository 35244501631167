import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AddPatientPage from '../../components/Patient/Add';
import { actionAddNewPatient, actionGetPatient, actionUpdatePatient, actionPatientExist, actionImageUpload, actionImageDelete, actionGetImage, actionLoadPatientStatusHistory } from '../../actions/patientAction';

class AddPatientContainer extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    patientEntity: PropTypes.object,
    referralReasons: PropTypes.array,
    patientStatuses: PropTypes.array,
    referredBy: PropTypes.object,
    exists: PropTypes.bool,
    patientStatusHistory: PropTypes.array
  }
  render() {
    return (
      <AddPatientPage 
      patientStatuses = {this.props.patientStatuses}
      referralReasons = {this.props.referralReasons}
      referredBy = {this.props.referredBy}
      loading = {this.props.loading}
      patientEntity = {this.props.patientEntity}
      add = {this.props.add}
      fetch = {this.props.fetch}
      update = {this.props.update}
      exist = {this.props.exist}
      upload = {this.props.upload}
      deleteImage = {this.props.deleteImage}
      getImage={this.props.getImage} 
      patientStatusHistory = {this.props.patientStatusHistory}
      loadPatientStatusHistory = {this.props.loadPatientStatusHistory}/>
    )
  }
}
const mapStateToProps = (state) => {
  const {patientStatuses, referralReasons} = state.lookup
  const referredBy = state.user.detail
  const loading = state.patient.loading
  const {patientEntity} = state.patient 
  const patientStatusHistory = state.patient.patientStatusHistory
  return {
    patientStatuses,
    referralReasons,
    referredBy,
    patientEntity,
    patientStatusHistory,
    loading
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (patientObject, callback, rCallback) => dispatch(actionAddNewPatient(patientObject, callback, rCallback)),
    fetch: (patientId, callback, rCallback) => dispatch(actionGetPatient(patientId, callback, rCallback)),
    update: (patientObject, callback, rCallback) => dispatch(actionUpdatePatient(patientObject, callback, rCallback)),
    exist: (emailAddress, callback, rCallback) => dispatch(actionPatientExist(emailAddress, callback, rCallback)),
    upload: (file, patient, userId, callback, rCallback) => dispatch(actionImageUpload(file, patient, userId, callback, rCallback)),
    deleteImage: (imgDetail, callback, rCallback) => dispatch(actionImageDelete(imgDetail, callback, rCallback, rCallback)),
    getImage: (url, callback, rCallback) => dispatch(actionGetImage(url, callback, rCallback)),
    loadPatientStatusHistory : (patientId, callback, rCallback) => dispatch(actionLoadPatientStatusHistory(patientId, callback, rCallback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientContainer)