import { patientConstants } from "../constants/patientConstants";

const DefaultPatientState = {
    patientsList:[],
    patientEntity:{},
    patientStatusHistory:[],
    loading: false,
}

const patient = (state = DefaultPatientState, action) => {
    switch (action.type) {
        case patientConstants.SEARCH_PATIENTS_SUCCESS:
          return {
            ...state,
            patientsList: action.payload.data,
            total: action.payload.total,
            errorMessage: '',
            successMessage: ''
          }
        case patientConstants.SEARCH_PATIENTS_FAILURE:
          return {
            ...state,
            errorMessage: action.payload,
            successMessage: ''
          }
        case patientConstants.GET_PATIENT_REQUEST:
            return {
              ...state,
              patientId: action.payload,
              loading: true
            }
        case patientConstants.GET_PATIENT_SUCCESS:
            return {
              ...state,
              patientEntity: action.payload.data,
              errorMessage: '',
              successMessage: '',
              loading: false
            }
        case patientConstants.GET_PATIENT_FAILURE:
            return {
              ...state,
              errorMessage: action.payload,
              successMessage: '',
              loading: false
            }
        case patientConstants.ADD_PATIENT_REQUEST:
                return {
                  ...state,
                  errorMessage: '',
                  successMessage: '',
                  loading: true
                }
        case patientConstants.ADD_PATIENT_SUCCESS:
              return {
                ...state,
                errorMessage: '',
                successMessage: action.payload.message,
                loading: false
              }
        case patientConstants.ADD_PATIENT_FAILURE:
              return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                loading: false
              }
        case patientConstants.UPDATE_PATIENT_REQUEST:
                  return {
                    ...state,
                    errorMessage: '',
                    successMessage: '',
                    loading: true
                  }
        case patientConstants.UPDATE_PATIENT_SUCCESS:
              return {
                ...state,
                errorMessage: '',
                successMessage: action.payload.message,
                loading: false
              }
        case patientConstants.UPDATE_PATIENT_FAILURE:
              return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                loading: false
              }
        case patientConstants.DELETE_PATIENT_REQUEST:
              return {
                ...state,
                patientId: action.payload,
                errorMessage: '',
                successMessage: ''
              }
        case patientConstants.DELETE_PATIENT_SUCCESS:
          return {
            ...state,
            successMessage: action.payload.message,
            errorMessage: ''
          }
        case patientConstants.DELETE_PATIENT_FAILURE:
          return {
            ...state,
            errorMessage: action.payload,
            successMessage: ''
          }
        case patientConstants.EXIST_PATIENT_REQUEST:
          return {
            ...state,
            emailAddress: action.payload
          }
        case patientConstants.EXIST_PATIENT_SUCCESS:
          return {
            ...state,
            exists:action.payload.exists
          }
        case patientConstants.EXIST_PATIENT_FAILURE:
          return {
            ...state,
            errorMessage: action.payload,
            successMessage: ''
          }
        case patientConstants.GET_PATIENT_STATUS_HISTORY_SUCCESS:
            return {
              ...state,
              patientStatusHistory:action.payload.data
            }
        case patientConstants.GET_PATIENT_STATUS_HISTORY_FAILURE:
            return {
              ...state,
              errorMessage: action.payload,
              successMessage: ''
            }
        default:
        return state
    }
}
export default patient