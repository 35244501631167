export const lookupConstants = {
    LOAD_REASONS_REQUEST: 'LOAD_REASONS_REQUEST',
    LOAD_REASONS_SUCCESS: 'LOAD_REASONS_SUCCESS',
    LOAD_REASONS_FAILURE: 'LOAD_REASONS_FAILURE',

    LOAD_STATUS_REQUEST: 'LOAD_STATUS_REQUEST',
    LOAD_STATUS_SUCCESS: 'LOAD_STATUS_SUCCESS',
    LOAD_STATUS_FAILURE: 'LOAD_STATUS_FAILURE',

    LOAD_OCCUPATION_REQUEST: 'LOAD_OCCUPATION_REQUEST',
    LOAD_OCCUPATION_SUCCESS: 'LOAD_OCCUPATION_SUCCESS',
    LOAD_OCCUPATION_FAILURE: 'LOAD_OCCUPATION_FAILURE',

    LOAD_PRACTICE_REQUEST: 'LOAD_PRACTICE_REQUEST',
    LOAD_PRACTICE_SUCCESS: 'LOAD_PRACTICE_SUCCESS',
    LOAD_PRACTICE_FAILURE: 'LOAD_PRACTICE_FAILURE',

    ADD_PRACTICE_REQUEST: 'ADD_PRACTICE_REQUEST',
    ADD_PRACTICE_SUCCESS: 'ADD_PRACTICE_SUCCESS',
    ADD_PRACTICE_FAILURE: 'ADD_PRACTICE_FAILURE',

    DELETE_PRACTICE_REQUEST: 'DELETE_PRACTICE_REQUEST',
    DELETE_PRACTICE_SUCCESS: 'DELETE_PRACTICE_SUCCESS',
    DELETE_PRACTICE_FAILURE: 'DELETE_PRACTICE_FAILURE',

    UPDATE_PRACTICE_REQUEST: 'UPDATE_PRACTICE_REQUEST',
    UPDATE_PRACTICE_SUCCESS: 'UPDATE_PRACTICE_SUCCESS',
    UPDATE_PRACTICE_FAILURE: 'UPDATE_PRACTICE_FAILURE'
}