import { combineReducers } from 'redux'

import user from './userReducer';
import dashboard from './dashboardReducer';
import patient from './patientReducer';
import lookup from './lookupReducer';

const rootReducer = combineReducers({
  user, dashboard, patient, lookup
})

export default rootReducer
