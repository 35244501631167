import { createStore, applyMiddleware } from 'redux'
import { createLogger  } from 'redux-logger'
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { loadState, saveState } from './utils/localStorage';

const loggerMiddleware = createLogger()
const persistedState = loadState()
const store = createStore(rootReducer, persistedState, applyMiddleware(loggerMiddleware, thunk))

store.subscribe(()=> {
    saveState(store.getState())
})

export default store