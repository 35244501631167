export const apiConstants = {
    API_URL: 'https://app.avantlasikspa.com/api',
    WEB_SOCK_URL: 'wss://app.avantlasikspa.com:1337',
    //API_URL: 'http://localhost:3002/api',
    //WEB_SOCK_URL: 'ws://localhost:1337',
    GOOGLE_CAPTCHA_SITE_KEY: '6Lc6ftMUAAAAAGXenVu4kfILwmmovgTxJWACfGw3',
    UI_IDLE_TIMEOUT: '60' //in minutes
};

export const DashboardAPI = {
    PatientStatusSummary: apiConstants.API_URL + '/dashboard/patient/status/summary'
};

export const UserAPI = {
    Login: apiConstants.API_URL + '/auth/login',
    ChangePassword: apiConstants.API_URL + '/auth/password/change',
    Logout: apiConstants.API_URL + '/users/logout?userid=',
    ForgotPassword: apiConstants.API_URL + '/auth/password/forgot',
    ResetPassword: apiConstants.API_URL+'/users/password/reset',
    DownloadImage: apiConstants.API_URL+'/users/images',
    UploadImage: apiConstants.API_URL+'/users/images',
    VerifyCaptchaAPI: apiConstants.API_URL+'/auth/captcha',
    //CURD
    AddNewUser: apiConstants.API_URL + '/users/add',
    SignupUser: apiConstants.API_URL + '/users/signup',
    UpdateUser: apiConstants.API_URL + '/users/id/',
    DeleteUser: apiConstants.API_URL + '/users/id/',
    ApproveUser: apiConstants.API_URL + '/users/approve/id/',
    RejectUser: apiConstants.API_URL + '/users/reject/id/',
    DisableUser: apiConstants.API_URL + '/users/disable/',
    EnableUser: apiConstants.API_URL + '/users/enable/',
    UserSearch: apiConstants.API_URL + '/users/search?query={query}&pagesize={pagesize}&page={page}&t={userStatusType}',
    GetUser: apiConstants.API_URL + '/users/id/',
    GetUserByUserName: apiConstants.API_URL + '/users/',
}

export const PatientAPI = {
    Filter: `${apiConstants.API_URL}/patients/filter`,
    Add: `${apiConstants.API_URL}/patients/add`,
    Get: `${apiConstants.API_URL}/patients/:patientId`,
    StatusHistory: `${apiConstants.API_URL}/patients/statushistory/:patientId`,
    Delete: `${apiConstants.API_URL}/patients/:patientId`,
    Update: `${apiConstants.API_URL}/patients/:patientId`,
    Exist: `${apiConstants.API_URL}/patients/check/exists?emailAddress={emailAddress}`,
    Upload: `${apiConstants.API_URL}/patients/images/add?patientid={patientId}`,
    GetImage: `${apiConstants.API_URL}/patients/images/get/b64?patientid={patientId}&filename={fileName}`,
    DeleteImage: `${apiConstants.API_URL}/patients/images/delete`
}

export const LookupAPI = {
    PatientStatus: `${apiConstants.API_URL}/lookup/patientstatus/all`,
    ReferralReason: `${apiConstants.API_URL}/lookup/referralreason/all`,
    Occupation: `${apiConstants.API_URL}/lookup/occupations/all`,
    Practice: `${apiConstants.API_URL}/lookup/practices/all`,
    AddNewPractice: `${apiConstants.API_URL}/lookup/practice/add/new`,
    DeletePractice: `${apiConstants.API_URL}/lookup/practice/delete`,
    UpdatePractice: `${apiConstants.API_URL}/lookup/practice/update`
}
