import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import NoEntity from '../../components/NoEntity';
import Modal from 'react-modal';
import { modalcustomStyles } from '../../styles/customStyles';
import { toast } from 'react-toastify';
import { Link, withRouter } from "react-router-dom";
import { constants } from '../../constants';
import { Icon, Spin, Input, Button, Steps, Modal as AntModal, Table, Form, Popconfirm } from 'antd';
import Highlighter from 'react-highlight-words';
const { Step } = Steps;


export class UserListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      userList: [],
      errors: {
        query: ''
      },
      successMessage: '',
      errorMessage: '',
      searchUser: {
        query: '',
        pagesize: 10,
        page: 0,
        userStatusType: this.props.userStatus
      },
      userId: 0,
      isDelete: true,
      isEnable: false,
      isApprove: false,
      isReject: false,
      approvalStatus: 0,
      defaultTabKey: 1,
      visible: false,
      newPracticeName: '',
      practiceName: '',
      showEditPractice: false,
      newPracticeNameId: 0,
      current:0,
      callPracticeUpdate: false,
      nameExists: false
    };
    this.keyPress = this.keyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.disableUser = this.disableUser.bind(this);
    this.enableUser = this.enableUser.bind(this);
    this.approveUser = this.approveUser.bind(this);
    this.rejectUser = this.rejectUser.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.redirectToTarget = this.redirectToTarget.bind(this);
    this.practiceNameTable = this.practiceNameTable.bind(this);
    this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePracticeEdit = this.handlePracticeEdit.bind(this);
    this.addNewPractice = this.addNewPractice.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  static contextTypes = {
    router: PropTypes.object
  }

  redirectToTarget = (target) => {
    this.context.router.history.push(target)
  }
  setErrors = () => {
    this.state.errors["query"] = '';
    if (!!this.state.searchUser.query) {
      this.state.errors["query"] = 'Enter query';
    }
    this.setState(this.state);
  }

  handleChangeSearchUser = (event, key) => {
    this.state.searchUser[key] = event.target.value
    this.setState(this.state)
  }
  componentDidMount() {
    this.state.searchUser.userStatusType = this.props.userStatus;
    this.setState(this.state);
    this.props.loadPractice();
    this.props.searchUsers(this.state.searchUser, undefined, this.redirectToTarget);
  }
  componentWillReceiveProps(newprops) {
    this.state.searchUser.userStatusType = newprops.userStatus;
    this.setState(this.state);
    if (newprops.userStatus != this.props.userStatus) {
      this.props.searchUsers(this.state.searchUser, undefined, this.redirectToTarget);
    }

  }

  keyPress(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      this.props.searchUsers(this.state.searchUser, undefined, this.redirectToTarget);
    }
  }
  handlePageClick = data => {
    this.state.searchUser.page = data.selected;
    this.setState(this.state);
    this.props.searchUsers(this.state.searchUser, undefined, this.redirectToTarget);
  }
  handleChange(e) {
    this.state.searchUser.query = e.target.value;
    this.setState(this.state);
  }

  //approvalStatus - 1 is approved
  //2 is rejected
  //0 is no action taken
  openModal(userId, isDelete, isEnable, isApprove, isReject) {
    this.setState({ modalIsOpen: true, userId: userId, isDelete: isDelete, isEnable: isEnable, isApprove: isApprove, isReject: isReject });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  deleteUser = (e) => {
    this.props.deleteUserById(this.state.userId, this.callbackDeleteUserById, this.redirectToTarget);
    this.closeModal();
  }
  callbackDeleteUserById = (responseCallback) => {
    toast.dismiss();
    this.closeModal();
    toast.success("User Deleted Successfully.", { toastId: 1 });
    this.props.searchUsers(this.state.searchUser, undefined, this.redirectToTarget);
  }
  disableUser = (e) => {
    this.props.disableUserById(this.state.userId, this.callbackDisableUserById, this.redirectToTarget);
    this.closeModal();
  }
  enableUser = (e) => {
    this.props.enableUserById(this.state.userId, this.callbackEnableUserById, this.redirectToTarget);
    this.closeModal();
  }
  callbackDisableUserById = (responseCallback) => {
    toast.dismiss();
    this.closeModal();
    toast.success("User Disabled Successfully.", { toastId: 2 });
    this.props.searchUsers(this.state.searchUser, undefined, this.redirectToTarget);
  }
  callbackEnableUserById = (responseCallback) => {
    toast.dismiss();
    this.closeModal();
    toast.success("User Enabled Successfully.", { toastId: 3 });
    this.props.searchUsers(this.state.searchUser, undefined, this.redirectToTarget);
  }
  approveUser = (e) => {
    this.props.approveUserById(this.state.userId, this.callbackApproveUserById, this.redirectToTarget);
    this.closeModal();
  }
  rejectUser = (e) => {
    this.props.rejectUserById(this.state.userId, this.callbackRejectUserById, this.redirectToTarget);
    this.closeModal();
  }
  callbackApproveUserById = (responseCallback) => {
    toast.dismiss();
    this.closeModal();
    toast.success("User Approved Successfully.", { toastId: 4 });
    this.props.searchUsers(this.state.searchUser, undefined, this.redirectToTarget);
  }
  callbackRejectUserById = (responseCallback) => {
    toast.dismiss();
    this.closeModal();
    toast.success("User Rejected Successfully.", { toastId: 5 });
    this.props.searchUsers(this.state.searchUser, undefined, this.redirectToTarget);
  }
  searchUser = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.searchUsers(this.state.searchUser, undefined, this.redirectToTarget);
  }
  
  handleCancel = e => {
    this.setState({
      visible: false,
      showEditPractice: false
    });
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handlePracticeEdit = (enable, callUpdate, cellData) => {

    if (cellData) {
      this.setState({ newPracticeName: cellData.practicename, newPracticeNameId: cellData.practiceid });
    }
    if (callUpdate && this.state.callPracticeUpdate && this.state.newPracticeName && this.state.newPracticeName.trim().length > 0) {
      const { newPracticeName, newPracticeNameId } = this.state;
      let list = this.props.practices.filter(p => p.PracticeName === newPracticeName && p.PracticeNameId !== newPracticeNameId);
      let practiceNameId = list && list.length > 0 ? list[0].PracticeNameId : undefined;
      if( !practiceNameId ) {
        this.props.updatePractice(newPracticeName, newPracticeNameId, () => {
          if (this.props.errorMessage) {
            toast.error(() => <div>{this.props.errorMessage}</div>)
            return
          }
          if (this.props.successMessage) {
            toast.success(() => <div>{this.props.successMessage}</div>)
          }
        }, this.redirectToTargetWithoutToast);
      } else if ( newPracticeName && newPracticeName.trim().length > 1){
        toast.error(() => <div>{constants.PRACTICE_NAME_ALREADY_EXISTS}</div>)
        return
      }
      
    }
    this.setState({ showEditPractice: enable });
  };

  handleDelete = (practiceRow) => {
    console.log(practiceRow.key)
    this.props.deletePractice(practiceRow.practiceid, () => {
      if (this.props.errorMessage) {
        toast.error(() => <div>{this.props.errorMessage}</div>)
        return
      }
      if (this.props.successMessage) {
        toast.success(() => <div>{this.props.successMessage}</div>)
      }
    }, this.redirectToTargetWithoutToast);
  };
  practiceNameTable = (practiceData) => {
    const { selectedRowKeys } = this.state;   
    const columns = [      
      {
        title: 'Name of Practice',
        dataIndex: 'practicename',
        key: 'practicename',
        width: '75%',
        ...this.getColumnSearchProps('practicename'),
      },
      {
        title: 'Options',
        dataIndex: 'options',
        render: (text, record) =>
          practiceData.length >= 1 ? (
            <div>
              <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record)}>
                <Icon type="delete" title="Delete" />
              </Popconfirm>
              <Icon type="edit" title="Edit" onClick={() => this.handlePracticeEdit(true, false, record)} />
            </div>
          ) : null,
      }];
    let data = [];
    if ( practiceData ){
      practiceData.map((practice, i) => {
        data.push({
          key: i,
          practicename: practice.PracticeName,
          practiceid: practice.PracticeNameId
        });
  
      })
    }
    
    return <Table columns={columns} dataSource={data} pagination={{ pageSize: 5 }} size="small" />;
  }
  showModal = () => {
    this.setState({
      visible: true,
      current:0
    });
  };
  addNewPractice = () => {
    let list = this.props.practices.filter(p => p.PracticeName === this.state.practiceName);
    let practiceNameId = list && list.length > 0 ? list[0].PracticeNameId : undefined;
    if (!practiceNameId && this.state.practiceName && this.state.practiceName.trim().length > 0) {
      this.props.addNewPractice(this.state.practiceName, () => {
        this.setState({practiceName:''});
        if (this.props.errorMessage) {
          toast.error(() => <div>{this.props.errorMessage}</div>)
          return
        }
        if (this.props.successMessage) {
          toast.success(() => <div>{this.props.successMessage}</div>)
        }
      }, this.redirectToTargetWithoutToast);
    } else if ( !this.state.practiceName || this.state.practiceName.trim().length <= 0) {
      toast.info(() => <div>{constants.PRACTICE_NAME_CANT_EMPTY}</div>)
      return
    } else {
      toast.error(() => <div>{constants.PRACTICE_NAME_ALREADY_EXISTS}</div>)
      return
    }

  };
  handlePracticeNameChange = (event, key) => {
    
    if (key.toLowerCase() === constants.PRACTICE_NAME) {
      this.state.practiceName = event.target.value;
      let list = this.props.practices.filter(p => p.PracticeName === event.target.value);
      let practiceNameId = list && list.length > 0 ? list[0].PracticeNameId : undefined;
      if (practiceNameId) {
          this.setState({ nameExists: true });
          this.state.practiceNameId = practiceNameId;
      } else {
          this.setState({ nameExists: false });
      }
  }

  if (key.toLowerCase() === constants.NEW_PRACTICE_NAME) {
      if( this.state.newPracticeName !== event.target.value ){
          this.state.newPracticeName = event.target.value;
          this.state.callPracticeUpdate = true;
      }
  }
  this.setState(this.state);
  }
  handleOk = e => {
    if (this.state.current === 0) {
        this.setState({ current: 1, showEditPractice: false });
    } else if (this.state.current === 1) {
      this.setState({ current: 0, showEditPractice: false });
    }else {
        this.setState({
            visible: false,
        });
    }

};

handleCancel = e => {
    this.setState({
        visible: false,
        showEditPractice: false,
        practiceName:'',
        newPracticeName:''
    });
};
  render() {
    let endIndex = this.state.searchUser.page * this.state.searchUser.pagesize + this.state.searchUser.pagesize;
    endIndex = (endIndex > this.props.total) ? this.props.total : endIndex;

    const addIcon = <Icon type="plus-circle" onClick={this.addNewPractice} title="Add New Practice"/>
    const { newPracticeName, practiceName } = this.state;
    return (
      <div>
        <div className="search_panal flex_btwn">
          <div className="col-md-6">
            <form className="form-inline md-form form-sm active-cyan-2 search_bx">
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Search Users" onKeyDown={this.keyPress}
                  value={this.state.searchUser.query} onChange={(e) => this.handleChangeSearchUser(e, 'query')} />
                <div className="input-group-append">
                  <button className="btn" type="submit" onClick={(e) => this.searchUser(e)}><img src={require('../../images/search.svg')} /></button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6 flex_end">
            <a href="/catalogue/add-user"><button type="button" className="btn btn-primary flex_box">
              <img className="mr-2 w_13" src={require('../../images/add.svg')} />Add User</button>
            </a> &nbsp;
            <button type="button" className="btn btn-primary flex_box" onClick={this.showModal}>
              <Icon type="setting" />&nbsp;Practice</button>
          </div>
        </div>
        <Spin spinning={this.props.loading}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email </th>
                <th>Occupation</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.userList.map(userData =>
                  <tr key={userData.userId}>
                    <td><Link to={constants.VIEW_USER_PAGE.replace(':userId', userData.userId)}>{userData.firstName}&nbsp;{userData.lastName}</Link></td>
                    <td>{userData.emailAddress}</td>
                    <td>{userData.occupationName}</td>
                    <td>{userData.roleName}</td>
                    <td>
                      <Link to={"/catalogue/add-user/" + userData.userId}>
                        <img className="cursor w-30" title="Edit" src={require('../../images/edit.svg')} />
                      </Link>&nbsp;&nbsp;
                      <img className="cursor w-30" title="Delete" onClick={(e) => this.openModal(userData.userId, true, false, false, false)} src={require('../../images/delete.svg')} />&nbsp;&nbsp;
                      {parseInt(this.state.searchUser.userStatusType) === 1 && userData.isDisabled === 0 && <img style={{ width: '25px', height: '25px' }} title="Disable" className="cursor" onClick={(e) => this.openModal(userData.userId, false, false, false, false)} src={require('../../images/user_disable.svg')} />}
                      {parseInt(this.state.searchUser.userStatusType) === 1 && userData.isDisabled === 1 && <img style={{ width: '25px', height: '25px' }} title="Enable" className="cursor" onClick={(e) => this.openModal(userData.userId, false, true, false, false)} src={require('../../images/user_enable.svg')} />}
                      {parseInt(this.state.searchUser.userStatusType) !== 1 && <img style={{ width: '30px', height: '30px' }} title="Approve" className="cursor"  onClick={(e) => this.openModal(userData.userId, false, false, true, false)} src={require('../../images/checkMark-01.svg')} />}&nbsp;
                      {parseInt(this.state.searchUser.userStatusType) === 0 && <img style={{ width: '30px', height: '30px' }} title="Reject" className="cursor"  onClick={(e) => this.openModal(userData.userId, false, false, false, true)} src={require('../../images/disable-01.svg')} />}&nbsp;
                    </td>
                  </tr>
                )
              }
              {
                !(this.props.userList && this.props.userList.length) ? <NoEntity message={"No Users Found.."} colspan={4} /> : null
              }
            </tbody>
          </table>
          {(this.props.userList && this.props.userList.length > 0) &&
            <div className="flex_btwn custom_pagination">
              <div className="total_count">Shows {(this.state.searchUser.page * this.state.searchUser.pagesize) + 1} - {endIndex} of {this.props.total} Users </div>
              <ReactPaginate
                previousLabel={'<<'}
                nextLabel={'>>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.props.total / this.state.searchUser.pagesize}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination pagination-sm'}
                subContainerClassName={'page-item'}
                activeClassName={'active'}
              />
            </div>
          }
        </Spin>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={modalcustomStyles}
            contentLabel="Delete User">
            <span className="modal-close" onClick={this.closeModal}><img src={require('../../images/close.svg')} /></span>
            <div className="flex_box deletealert flex_column">
              {this.state.isDelete === true &&
                <span>Are you sure you want to delete the user?</span>
              }
              {this.state.isEnable === true &&
                <span>Are you sure you want to enable the user?</span>
              }
              {this.state.isEnable === false && this.state.isDelete === false && this.state.isApprove === false && this.state.isReject === false &&
                <span>Are you sure you want to disable the user?</span>
              }
              {this.state.isApprove === true &&
                <span>Are you sure you want to approve the user?</span>
              }
              {this.state.isReject === true &&
                <span>Are you sure you want to reject the user?</span>
              }
            </div>
            <div className="flex_box">
              <div className="pad_15 text-center">
                {this.state.isDelete === true &&
                  <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.deleteUser(e)}>Yes</button>
                }
                {this.state.isEnable === false && this.state.isDelete === false && this.state.isApprove === false && this.state.isReject === false &&
                  <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.disableUser(e)}>Yes</button>
                }
                {this.state.isEnable === true &&
                  <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.enableUser(e)}>Yes</button>
                }
                {this.state.isApprove === true &&
                  <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.approveUser(e)}>Yes</button>
                }
                {this.state.isReject === true &&
                  <button type="button" className="btn btn-primary m-w-90 cursor mr-2" onClick={(e) => this.rejectUser(e)}>Yes</button>
                }
                <button type="button" className="btn btn-default cursor" onClick={this.closeModal}>No</button>
              </div>
            </div>

          </Modal>
          <AntModal
            title="Manage the Name of Practices"
            id="pModal"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer={[
              <div>
                <Button key="Ok" type="primary" className="btn btn-primary m-w-15" shape='round' onClick={this.handleOk}>
                {this.state.current === 0 ? "Add New" : "Listing"}
                </Button>
                <Button key="Cancel" type="primary" className="btn btn-primary m-w-15" shape='round' onClick={this.handleCancel}>
                  Close
                </Button>
              </div>              
            ]}
          >
            <Steps
              type="navigation"
              size="small"
              current={this.state.current}
              onChange={this.onChange}
            >
              <Step key={0} title="Manage" onClick={(e) => this.setState({ current: 0 })} description="" />
              <Step key={1} title="Add New" onClick={(e) => this.setState({ current: 1 , showEditPractice: false})} description=""/>
              
              {/* <Step key={2} title="Edit/Delete" onClick={(e) => this.setState({ current: 2 })} description="" /> */}
            </Steps>

            {this.state.current === 1 &&
              <div>
                <label htmlFor="practice">Add a new Name of the Practice to the list</label>
                <Input className="form-control field-strong" id="practice" aria-describedby="practice"
                  value={practiceName}
                  suffix={this.state.nameExists === false ? addIcon : ''} onChange={(e) => this.handlePracticeNameChange(e, 'practiceName')} />
              </div>
            }

            {this.state.current === 0 && this.state.showEditPractice === false &&
              <div>                
                {
                  this.practiceNameTable(this.props.practices)
                }
              </div>
            }
            {this.state.current === 0 && this.state.showEditPractice === true &&
              <div>
                <label htmlFor="editPractice">Provide the new Name of Practice</label>
                <Input className="form-control field-strong" id="editPractice" aria-describedby="editPractice"
                  value={newPracticeName}
                  suffix={<div><Icon type="save" onClick={() => this.handlePracticeEdit(false, true)}  title="Save"/><Icon type="clear" onClick={() => this.handlePracticeEdit(false)} /></div>}
                  onChange={(e) => this.handlePracticeNameChange(e, 'newPracticeName')}
                />

              </div>
            }

          </AntModal>
        </div>
      </div>
    )
  }
}

export default withRouter(UserListComponent);
