import React from 'react'
import { connect } from 'react-redux'
import { actionSearchPatients } from '../../actions/index';
import PropTypes from 'prop-types'
import PatientList from '../../components/Patient/List';
import { actionLoadStatus, actionLoadReason } from '../../actions/lookupAction';
import { actionDeletePatient } from '../../actions/patientAction';

class PatientListContainer extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    patientList: PropTypes.array,
    total: PropTypes.number,
    patientStatuses: PropTypes.array
  }
  render() {
    console.log("props...........",this.props);
    console.log("props. location..........",this.props.location);
    let params ;
    if(this.props.location && this.props.location.search){
      params = new URLSearchParams(this.props.location.search);      
    }
    return (
      <PatientList
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        patientsList={this.props.patientsList}
        patientStatuses = {this.props.patientStatuses}
        total={this.props.total}
        fetch={this.props.fetch}
        deletePatient={this.props.deletePatientById}
        userId = {this.props.userId}
        roleId = {this.props.roleId}
        statusId = {params?params.get("statusId"):null}
        key = {params?params.get("statusId"):null}
      />
    )
  }
}
const mapStateToProps = (state) => {
  console.log('State in PatientListContainer : ', state);
  const {userId, roleId} = state.user.detail;
  const {total, patientsList, successMessage, errorMessage} = state.patient;
  const {patientStatuses} = state.lookup;
  return {
    total,
    patientsList,
    successMessage,
    errorMessage,
    patientStatuses,
    userId,
    roleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch: (searchObject, callback, rCallback) => dispatch(actionSearchPatients(searchObject, callback, rCallback)),
    deletePatientById: (patientId, callback, rCallback) => dispatch(actionDeletePatient(patientId, callback, rCallback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientListContainer)