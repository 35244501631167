import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import ProfileComponent from '../../components/User/Profile';
import { constants } from '../../constants';
import { actionChangePassword, actionImageUpload, actionGetUser } from '../../actions/userAction';

class ProfileContainer extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    detail: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      roleId: PropTypes.number
    })
  }

  render() {
    if (this.props.detail.roleId == constants.SUPER_ADMIN_ROLE) {
      return <Redirect to={constants.DASHBOARD_PAGE} />
    }
    return (<ProfileComponent detail={this.props.detail} 
      changePassword={this.props.changePassword} 
      successMessage={this.props.successMessage} 
      errorMessage={this.props.errorMessage} 
      uploadImage={this.props.uploadImage} 
      fetch = {this.props.fetch}/>)
  }
}
const mapStateToProps = (state) => {
  const { errorMessage, successMessage, detail } = state.user;
  const occupation = state.lookup.occupations.find((occupation)=>{return occupation.OccupationId == detail.occupationId});
  detail.occupationName = occupation ? occupation.OccupationName : '';
  return {
    errorMessage,
    successMessage,
    detail
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword : (userName, oldPassword, newPassword, fromEmail, callback, redirectToLogin, rCallback) => dispatch(actionChangePassword(userName, oldPassword, newPassword, fromEmail, callback, redirectToLogin, rCallback)),
    uploadImage: (file, user, callback, rCallback) => dispatch(actionImageUpload(file, user, callback, rCallback)),
    fetch: (userId, callback, rCallback) => dispatch(actionGetUser(userId, callback, rCallback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)