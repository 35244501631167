import React from 'react'
import { connect } from 'react-redux'
import { actionEditGetUser} from '../../actions/index';
import PropTypes from 'prop-types';
import UserDetailComponent from '../../components/User/Detail';
import { actionDeleteUserById, actionDisableUserById, actionEnableUserById, actionApproveUserById, actionRejectUserById, actionLoadPractice } from '../../actions/index';

class UserDetailContainer extends React.Component {
  static propTypes = {
    userEntity: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      roleId: PropTypes.number,
      occupationId: PropTypes.number
    }),
    occupations: PropTypes.array,
    practices: PropTypes.array
  }
  render() {
    return (
      <UserDetailComponent
        userEntity={this.props.userEntity}
        occupations = {this.props.occupations}
        practices = {this.props.practices}
        fetch = {this.props.fetch}
        deleteUserById={this.props.deleteUserById}
        disableUserById={this.props.disableUserById}
        enableUserById={this.props.enableUserById}
        approveUserById={this.props.approveUserById}
        rejectUserById={this.props.rejectUserById}
        prevTab={this.props.prevTab}
      />
    )
  }
}
const mapStateToProps = (state) => {
  const userEntity = state.user ? state.user.userEntity : this.props.userEntity;
  const { occupations } = state.lookup;
  const {practices} = state.lookup;
  console.log('map', userEntity)
  return {
    userEntity,
    occupations,
    practices
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch : (userId,callback, rCallback) => dispatch(actionEditGetUser(userId, callback, rCallback)),
    deleteUserById: (userId, callback, rCallback) => dispatch(actionDeleteUserById(userId, callback, rCallback)),
    disableUserById: (userId,callback, rCallback)=>dispatch(actionDisableUserById(userId, callback, rCallback)),
    enableUserById: (userId,callback, rCallback)=>dispatch(actionEnableUserById(userId, callback, rCallback)),
    rejectUserById: (userId,callback, rCallback)=>dispatch(actionRejectUserById(userId, callback, rCallback)),
    approveUserById:(userId,callback, rCallback)=>dispatch(actionApproveUserById(userId, callback, rCallback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailContainer)