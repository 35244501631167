import React from "react";
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import LoginContainer from "./containers/LoginContainer";
import DashboardContainer from "./containers/DashboardContainer";
import ChangePasswordContainer from "./containers/ChangePasswordContainer";
import AuthorizedLayoutContainer from "./containers/AuthorizedLayoutContainer"
import PatientListContainer from "./containers/Patient/List";
import AddPatientContainer from "./containers/Patient/Add";
import PatientDetailContainer from "./containers/Patient/Detail";
import UserListContainer from "./containers/User/List";
import AddUserContainer from "./containers/User/Add";
import UserDetailContainer from "./containers/User/Detail";
import ProfileContainer from "./containers/User/Profile";
import PrivateRoute from "./routes/PrivateRoute";
import store from './store'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './styles/style.css';
import './styles/custom.css';
import 'antd/dist/antd.css';

const App = props => (
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path={'/login'} component={LoginContainer} props={store.getState()} />
        <Route path={'/forgotPassword'} component={LoginContainer} />
        <Route path={'/user/sign-up'} component={LoginContainer} />
        <Route path="/changePassword" component={ChangePasswordContainer} />
        {/* Routes navigate after login */}
        <PrivateRoute path="/dashboard" 
          store={store} children={<DashboardContainer />} pageTitle={'Dashboard'}/>
        <PrivateRoute path="/catalogue/add-user/:userId" 
          store={store} children={<AddUserContainer />} pageTitle={'Edit User'}/>
        <PrivateRoute path="/catalogue/add-user" 
          store={store} children={<AddUserContainer />} pageTitle={'Add User'}/>
        <PrivateRoute path="/catalogue/users" 
          store={store} children={<UserListContainer />} pageTitle={'Users'}/>    
        <PrivateRoute path="/catalogue/patients/:page" 
          store={store} children={<PatientListContainer />} pageTitle={'Patients'}
          render={(props) => <AuthorizedLayoutContainer pageTitle={'Patients'}><PatientListContainer {...props} /></AuthorizedLayoutContainer>} />
        <PrivateRoute path="/catalogue/patients" 
          store={store} children={<PatientListContainer />} pageTitle={'Patients'}
          render={(props) => <AuthorizedLayoutContainer pageTitle={'Patients'}><PatientListContainer {...props} /></AuthorizedLayoutContainer>} /> 
        <PrivateRoute exact path="/catalogue/add-patient"
          store={store} children={<AddPatientContainer />} pageTitle={'Add Patient'}/>
        <PrivateRoute exact path="/catalogue/add-patient/:patientId/:cPage"
          store={store} children={<AddPatientContainer />} pageTitle={'Edit Patient'}/>
        <PrivateRoute exact path="/catalogue/add-patient/:patientId"
          store={store} children={<AddPatientContainer />} pageTitle={'Edit Patient'}/>
        <PrivateRoute path="/catalogue/patient/view/:patientId/:cPage" 
          store={store} children={<PatientDetailContainer />} pageTitle={'Patient Detail'}/>
        <PrivateRoute path="/catalogue/patient/view/:patientId" 
          store={store} children={<PatientDetailContainer />} pageTitle={'Patient Detail'}/>
        <PrivateRoute path="/catalogue/user/view/:userId" 
          store={store} children={<UserDetailContainer />} pageTitle={'Doctor Detail'}/>
        <PrivateRoute path="/user/profile" 
          store={store} children={<ProfileContainer />} pageTitle={'Profile'}/>
        {(store.getState().user.token) ?
          <Redirect to="/dashboard" />
          :
          <Redirect to="/login" />
        }

      </Switch>
    </BrowserRouter>
  </Provider>)

ReactDOM.render(<App />, document.getElementById('root'))



