import React from "react";
import PropTypes from 'prop-types'
import logo from '../images/logo.svg';
import logo1 from '../images/logo2.png';
import { constants } from "../constants";

export default class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    console.log('Props in LoginComponent : ', props);
    console.log('State in LoginComponent : ', this.state);
    this.state = {
      userName: '',
      password: '',
      errors: {
        userName: '',
        password: ''
      },
      successMessage: '',
      errorMessage: ''
    }
  }

  //Used for page navigation
  static contextTypes = {
    router: PropTypes.object
  }

  redirectToTarget = (target) => {
    this.context.router.history.push(target)
  }

  handleChange = (event, key) => {
    this.state[key] = event.target.value
    this.setState(this.state)
    this.setErrors();
  }

  login = () => {
    this.setErrors();
    if (!this.state.errors['userName'] || !this.state.errors['password']) {
      this.props.login(this.state.userName, this.state.password, this.loginCallback)
    }
  }

  keyPress(e) {    
    if (e.keyCode === 13 && e.target.value) {
      this.login();
    }
  }

  loginCallback = (target) => {
    this.props.loadLookUp();
    this.redirectToTarget(target);
  }

  redirectToForgotPassword = () => {
    this.props.redirectToForgotPassword();
    this.redirectToTarget(constants.FORGOT_PASSWORD_PAGE);
  }
  redirectToNewUser = () => {
    this.props.redirectToForgotPassword();
    this.redirectToTarget(constants.USER_SIGN_UP);
  }

  setErrors = () => {
    this.state.errors["userName"] = '';
    this.state.errors["password"] = '';
    if (!this.state.userName) {
      this.state.errors["userName"] = 'Enter username';
    }
    if (!this.state.password) {
      this.state.errors["password"] = 'Enter password';
    }
    this.setState(this.state);
  }

  render() {
    return <div className="login">
      <div className="bg_overlay"></div>
      <div className="container flex_box height_100">
        <div className="row login_container">
          <div className="col-md-12 login_section">
            <div className="row height_100">
              <div className="col-md-4 login_left">
              <div>
                <div className="logo-1">
                <img src={logo} alt="Avant logo" />
                </div>
                <div className="logo-2">
                <img src={logo1} alt="Avant logo" />
                </div>
              </div>
                
                <div className="login_footer"><span>contact@avant.com</span></div>
              </div>
              <div className="col-md-8 login_right flex_box flex-column">
              <h6 className="login_title">Login</h6>
                <div className="login-msg">
                  {this.props.errorMessage != '' && <span className="msg-error">{this.props.errorMessage}</span>}
                  {this.props.successMessage != '' && <span className="msg-success">{this.props.successMessage}</span>}
                </div>
                <form className="Login-form">
                  <div className="form-group">
                    <input type="text" className="form-control" id="usr" placeholder="User Name" value={this.state.userName} onChange={(e) => this.handleChange(e, 'userName')} />
                    <span className="login-error"> {this.state.errors["userName"] != '' && <span className="msg-error" >{this.state.errors["userName"]}</span>}</span>
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" id="pwd" placeholder="Password" value={this.state.password} onChange={(e) => this.handleChange(e, 'password')} onKeyDown={(e) => this.keyPress(e)}/>
                    <span className="login-error">{this.state.errors["password"] != '' && <span className="msg-error" >{this.state.errors["password"]}</span>}</span>
                  </div>
                  <div className="forgot_password">
                    <span className="cursor space_between_fields" onClick={() => this.redirectToNewUser()}>New User?</span>
                    <span className="cursor space_between_fields" onClick={() => this.redirectToForgotPassword()}>Forgot Password?</span>
                  </div>
                  <button type="button" className="login_btn btn btn-default" onClick={() => this.login()}>Login</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}
