export const patientConstants = {
    SEARCH_PATIENTS_REQUEST: 'SEARCH_PATIENTS_REQUEST',
    SEARCH_PATIENTS_SUCCESS: 'SEARCH_PATIENTS_SUCCESS',
    SEARCH_PATIENTS_FAILURE: 'SEARCH_PATIENTS_FAILURE',

    ADD_PATIENT_REQUEST: 'ADD_PATIENT_REQUEST',
    ADD_PATIENT_SUCCESS: 'ADD_PATIENT_SUCCESS',
    ADD_PATIENT_FAILURE: 'ADD_PATIENT_FAILURE',

    UPDATE_PATIENT_REQUEST: 'UPDATE_PATIENT_REQUEST',
    UPDATE_PATIENT_SUCCESS: 'UPDATE_PATIENT_SUCCESS',
    UPDATE_PATIENT_FAILURE: 'UPDATE_PATIENT_FAILURE',

    GET_PATIENT_REQUEST: 'GET_PATIENT_REQUEST',
    GET_PATIENT_SUCCESS: 'GET_PATIENT_SUCCESS',
    GET_PATIENT_FAILURE: 'GET_PATIENT_FAILURE',

    EXIST_PATIENT_REQUEST: 'EXIST_PATIENT_REQUEST',
    EXIST_PATIENT_SUCCESS: 'EXIST_PATIENT_SUCCESS',
    EXIST_PATIENT_FAILURE: 'EXIST_PATIENT_FAILURE',

    DELETE_PATIENT_REQUEST: 'DELETE_PATIENT_REQUEST',
    DELETE_PATIENT_SUCCESS: 'DELETE_PATIENT_SUCCESS',
    DELETE_PATIENT_FAILURE: 'DELETE_PATIENT_FAILURE',
    
    IMAGE_UPLOAD_REQUEST: 'IMAGE_UPLOAD_REQUEST',
    IMAGE_UPLOAD_SUCCESS: 'IMAGE_UPLOAD_SUCCESS',
    IMAGE_UPLOAD_FAILURE: 'IMAGE_UPLOAD_FAILURE',

    IMAGE_DELETE_REQUEST: 'IMAGE_DELETE_REQUEST',
    IMAGE_DELETE_SUCCESS: 'IMAGE_DELETE_SUCCESS',
    IMAGE_DELETE_FAILURE: 'IMAGE_DELETE_FAILURE',

    GET_IMAGE_REQUEST: 'GET_IMAGE_REQUEST',
    GET_IMAGE_SUCCESS: 'GET_IMAGE_SUCCESS',
    GET_IMAGE_FAILURE: 'GET_IMAGE_FAILURE',

    GET_PATIENT_STATUS_HISTORY_REQUEST: 'GET_PATIENT_STATUS_HISTORY_REQUEST',
    GET_PATIENT_STATUS_HISTORY_SUCCESS: 'GET_PATIENT_STATUS_HISTORY_SUCCESS',
    GET_PATIENT_STATUS_HISTORY_FAILURE: 'GET_PATIENT_STATUS_HISTORY_FAILURE',

}