import React from 'react';
import PropTypes from 'prop-types'
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { modalcustomStyles, fullScreencustomStyles } from '../../styles/customStyles';
import { toast } from 'react-toastify';
import * as EmailValidator from 'email-validator';
import MaskedInput from 'react-text-mask';
import { constants } from '../../constants/index';
import { PatientAPI } from '../../constants/apiConstants';
import { isValidAge } from '../../utils/common';
import Select, { components } from 'react-select';
import MultiSelectReact from 'multi-select-react';
import ImageUploader from 'react-images-upload';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { DatePicker, Input, Tooltip, Icon, Col,Modal as AntModal, Button, Timeline, Spin } from 'antd';
const { TextArea } = Input;


class AddPatientPage extends React.Component {

  constructor(props) {
    super(props)
    console.log("props---------------", props)
    this.state = {
      patientEntity: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        age: 0,
        gender: 'Male',
        currentPatientStatusId: 1,
        referralReasonId: [],
        notes: '',
        statusNotes: '',
        adminNotes : '',
        statusDate : '',
        createdBy: this.props.referredBy.userId,
        createdByName: this.props.referredBy.firstName + ' ' + this.props.referredBy.lastName,
        preferredContactMethod: 'Both'
      },
      pictures: [],
      imageDetail: {},
      emailChecked: false,
      phoneChecked: false,
      hasError: false,
      showAddConfirmModal: false,
      showDeleteConfirmModal: false,
      dragModelOpen: false,
      showImgViewModel: false,
      showStatusNotes: false,
      showStatusDate: false,
      canUpload: false,
      isUploading: false,
      date: new Date(),
      selectedReferralReasons: [],
      imageData: null,
      prevPage:0,
      dragMultiImagePrintModelOpen:false,
      isSelectImage: false,
      selectImageIndex: [],
      multiPrintImgDetail: [],
      isLoading: true
    }

    this.onChangeFile = this.onChangeFile.bind(this);
    this.showStatusModal = this.showStatusModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }
  imageIndexArr = [];
  patientImages = [];
  isEdit = false;
  isNextClicked = false;
  genderList = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Unknown', label: 'Unknown' }
  ];
  referralReasons = this.props.referralReasons.map((reason) => {
    return { label: reason.ReferralReasonName, value: reason.ReferralReasonId }
  })
  //console.log('referral reason...............', this.referralReasons)
  //Used for page navigation
  static contextTypes = {
    router: PropTypes.object
  }
  redirectToTarget = (target) => {
    if (this.props.errorMessage) {
      toast.error(() => <div>{this.props.errorMessage}</div>)
      return
    }
    if (this.props.successMessage) {
      toast.success(() => <div>{this.props.successMessage}</div>)
    }
    this.context.router.history.push(target)
  }
  redirectToTargetWithoutToast = (target) => {
    this.context.router.history.push(target)
  }
  componentDidMount() {
    this.getPatient();
  }
  getPatient = (id) => {
    const { match: { params } } = this.props;
    let patientId = params.patientId ? params.patientId : id;
    this.state.prevPage = params.cPage;
    if (params.patientId) {
      this.isEdit = true;
    }
    if (!patientId) {
      return
    }

    this.props.fetch(patientId, () => {
      this.state.patientEntity = this.props.patientEntity;
      this.state.patientEntity.createdByName = this.props.patientEntity.referredByFirstName + ' ' + this.props.patientEntity.referredByLastName;
      if (this.referralReasons) {
        this.referralReasons.forEach(rr => {
          let option = this.state.selectedReferralReasons.filter(selected => selected.value === rr.value);
          if (this.state.patientEntity.referralReasonId.includes(rr.value) && option.length <= 0) {
            this.setState({ selectedReferralReasons: this.state.selectedReferralReasons.concat(rr) })
          }
        })
      }

      if (undefined != this.state.patientEntity.currentPatientStatusId) {
        if (this.state.patientEntity.currentPatientStatusId == 2 || this.state.patientEntity.currentPatientStatusId == 5 || this.state.patientEntity.currentPatientStatusId == 7) {
          this.setState({ showStatusNotes: true });
          this.setState({ showStatusDate: false });
        } else if (this.state.patientEntity.currentPatientStatusId == 3 || this.state.patientEntity.currentPatientStatusId == 4 || this.state.patientEntity.currentPatientStatusId == 6 || this.state.patientEntity.currentPatientStatusId == 8) {
          this.setState({ showStatusNotes: false });
          this.setState({ showStatusDate: true });
        } else {
          this.setState({ showStatusNotes: false });
          this.setState({ showStatusDate: false });
        }
      }

      this.state.canUpload = true;
      this.props.loadPatientStatusHistory(this.state.patientEntity.patientId, undefined, this.redirectToTargetWithoutToast);
      this.setState(this.state);
    }, this.redirectToTargetWithoutToast)
  }

  handleCancel(){
    this.setState({
      modalVisible: false
    });
  }
  showStatusModal(){
    this.setState({
      modalVisible: true
    });
  }
  handleChange = (event, key) => {
    this.state.patientEntity[key] = event.target.value
    this.setState(this.state);
    if (undefined != 'currentPatientStatusId' && key == 'currentPatientStatusId') {
      let patientEntity = this.state.patientEntity;
      patientEntity.statusNotes='';
      patientEntity.statusDate = '';
      if (event.target.value == 2 || event.target.value == 5 || event.target.value == 7) {   
        this.setState({ showStatusNotes: true, patientEntity: patientEntity, showStatusDate: false });
      } else if (event.target.value == 3 || event.target.value == 4 || event.target.value == 6 || event.target.value == 8) {      
        this.setState({ showStatusNotes: false, patientEntity: patientEntity, showStatusDate: true });
      } else {
        this.setState({ showStatusNotes: false, showStatusDate: false });
      }
    }
  }
  handleDateChange = (event, key) => {
    this.state.patientEntity[key] = moment(event.toDate())    
    this.setState(this.state);
  }
  optionClicked(optionsList) {
    this.setState(this.state);
  }
  selectedBadgeClicked(optionsList) {
    this.setState(this.state);
  }

  handleMultiSelect = (e) => {
    console.log('option---------------', e)
    let refferralReasonIds = [];
    e.forEach(referralOption => {
      refferralReasonIds.push(referralOption.value);
    })
    this.state.selectedReferralReasons = e;
    this.state.patientEntity.referralReasonId = refferralReasonIds;
    this.setState(this.state);
    console.log(this.state.patientEntity)
  }
  handleCheck(e, key) {
    this.state[key] = e.target.checked
    this.setState(this.state);
  }
  submit = () => {
    //this.state.patientEntity.preferredContactMethod = (this.state.emailChecked && this.state.phoneChecked) ? 'both' : (this.state.emailChecked) ? 'email' : (this.state.phoneChecked) ? 'phonenumber' : null
    //this.state.patientEntity.referralReasonId = this.referralReasons.filter(rr=>rr.value === true).map(rr=>rr.id);
    this.setErrors();
    if (!this.state.hasError) {
      const { match: { params } } = this.props;
      if (params.patientId || (!this.isEdit && this.state.patientEntity.patientId)) {
        this.state.patientEntity.patientId = (params.patientId) ? params.patientId : this.state.patientEntity.patientId;
        this.state.patientEntity.imageDetails = [];
        this.setState(this.state);
        this.props.update(this.state.patientEntity, () => {
          this.redirectToTarget(constants.LIST_PATIENT_PAGE)
        }, this.redirectToTargetWithoutToast);
      } else {
        this.isNextClicked = true;
        this.props.exist(this.state.patientEntity.emailAddress, this.showAddConfirmation, this.redirectToTargetWithoutToast)
      }
    }
  }
  showAddConfirmation = (resData) => {
    if (resData.exists) {
      this.isNextClicked = false;
      this.setState({ showAddConfirmModal: true });
    } else {
      this.proceedToAdd();
    }
  }
  showDeleteImageConfirmation = (imgDetail) => {
    this.setState({ showDeleteConfirmModal: true });
    this.setState({ imageDetail: imgDetail });
    //this.deleteImage(imgDetail)
  }
  showDragView = () => {
    this.setState({ dragModelOpen: true })
  }

  showMultiImagePrint = () => {
	  this.setState({ dragMultiImagePrintModelOpen: true })
  }
  
  onChangeFile(picture) {
    console.log(".........pic")
    console.log(picture)
    this.setState({ pictures: picture });
  }
  upload = () => {
    this.closeModal();
    this.setState({ isUploading: true })
    this.state.pictures.forEach(picture => {
      this.props.upload(picture, this.state.patientEntity, this.props.referredBy.userId, this.uploadCallBack, this.redirectToTargetWithoutToast);
    });

  }
  uploadCallBack = () => {
    this.getPatient(this.state.patientEntity.patientId);
    this.setState({ isUploading: false })
    this.setState({ file: null })
  }
  getImage = (imgDetail) => {
    this.setState({ selectedImg: PatientAPI.GetImage.replace('{patientId}', imgDetail.patientId).replace('{fileName}', imgDetail.relativePath) })
    this.setState({ showImgViewModel: true })
  }
  loadImage = () => {
    this.setState({ imageData: '' });
    this.props.getImage(this.state.selectedImg, (data) => {
      this.setState({ imageData: data });
    }, this.redirectToTargetWithoutToast)
  }
  deleteImage = (imgDetail) => {
    this.closeModal();
    const postData = JSON.stringify({ patientImageId: imgDetail.patientImageId, relativePath: imgDetail.relativePath });
    this.props.deleteImage(postData, () => {
      this.getPatient(this.state.patientEntity.patientId);
    }, this.redirectToTargetWithoutToast);
  }
  closeModal = () => {
    this.setState({ showAddConfirmModal: false });
    this.setState({ dragModelOpen: false });
    this.setState({dragMultiImagePrintModelOpen: false});
    this.setState({ showImgViewModel: false });
    this.setState({ selectedImg: '' });
    this.setState({ showDeleteConfirmModal: false });
    this.setState({ imageDetail: {} });
    this.setState({ isUploading: false });
    this.setState({ file: null })
    this.setState({ pictures: [] })
    this.setState({isSelectImage: false})
    this.setState({selectImageIndex: []})
    this.imageIndexArr = []
    this.setState({multiPrintImgDetail: []});
    this.imageIndexArr = [];
    this.setState({isLoading: true});
  }
  
  selectImage = (imgDetail, i) => {
	  if(!(this.state.selectImageIndex.indexOf(i) > -1)) {
		  this.setState({selectImageIndex: this.imageIndexArr})
		  this.setState({isSelectImage: true})
		  this.imageIndexArr.push(i)
		  this.setState({isLoading: true});
		  this.props.getImage(PatientAPI.GetImage.replace('{patientId}', imgDetail.patientId).replace('{fileName}', imgDetail.relativePath), (data) => {
			  this.state.multiPrintImgDetail.push(data);
			  this.setState({isLoading: false});
		  }, this.redirectToTargetWithoutToast)
	  } else {
		  for(var index = 0;index < this.imageIndexArr.length;index++) {
			  if(this.imageIndexArr[index] == i) {
				  this.imageIndexArr.splice(index,1);
				  this.state.multiPrintImgDetail.splice(index,1);
			  }
		  }
		  this.setState({selectImageIndex: this.imageIndexArr})
	  }
  }
  
  multiImagePrint = () => {
	
	var imgSrc = '';  
	for(var index=0;index < this.state.multiPrintImgDetail.length;index++) {
		imgSrc = imgSrc + "<div align='center'> <img style='width:auto;height: calc(100vh - 16px)' alt={'Loading...'} src="+this.state.multiPrintImgDetail[index]+" /></div>";
	}
	
	let printContents, popupWin;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
	      <html>
	        <head>
	          <title>Avant</title>
	          <style>
	          </style>
	        </head>
	    <body onload="window.print();window.close()">
    		`+
    		imgSrc
    		+`
	    </body>
	      </html>`);
	      popupWin.document.close();
  }
  
  enableFullScreen = () => {
    var image = new Image();
    image.src = this.state.imageData;
    var w = window.open("");
    console.log(image.outerHTML);
    w.document.write(`
    		  <div align='center'>
    	          <img style='width:auto;height: calc(100vh - 16px)' alt={"Loading..."} src=${this.state.imageData} />
    	      </div>
    		  `);
  }

  downloadImage = () => {
    var a = document.createElement('a');
    a.href = this.state.imageData;
    a.target = '_blank';
    a.download = "image.png";
    document.body.appendChild(a);
    a.click();
  }

  printImage = () => {

    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    console.log(printContents);
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
	      <html>
	        <head>
	          <title>Avant</title>
	          <style>
	          </style>
	        </head>
	    <body onload="window.print();window.close()">
	    <div align='center'>
    	          <img style='width:auto;height: calc(100vh - 16px)' alt={"Loading..."} src=${this.state.imageData} />
    	      </div>
	    </body>
	      </html>`
    );
    popupWin.document.close();
  }

  proceedToAdd = () => {
    this.closeModal();
    this.props.add(this.state.patientEntity, (resData) => {
      this.isNextClicked = false;
      if (!this.state.canUpload) {
        this.getPatient(resData.patientId);
        this.setState({ dragModelOpen: true })
        return
      }
      this.redirectToTarget(constants.LIST_PATIENT_PAGE)
    }, this.redirectToTargetWithoutToast);
  }
  setErrors = () => {
    var error = " Required fields:  ";
    var errorContent = "";
    if (!this.state.patientEntity.firstName || this.state.patientEntity.firstName.trim().length <= 0) {
      errorContent += ',First Name';
      this.state.hasError = true;
    }
    if (!this.state.patientEntity.lastName || this.state.patientEntity.lastName.trim().length <= 0) {
      errorContent += ',Last Name';
      this.state.hasError = true;
    }
    /* if (!this.state.patientEntity.age) {
      errorContent += ',Age';
      this.state.hasError = true;
    } */
    if (!this.state.patientEntity.emailAddress && (!this.state.patientEntity.phoneNumber || this.state.patientEntity.phoneNumber.trim().length <= 0 )) {
      errorContent += ',Either Email or Phone number is mandatory';
      this.state.hasError = true;
    }  
    
    if (this.state.patientEntity.phoneNumber != undefined && null != this.state.patientEntity.phoneNumber && this.state.patientEntity.phoneNumber.length != 0 && this.state.patientEntity.phoneNumber.trim().length !=14 ) {
    	errorContent = ' Enter valid Phone Number';
        this.state.hasError = true;
        toast.error(() => <div>{errorContent.substring(1)}</div>);
        return
    }
    
    
    if (errorContent) {
      toast.error(() => <div>{error + errorContent.substring(1)}</div>);
      return
    }
    if (this.state.patientEntity.emailAddress && !EmailValidator.validate(this.state.patientEntity.emailAddress)) {
      errorContent = ' Enter valid email address';
      this.state.hasError = true;
      toast.error(() => <div>{errorContent.substring(1)}</div>);
      return
    }
    this.state.hasError = false;
  }
  getTimeLineItems(statusList){
    let timeLineItemList = [];

    statusList.forEach(status=>{
      let timeData = new Date(status.UpdatedOn);
      let description = "-"
      let dateStatuses = [3,4,6,8];
      let templateIfDataAvailable = ":status on :date"
      let templateIfNoDataAvailable = "No Date Given"
      if( status.StatusActionDate && status.StatusActionDate.trim().length > 0 && dateStatuses.includes(parseInt(status.CurrentPatientStatusId))){
        let dateData = moment(status.StatusActionDate).toDate().toLocaleDateString() + " "+ moment(status.StatusActionDate).toDate().toLocaleTimeString();
        description = templateIfDataAvailable.replace(":status",status.CurrentPatientStatusName).replace(":date",dateData)
      } else if  ( status.StatusNotes && status.StatusNotes.trim().length > 0 ){
        description = status.StatusNotes
      }
     
      timeLineItemList.push(
        <Timeline.Item dot={"."}>
          <div>
            <span><b>{status.CurrentPatientStatusName}</b></span><span style={{float: 'right'}}>{timeData.toLocaleTimeString()} <br/>{status.UpdatedDate}</span>
          </div>
          <div>
            <span className='status-notes-description'> {description} </span>
          </div>
        </Timeline.Item>
      );
    })
    return timeLineItemList;
  }

  render() {
    const { firstName, lastName, emailAddress, phoneNumber, age, gender, currentPatientStatusId, referralReasonId, notes, createdByName, statusNotes, statusDate, adminNotes } = this.state.patientEntity;
    // const Option = props => { return ( <div> <components.Option {...props}> <input type="checkbox" checked={props.isSelected} onChange={(e)=> {return e;}}/> <label>{props.label}</label> </components.Option> </div> ); };
    var momentDateVal = "";
    if(undefined != statusDate && null != statusDate && statusDate != '') {
    	momentDateVal = moment(statusDate)
    }
    
    return (<div className="edit_patient_view">
      <Spin spinning={this.props.loading} tip="Processing">
      <div className="search_panal">
        <div className="panal_header flex_btwn">
          <div className="panal_header_nav d-flex">
            <Link to={"/catalogue/patients/"+this.state.prevPage} className="breadcrumb-link"><img className="back-btn mr-3" src={require('../../images/back.svg')} />
              <div className="title-txt primary">{this.isEdit ? 'Edit ' : 'Add '}Basic Details</div></Link>
          </div>
        </div>

        
        <div className="row pad_15">        
          <div className="form-group col-md-4">
            <label htmlFor="firstname">First Name</label>
            <input type="text" className="form-control field-strong" id="firstname" aria-describedby="firstname" placeholder="Enter First Name" value={firstName} onChange={(e) => this.handleChange(e, 'firstName')} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="lastname">Last Name</label>
            <input type="text" className="form-control field-strong" id="lastname" aria-describedby="lastname" placeholder="Enter Last Name" value={lastName} onChange={(e) => this.handleChange(e, 'lastName')} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="age">Age</label>
            <input type="number" min="1" max="999" onKeyDown={(e) => isValidAge(e, age)} className="form-control field-strong" id="age" aria-describedby="age" placeholder="Enter Age" value={age} onChange={(e) => this.handleChange(e, 'age')} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="gender">Gender</label>
            <select className="form-control field-strong" id="patientGender" placeholder="Gender" value={gender} onChange={(e) => this.handleChange(e, 'gender')}>
              {this.genderList.map((gender, i) => {
                return <option key={i} value={gender.value}>{gender.label}</option>
              })}
            </select>
          </div>

          <div className="form-group col-md-4 not_allowded">
            <label htmlFor="referred">Referred by</label>
            <input type="text" className="form-control cursor-disable" id="referred" aria-describedby="referred" placeholder="Referred by" value={createdByName} disabled />
          </div>

          {/* 
        <div className="form-group col-md-4">
          <label htmlFor="sel1">Reason for Referral</label>
          <select className="form-control" id="referralReason" placeholder="Reason For Referral" value={referralReasonId} onChange = {(e) => this.handleChange(e, 'referralReasonId')}>
            { this.props.referralReasons.map((reason, i)=> {
                return <option key={i} value={reason.ReferralReasonId}>{reason.ReferralReasonName}</option>
            })}
          </select>
        </div>
        <div className="form-group col-md-4 custom_multiselect" id="Mulit-select">
          <label htmlFor="sel1">Reason for Referral</label>
          <MultiSelectReact 
            options={this.referralReasons}
            optionClicked={e=>this.optionClicked(e)}
            selectedBadgeClicked={e=>this.selectedBadgeClicked(e)}
          />
        </div>
         */}
          <div className="form-group col-md-4">
            <label htmlFor="sel1">Reason for Referral</label>
            <Select className="form-control custom_multiselect field-strong" isMulti closeMenuOnSelect={true} hideSelectedOptions={false} options={this.referralReasons} onChange={(e) => this.handleMultiSelect(e)} value={this.state.selectedReferralReasons} />
          </div>

            
          <div className="form-group col-md-4">
            <label htmlFor="emailAddress">Email Address</label>
            <input type="text" className="form-control field-strong" id="emailAddress" aria-describedby="emailAddress" placeholder="Enter Email Address" value={emailAddress} onChange={(e) => this.handleChange(e, 'emailAddress')} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="referred">Phone Number</label>
            <MaskedInput
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              className="form-control field-strong"
              placeholder="Enter a phone number"
              guide={false}
              id="phonenumber"
              aria-describedby="phonenumber"
              value={phoneNumber}
              onBlur={(e) => this.handleChange(e, 'phoneNumber')}
              onChange={(e) => this.handleChange(e, 'phoneNumber')}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="status">Status</label>
            <select className="form-control field-strong" id="patientStatus" placeholder="Patient Status" value={currentPatientStatusId} onChange={(e) => this.handleChange(e, 'currentPatientStatusId')} disabled={ (this.props.referredBy.roleId == 3 ) == true}>
              {this.props.patientStatuses.map((status, i) => {
                return <option key={i} value={status.PatientStatusId} disabled={status.PatientStatusId === 1}>{status.PatientStatusName}</option>
              })}
            </select>
          </div>

          {this.state.showStatusNotes && (this.props.referredBy.roleId == 1 || this.props.referredBy.roleId == 2) &&
            <div className="form-group col-md-8">
              <label htmlFor="statusNotes" >Status Note</label>
              <Input.Group>
                <Col xs={23}>
                  <TextArea className="form-control field-strong col-md-6" id="statusNotes"
                            aria-describedby="statusNotes" placeholder="Enter Status Notes(Max. 1000 chars)" value={statusNotes}
                            onChange={(e) => this.handleChange(e, 'statusNotes')}
                            disabled={ (this.props.referredBy.roleId == 3 ) == true}
                            maxlength ="1000"
                  />
                </Col>
                <Col xs={1}>
                  <Tooltip title="Check Status History">
                    <Icon type="history" onClick={this.showStatusModal}/>
                  </Tooltip>
                </Col>
              </Input.Group>
              
            </div>
          }

          {this.state.showStatusDate && (this.props.referredBy.roleId == 1 || this.props.referredBy.roleId == 2) &&
            <div className="form-group col-md-4">
              <label htmlFor="statusDate" >Selected Date</label>
              <Input.Group>
                <Col xs={20}>
                  <div>
                    <DatePicker showTime className="form-control field-strong m-h-40" onChange={(e) => this.handleDateChange(e, 'statusDate')} value={momentDateVal} allowClear={false}  placeholder="Enter / Pick a Date" />
                  </div>
                </Col>
                <Col xs={1}>
                  <Tooltip title="Check Status History">
                    <Icon type="history" onClick={this.showStatusModal}/>
                  </Tooltip>
                </Col>
              </Input.Group>
              
            </div>
          }


          <div className="form-group col-md-6">
            <label htmlFor="symptoms" >Symptoms/Notes</label>
            <textarea type="text" className="form-control field-strong m-h-40" id="symptoms" aria-describedby="symptoms" placeholder="Enter Symptoms/Notes" value={notes} onChange={(e) => this.handleChange(e, 'notes')} />
          </div>

          {(this.props.referredBy.roleId == 1 || this.props.referredBy.roleId == 2) &&
            <div className="form-group col-md-6">
              <label htmlFor="adminNotes" >Admin Notes</label>
              <textarea type="text" className="form-control field-strong m-h-40" id="adminNotes" aria-describedby="adminNotes" placeholder="Notes" value={adminNotes} onChange={(e) => this.handleChange(e, 'adminNotes')} />
            </div>
          }
           
        </div>
        {this.state.canUpload &&
          <div>
            <div className="panal_header flex_btwn">
              <div className="panal_header_nav">
                <span>Images</span>
              </div>
              <div className="flex-box">
              <img className="cursor" title="Print Images" onClick={this.showMultiImagePrint} src={require('../../images/Printer.svg')} />
              <img className="cursor" title="Upload Images" onClick={this.showDragView} src={require('../../images/upload.svg')} />
              </div>
            </div>
            {this.state.patientEntity.imageDetails && this.state.patientEntity.imageDetails.length > 0 ?
              <div className="image_panel pad_15 overflow-box">
                {this.state.patientEntity.imageDetails.map((patientImg, i) => {
                  return <div key={i} className="item_image">
                    <img src={patientImg.imageThumbnail} onClick={() => this.getImage(patientImg)} />
                    <span className="img-close cursor"><img src={require('../../images/close.svg')} onClick={() => this.showDeleteImageConfirmation(patientImg)//this.deleteImage(patientImg)
                    } /></span>
                  </div>
                })}
                {this.state.isUploading &&
                  <div className="item_image center margin-top-50">
                    <span className="spinner-border text-dark"></span>
                  </div>
                }
              </div>
              :
              <div>
                {this.state.isUploading ?
                  <div className="item_image center margin-top-50">
                    <span className="spinner-border text-dark"></span>
                  </div>
                  :
                  <span className="margin-left-20">No Images</span>
                }
              </div>
            }
          </div>
        }
        {this.state.canUpload ?
          <div className="pad_15 flex_end">
            <Link className="btn btn-default cancel-btn m-w-15 mr-2" to={{ pathname: `/catalogue/patients` }}>Cancel</Link>
            <button type="button" className="btn btn-primary  m-w-15" onClick={() => this.submit()}>Save</button>
          </div>
          :
          <div className="pad_15 flex_end">
            <button type="button" className="btn btn-primary" onClick={() => this.submit()}>Next</button>
          </div>
        }  
           
      </div>
      </Spin>

      <div>
        <Modal
          isOpen={this.state.dragModelOpen}
          onRequestClose={this.closeModal}
          style={modalcustomStyles}
          contentLabel="Example Modal"
        >
          <div className="model-header">
            <h2>Image Upload</h2>
            <span className="modal-close" onClick={this.closeModal}><img src={require('../../images/close.svg')} /></span>
          </div>
          <ImageUploader
            withIcon={true}
            buttonText='Choose images'
            onChange={this.onChangeFile}
            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
            maxFileSize={10485760}
            accept="accept=image/*"
            withPreview={true}
            fileContainerStyle={{
              display: 'flex ', alignItems: 'center',
              justifyContent: 'center'
            }}
            name="file"
            label='Max file size: 10 MB, Accepted: jpg|png|gif'
          />
          <div className="flex_box modal-footer">
            <button type="button" className="btn btn-primary" onClick={() => this.upload()} disabled={!this.state.pictures.length > 0}>Upload &amp; Save</button>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={this.state.showAddConfirmModal}
          onRequestClose={() => this.closeModal()}
          style={modalcustomStyles}
          contentLabel="Add Patient">
          <span className="modal-close" onClick={() => this.closeModal()}><img src={require('../../images/close.svg')} /></span>
          <div className="flex_box deletealert flex_column">
            <span>Email address already exist do you want to continue?</span>
          </div>
          <div className="flex_box">
            <div className="pad_15 text-center">
              <button type="button" className="btn btn-default cursor" onClick={() => this.proceedToAdd()}>Yes</button>
              <button type="button" className="btn btn-primary m-w-90 cursor" onClick={() => this.closeModal()}>No</button>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={this.state.showDeleteConfirmModal}
          onRequestClose={() => this.closeModal()}
          style={modalcustomStyles}
          contentLabel="Delete Image">
          <span className="modal-close" onClick={() => this.closeModal()}><img src={require('../../images/close.svg')} /></span>
          <div className="flex_box deletealert flex_column">
            <span>Are you sure you want to delete the image?</span>
          </div>
          <div className="flex_box">
            <div className="pad_15 text-center">
              <button type="button" className="btn btn-default cursor" onClick={() => this.deleteImage(this.state.imageDetail)}>Yes</button>
              <button type="button" className="btn btn-primary m-w-90 cursor" onClick={() => this.closeModal()}>No</button>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={this.state.showImgViewModel}
          onAfterOpen={this.loadImage}
          onRequestClose={this.closeModal}
          style={modalcustomStyles}
          contentLabel="View Image"
        >
          <div className="model-header">
            <h2>View Image <Icon type="download" title='Download' onClick={this.downloadImage}/>
              <Icon type="printer" title='Print' onClick={this.printImage}/>
              <Icon type="fullscreen" onClick={this.enableFullScreen}  title='FullScreen'/></h2>
            
            <a href="#" onClick={this.closeModal}><img src={require('../../images/close.svg')} /></a>


          </div>
          <div className="flex_box custom_footer_body flex_column" id="print-section">
            {this.state.selectedImg ?
              <img className="loading box-auto" alt={"Loading..."} src={this.state.imageData} />
              :
              <img src={require('../../images/image-file.svg')} />
            }
          </div>
        </Modal>
        <AntModal id="statusHistoryModal" visible={this.state.modalVisible}
          title= {  <span className="pad_15 text-center">Status History <ReactToPrint
          trigger={() => <Icon type="printer"/>}
          content={() => this.statuscomponentRef1}
          /></span>}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Close
            </Button>
          ]}
          style={{ overflowY: 'auto', height: '80%'}}>
            { this.props.patientStatusHistory &&
            <div ref={el => (this.statuscomponentRef1 = el)} style={{margin: '18px 18px 18px 18px'}} >
              <Timeline >
                {this.getTimeLineItems(this.props.patientStatusHistory)}
              </Timeline>
            </div>
                       
            }
        </AntModal>

      </div>
      
      <div>
      <Modal
        isOpen={this.state.dragMultiImagePrintModelOpen}
        onRequestClose={this.closeModal}
        style={modalcustomStyles}
        contentLabel="Example Modal"
      >
        <div className="model-header">
          <h2>Select Images</h2>
          <span className="modal-close" onClick={this.closeModal}><img src={require('../../images/close.svg')} /></span>
        </div>
        
        {this.state.patientEntity.imageDetails && this.state.patientEntity.imageDetails.length > 0 ?
                <div className="image_panel pad_15 overflow-box">
                  {this.state.patientEntity.imageDetails.map((patientImg, i) => {
                    return <div key={i} className="multi_item_image">
                    {this.state.isSelectImage && this.state.selectImageIndex.indexOf(i) > -1 &&
                    	<span className="select_image"><img src={require('../../images/tick.svg')} /></span>
                    
                    }
                    <a href="#" onClick={() => this.selectImage(patientImg,i)}><img className="img_thumb_widh" src={patientImg.imageThumbnail}/></a>
                      		</div>
                  })}
                  {this.state.isUploading &&
                    <div className="item_image center margin-top-50">
                      <span className="spinner-border text-dark"></span>
                    </div>
                  }
                </div>
                :
                <div>
                  {this.state.isUploading ?
                    <div className="item_image center margin-top-50">
                      <span className="spinner-border text-dark"></span>
                    </div>
                    :
                    <span className="margin-left-20">No Images</span>
                  }
                </div>
              }
        
        
        
        <div className="flex_box modal-footer">
        	<button type="button" className="btn btn-primary" onClick={this.multiImagePrint} disabled={this.state.isLoading}>Print</button>
        </div>
      </Modal>
    </div>
      
    </div>)
  }
}

export default withRouter(AddPatientPage);