import { patientConstants } from "../constants/patientConstants";
import { patientService } from "../services/patientService";
import {cleanStateAndRedirectToLogin} from "./userAction";

const actionSearchPatientRequest = (postData) => {
    return {
        type: patientConstants.SEARCH_PATIENTS_REQUEST,
        payload: postData
    };
}
const actionSearchPatientSuccess = (resData) => {
    return {
        type: patientConstants.SEARCH_PATIENTS_SUCCESS,
        payload: resData
    };
}
const actionSearchPatientFailure = (errData) => {
    return {
        type: patientConstants.SEARCH_PATIENTS_FAILURE,
        payload: errData
    };
}

const actionAddPatientRequest = () => {
    return {
        type: patientConstants.ADD_PATIENT_REQUEST,
        payload: {}
    };
}
const actionAddPatientSuccess = (resData) => {
    return {
        type: patientConstants.ADD_PATIENT_SUCCESS,
        payload: {}
    };
}
const actionAddPatientFailure = (errData) => {
    return {
        type: patientConstants.ADD_PATIENT_FAILURE,
        payload: {}
    };
}
const actionGetPatientRequest = (patientId) => {
    return {
        type: patientConstants.GET_PATIENT_REQUEST,
        payload: patientId
    };
}
const actionGetPatientSuccess = (resData) => {
    return {
        type: patientConstants.GET_PATIENT_SUCCESS,
        payload: resData
    };
}
const actionGetPatientFailure = (errData) => {
    return {
        type: patientConstants.GET_PATIENT_FAILURE,
        payload: errData
    };
}
const actionUpdatePatientRequest = () => {
    return {
        type: patientConstants.UPDATE_PATIENT_REQUEST,
        payload: {}
    };
}
const actionUpdatePatientSuccess = (resData) => {
    return {
        type: patientConstants.UPDATE_PATIENT_SUCCESS,
        payload: resData
    };
}
const actionUpdatePatientFailure = (errData) => {
    return {
        type: patientConstants.UPDATE_PATIENT_FAILURE,
        payload: errData
    };
}
const actionDeletePatientRequest = (patientId) => {
    return {
        type: patientConstants.DELETE_PATIENT_REQUEST,
        payload: patientId
    };
}
const actionDeletePatientSuccess = (resData) => {
    return {
        type: patientConstants.DELETE_PATIENT_SUCCESS,
        payload: resData
    };
}
const actionDeletePatientFailure = (errData) => {
    return {
        type: patientConstants.DELETE_PATIENT_FAILURE,
        payload: errData
    };
}
const actionPatientExistRequest = (emailAddress) => {
    return {
        type: patientConstants.EXIST_PATIENT_REQUEST,
        payload: emailAddress
    };
}
const actionPatientExistSuccess = (resData) => {
    return {
        type: patientConstants.EXIST_PATIENT_SUCCESS,
        payload: resData
    };
}
const actionPatientExistFailure = (errData) => {
    return {
        type: patientConstants.EXIST_PATIENT_FAILURE,
        payload: errData
    };
}

export const actionSearchPatients = (searchObject, callback, rCallback) => {
    console.log('search object------------------', searchObject)
    return (dispatch) => {
        dispatch(actionSearchPatientRequest(searchObject));
        patientService.searchPatients(searchObject).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionSearchPatientSuccess(parsedData));
                    callback(parsedData)
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    console.log(parsedData)
                    dispatch(actionSearchPatientFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}

export const actionAddNewPatient = (patientObject, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionAddPatientRequest(patientObject));
        patientService.addPatient(patientObject).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionAddPatientSuccess(parsedData));
                    callback(parsedData)
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    console.log(parsedData)
                    dispatch(actionAddPatientFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}
export const actionGetPatient = (patientId, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionGetPatientRequest(patientId));
        patientService.getPatient(patientId).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionGetPatientSuccess(parsedData));
                    callback()
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    console.log(parsedData)
                    dispatch(actionGetPatientFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}
export const actionUpdatePatient = (patientObject, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionUpdatePatientRequest(patientObject));
        patientService.updatePatient(patientObject).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionUpdatePatientSuccess(parsedData));
                    callback()
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    console.log(parsedData)
                    dispatch(actionUpdatePatientFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}
export const actionDeletePatient = (patientId, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionDeletePatientRequest(patientId));
        patientService.deletePatient(patientId).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionDeletePatientSuccess(parsedData));
                    callback(response);
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    console.log(parsedData)
                    dispatch(actionDeletePatientFailure(parsedData.message));
                    callback(response);
                });
            }
        }).catch(err => {
            callback(err);
        });
    }
}
export const actionPatientExist = (emailAddress, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionPatientExistRequest(emailAddress));
        patientService.patientExist(emailAddress).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    dispatch(actionPatientExistSuccess(parsedData));
                    callback(parsedData)
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    console.log(parsedData)
                    dispatch(actionPatientExistFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}
const actionImageUploadRequest = (patientId) => {
    return {
        type: patientConstants.IMAGE_UPLOAD_REQUEST,
        payload: { patientId: patientId }
    };
}

const actionImageUploadSuccess = (patient) => {
    return {
        type: patientConstants.IMAGE_UPLOAD_SUCCESS,
        payload: patient
    };
}

const actionImageUploadFailure = (errData) => {
    return {
        type: patientConstants.IMAGE_UPLOAD_FAILURE,
        payload: errData
    };
}
export const actionImageUpload = (file, patient, userId, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionImageUploadRequest(patient.patientId))
        patientService.uploadImage(file, patient, userId).then(response => {
            if (response.status == 200) {
                response.json().then(parsedData => {
                    dispatch(actionImageUploadSuccess(parsedData))
                    if(callback)
                        callback()
                })
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionImageUploadFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}
const actionImageDeleteRequest = (imgDetail) => {
    return {
        type: patientConstants.IMAGE_DELETE_REQUEST,
        payload: imgDetail
    };
}

const actionImageDeleteSuccess = (imgDetail) => {
    return {
        type: patientConstants.IMAGE_DELETE_SUCCESS,
        payload: imgDetail
    };
}

const actionImageDeleteFailure = (errData) => {
    return {
        type: patientConstants.IMAGE_DELETE_FAILURE,
        payload: errData
    };
}
export const actionImageDelete = (imgDetail, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionImageDeleteRequest(imgDetail))
        patientService.deleteImage(imgDetail).then(response => {
            if (response.status == 200) {
                response.json().then(parsedData => {
                    dispatch(actionImageDeleteSuccess(parsedData))
                    if(callback)
                        callback()
                })
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionImageDeleteFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}

const actionGetImageRequest = (data) => {
    return {
        type: patientConstants.GET_IMAGE_REQUEST,
        payload: data
    };
}

const actionGetImageSuccess = (data) => {
    return {
        type: patientConstants.GET_IMAGE_SUCCESS,
        payload: data
    };
}

const actionGetImageFailure = (errData) => {
    return {
        type: patientConstants.GET_IMAGE_FAILURE,
        payload: errData
    };
}
export const actionGetImage = (url, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionGetImageRequest(url))
        patientService.getPatientImage(url).then(response => {
            if (response.status == 200) {
                response.text().then(parsedData => {
                    let imageSrc = "data:image;base64," + parsedData;
                    dispatch(actionGetImageSuccess(imageSrc))
                    if(callback)
                        callback(imageSrc)
                })
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback );
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionGetImageFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}

const actionLoadPatientStatusHistoryRequest = (patientId) => {
    return {
        type: patientConstants.GET_PATIENT_STATUS_HISTORY_REQUEST,
        payload: patientId
    };
}

const actionLoadPatientStatusHistorySuccess = (statusData) => {
    return {
        type: patientConstants.GET_PATIENT_STATUS_HISTORY_SUCCESS,
        payload: statusData
    };
}

const actionLoadPatientStatusHistoryFailure = (errData) => {
    return {
        type: patientConstants.GET_PATIENT_STATUS_HISTORY_FAILURE,
        payload: errData
    };
}
export const actionLoadPatientStatusHistory = (patientId, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionLoadPatientStatusHistoryRequest(patientId))
        patientService.loadStatusHistory(patientId).then(response => {
            if (response.status == 200) {
                response.json().then(parsedData => {
                    dispatch(actionLoadPatientStatusHistorySuccess(parsedData))
                    if(callback)
                        callback()
                })
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionLoadPatientStatusHistoryFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}