import { UserAPI } from "../constants/apiConstants";
import { httpService } from './httpService'

const login = (userName, password) => {
    console.log('FETCH  LOGIN API : ')
    return httpService.httpPostBeforeAuth(`${UserAPI.Login}`, { userName: userName, password: password });
}
const logout = (userId) => {
    console.log('LOGOUT API : ')
    return httpService.httpPut(`${UserAPI.Logout}${userId}`, {});
}
const changePassword = (userName, newPassword, oldPassword, fromEmail) => {
    console.log('CHANGE PASSWORD API : ')
    return httpService.httpPost(`${UserAPI.ChangePassword}`, { userName: userName, newPassword: newPassword, oldPassword: oldPassword, fromEmail: fromEmail });
}
const forgotPassword = (userName) => {
    console.log('FORGOT PASSWORD API : ')
    return httpService.httpPostBeforeAuth(`${UserAPI.ForgotPassword}`, { userName: userName });
}
const verifyCaptchaAPI = (cToken) => {
    console.log('FORGOT PASSWORD API : ')
    return (httpService.httpPostBeforeAuth(`${UserAPI.VerifyCaptchaAPI}`, { genToken: cToken }));
}
const resetPassword = (userName) => {
    console.log('RESET PASSWORD API : ')
    return httpService.httpPost(`${UserAPI.ResetPassword}`,{userName:userName});    
}
const getUser = (userId) => {
    console.log('FETCH USER API : ')
    return httpService.httpGet(`${UserAPI.GetUser}${userId}`);
}
const getUserByUserName = (userName) => {
    console.log('FETCH USER BY USERNAME API : ')
    return httpService.httpGet(`${UserAPI.GetUserByUserName}${userName}`);
}
const addNewUser = (user) => {
    console.log('FETCH  AddNew USER API : ')
    return httpService.httpPost(`${UserAPI.AddNewUser}`, user);
}
const signupUser = (user) => {
    console.log('FETCH  AddNew USER API : ')
    return httpService.httpPost(`${UserAPI.SignupUser}`, user);
}
const updateUser = (id, user) => {
    console.log('FETCH  UpdateUser USER API : ')
    return httpService.httpPut(`${UserAPI.UpdateUser}` + '' + id, user);
}
const deleteUserById = (id) => {
    console.log('FETCH  deleteUserById USER API : ')
    return httpService.httpDelete(`${UserAPI.DeleteUser}` + '' + id);
}
const disableUserById = (id) => {
    console.log('FETCH  disableUserById USER API : ')
    return httpService.httpPost(`${UserAPI.DisableUser}` + '' + id,{});
}

const enableUserById = (id) => {
    console.log('FETCH  enableUserById USER API : ')
    return httpService.httpPost(`${UserAPI.EnableUser}` + '' + id,{});
}
const approveUserById = (id) => {
    console.log('FETCH  approveUserById USER API : ')
    return httpService.httpPost(`${UserAPI.ApproveUser}` + '' + id,{});
}
const rejectUserById = (id) => {
    console.log('FETCH  rejectUserById USER API : ')
    return httpService.httpPost(`${UserAPI.RejectUser}` + '' + id,{});
}
const searchUsers = (searchObject) => {
    console.log(searchObject);
    return httpService.httpGet(`${UserAPI.UserSearch}` + '', searchObject);
}
const uploadImage = (file, user) => {
    console.log('UPLOAD IMAGE API : ')
    const data = new FormData()
    data.append('file', file)
    return httpService.httpImagePost(`${UserAPI.UploadImage}?userid=${user.detail.userId}`, data, user.detail.userId);
}
export const userService = {
    login,
    changePassword,
    addNewUser,
    signupUser,
    verifyCaptchaAPI,
    updateUser,
    deleteUserById,
    disableUserById,
    enableUserById,
    approveUserById,
    rejectUserById,
    searchUsers,
    forgotPassword,
    logout,
    getUser,
    getUserByUserName,
    resetPassword,
    uploadImage
};