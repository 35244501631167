import { lookupConstants } from "../constants/lookupConstants";
import { lookupService } from "../services/lookupService";
import {cleanStateAndRedirectToLogin} from "./userAction";

const actionLoadReasonsRequest = () => {
    return {
        type: lookupConstants.LOAD_REASONS_REQUEST
    };
}
const actionLoadReasonsSuccess = (resData) => {
    return {
        type: lookupConstants.LOAD_REASONS_SUCCESS,
        payload: resData
    };
}
const actionLoadReasonsFailure = (errData) => {
    return {
        type: lookupConstants.LOAD_REASONS_FAILURE,
        payload: errData
    };
}
export const actionLoadReason = (rCallback) => {
    console.log('actionLoadReason........................');
    return (dispatch) => {
        dispatch(actionLoadReasonsRequest());
        lookupService.referralReason().then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionLoadReasonsSuccess(parsedData));
                });
            } else {
                response.json().then(parsedData => {
                    console.log(parsedData)
                    dispatch(actionLoadReasonsFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}
const actionLoadStatusRequest = () => {
    return {
        type: lookupConstants.LOAD_STATUS_REQUEST
    };
}
const actionLoadStatusSuccess = (resData) => {
    return {
        type: lookupConstants.LOAD_STATUS_SUCCESS,
        payload: resData
    };
}
const actionLoadStatusFailure = (errData) => {
    return {
        type: lookupConstants.LOAD_STATUS_FAILURE,
        payload: errData
    };
}
export const actionLoadStatus = (rCallback) => {
    return (dispatch) => {
        dispatch(actionLoadStatusRequest())
        lookupService.patientStatuses().then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    console.log(parsedData);
                    dispatch(actionLoadStatusSuccess(parsedData));
                });
            } else {
                response.json().then(parsedData => {
                    console.log(parsedData)
                    dispatch(actionLoadStatusFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}
const actionLoadOccupationRequest = () => {
    return {
        type: lookupConstants.LOAD_OCCUPATION_REQUEST
    };
}
const actionLoadOccupationSuccess = (resData) => {
    return {
        type: lookupConstants.LOAD_OCCUPATION_SUCCESS,
        payload: resData
    };
}
const actionLoadOccupationFailure = (errData) => {
    return {
        type: lookupConstants.LOAD_OCCUPATION_FAILURE,
        payload: errData
    };
}
export const actionLoadOccupation = (callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionLoadOccupationRequest())
        lookupService.occupations().then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    dispatch(actionLoadOccupationSuccess(parsedData));
                });
            } else {
                response.json().then(parsedData => {
                    dispatch(actionLoadOccupationFailure(parsedData.message));
                });
            }
        }).catch(err => {
        });
    }
}

const actionLoadPracticeRequest = () => {
    return {
        type: lookupConstants.LOAD_PRACTICE_REQUEST
    };
}
const actionLoadPracticeSuccess = (resData) => {
    return {
        type: lookupConstants.LOAD_PRACTICE_SUCCESS,
        payload: resData
    };
}
const actionLoadPracticeFailure = (errData) => {
    return {
        type: lookupConstants.LOAD_PRACTICE_FAILURE,
        payload: errData
    };
}
export const actionLoadPractice = (callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionLoadPracticeRequest())
        lookupService.practices().then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    dispatch(actionLoadPracticeSuccess(parsedData));                    
                });
            } else {
                response.json().then(parsedData => {
                    dispatch(actionLoadPracticeFailure(parsedData.message));                    
                });
            }
        }).catch(err => {
        });
    }
}

const actionNewPracticeRequest = () => {
    return {
        type: lookupConstants.ADD_PRACTICE_REQUEST
    };
}
const actionNewPracticeSuccess = (resData) => {
    return {
        type: lookupConstants.ADD_PRACTICE_SUCCESS,
        payload: resData
    };
}
const actionNewPracticeFailure = (errData) => {
    return {
        type: lookupConstants.ADD_PRACTICE_FAILURE,
        payload: errData
    };
}
export const actionNewPractice = (practiceName, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionNewPracticeRequest())
        lookupService.addNewPractice(practiceName).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    dispatch(actionNewPracticeSuccess(parsedData));
                    if( callback ){
                        callback();
                    }
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionNewPracticeFailure(parsedData.message));
                    if( callback ){
                        callback();
                    }
                });
            }
        }).catch(err => {
        });
    }
}

const actionDeletePracticeRequest = () => {
    return {
        type: lookupConstants.DELETE_PRACTICE_REQUEST
    };
}
const actionDeletePracticeSuccess = (resData) => {
    return {
        type: lookupConstants.DELETE_PRACTICE_SUCCESS,
        payload: resData
    };
}
const actionDeletePracticeFailure = (errData) => {
    return {
        type: lookupConstants.DELETE_PRACTICE_FAILURE,
        payload: errData
    };
}
export const actionDeletePractice = (practiceNameId, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionDeletePracticeRequest())
        lookupService.deletePractice(practiceNameId).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    dispatch(actionDeletePracticeSuccess(parsedData));
                    if( callback ){
                        callback();
                    }
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionDeletePracticeFailure(parsedData.message));
                    if( callback ){
                        callback();
                    }
                });
            }
        }).catch(err => {
        });
    }
}

const actionUpdatePracticeRequest = () => {
    return {
        type: lookupConstants.UPDATE_PRACTICE_REQUEST
    };
}
const actionUpdatePracticeSuccess = (resData) => {
    return {
        type: lookupConstants.UPDATE_PRACTICE_SUCCESS,
        payload: resData
    };
}
const actionUpdatePracticeFailure = (errData) => {
    return {
        type: lookupConstants.UPDATE_PRACTICE_FAILURE,
        payload: errData
    };
}
export const actionUpdatePractice = (practiceName, practiceNameId, callback, rCallback) => {
    return (dispatch) => {
        dispatch(actionUpdatePracticeRequest())
        lookupService.updatePractice(practiceNameId, practiceName).then(response => {
            if (response.status === 200) {
                response.json().then(parsedData => {
                    dispatch(actionUpdatePracticeSuccess(parsedData));
                    if( callback ){
                        callback();
                    }
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionUpdatePracticeFailure(parsedData.message));
                    if( callback ){
                        callback();
                    }
                });
            }
        }).catch(err => {
        });
    }
}

