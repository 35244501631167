import React from "react";
import Header from '../components/Header';
import LeftSideBar from '../components/LeftSideBar';
import { constants } from "../constants";


const AuthorizedLayout = props => {
  return <div className="container-fluid">
          <Header logout={props.logout} viewProfile={props.viewProfile} name={props.name} roleId={props.roleId} resetPassword={props.resetPassword} userId={props.userId} userName={props.userName} pageTitle={props.pageTitle}/>
            <div className="row dashboard_height">
              <div className="col-md-2 col-lg-2 left left-side-bar-bg"> 
                <LeftSideBar allowedRoles={[constants.SUPER_ADMIN_ROLE,constants.USER_ADMIN_ROLE]} roleId={props.roleId} userMenuAction={props.userMenu}/></div>
              <div className="col-md-10 col-lg-10 right pad_15">{props.children}</div>
            </div>
          </div>
}

export default AuthorizedLayout;