import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DashboardPage from '../components/DashboardPage';
import { patientStatusSummary } from '../actions/index';


class DashboardContainer extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    data: PropTypes.array
  }


  render() {
    return (
      <DashboardPage
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        data={this.props.data}
        patientStatusSummary={this.props.patientStatusSummary}
      />
    )
  }
}
const mapStateToProps = (state) => {
  console.log('State in DashboardContainer : ', state);
  const { data } = (state.dashboard != null) ? state.dashboard : null;
  return {
    data
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    patientStatusSummary: (rCallback) => dispatch(patientStatusSummary(rCallback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)