import React from 'react'

export class NoEntity extends React.Component {
    render() {
        return (
            <tr><td colSpan={this.props.colspan}><div className="no-entity flex_box">
                <div className="info-message">{this.props.message}</div>
            </div></td></tr>
        )
    }
}

export default NoEntity;