import { LookupAPI } from "../constants/apiConstants";
import { httpService } from './httpService'

const patientStatuses = () => {
    return httpService.httpGet(LookupAPI.PatientStatus);
}

const referralReason = () => {
    return httpService.httpGet(LookupAPI.ReferralReason);
}

const occupations = () => {
    return httpService.httpGet(LookupAPI.Occupation);
}

const practices = () => {
    return httpService.httpGet(LookupAPI.Practice);
}

const addNewPractice = (practiceName) => {
    return httpService.httpPost(LookupAPI.AddNewPractice,{practiceName: practiceName});
}

const deletePractice = (practiceNameId) => {
    return httpService.httpPost(LookupAPI.DeletePractice,{PracticeNameId: practiceNameId});
}

const updatePractice = (practiceNameId, practiceName) => {
    return httpService.httpPost(LookupAPI.UpdatePractice,{PracticeNameId: practiceNameId, PracticeName: practiceName});
}

export const lookupService = {
    referralReason,
    patientStatuses,
    occupations,
    practices,
    addNewPractice,
    updatePractice,
    deletePractice
}