export const constants = {
    LOGIN_PAGE: '/login',
    DASHBOARD_PAGE: '/dashboard',
    CHANGE_PASSWORD_PAGE: '/changePassword',
    FORGOT_PASSWORD_PAGE: '/forgotPassword',
    PROFILE_PAGE: '/user/profile',
    LIST_USER_PAGE: '/catalogue/users',
    USER_EDIT_PAGE: '/catalogue/add-user/{userId}',
    USER_ADD_PAGE: '/catalogue/add-user',
    USER_SIGN_UP: '/user/sign-up',
    LIST_PATIENT_PAGE: '/catalogue/patients',
    EDIT_PATIENT_PAGE: '/catalogue/add-patient/{patientId}',
    VIEW_PATIENT_PAGE: '/catalogue/patient/view/:patientId',
    VIEW_USER_PAGE: '/catalogue/user/view/:userId',
    TAB_QUERY_PARAM: '?t=:tabKey',
    PAGE_REDIRECT: 'PAGE_REDIRECT',
    CLEAR_STATE: 'CLEAR_STATE',
    SET_SUCCESS_MESSAGE: 'SET_SUCCESS_MESSAGE',
    SUPER_ADMIN_ROLE: 1,
    USER_ADMIN_ROLE: 2,
    OTHER_OCCUPATION_ID: 4,
    OCCUPATION_ID: 'occupationid',
    PRACTICE_NAME_ID:'practicenameid',
    PRACTICE_NAME:'practicename',
    NEW_PRACTICE_NAME:'newpracticename',
    IS_DISABLED: 'isDisabled',
    ALL_STRING: 'All',
    USER_APPROVED: 1,
    USER_PENDING_APP: 0,
    USER_REJECTED: 2,
    PRACTICE_ALREADY_EXISTS: 'Given Name of Practice is already exists. Please choose from the list by clicking next',
    PRACTICE_NAME_ALREADY_EXISTS: 'Given Name of Practice is already exists.',
    PRACTICE_NAME_CANT_EMPTY: 'Please provide a non-empty Name of Practice.'
};