import React from 'react'
import PropTypes from 'prop-types'
import { Spin} from 'antd'

export class ChangePassword extends React.Component {

  constructor(props,context) {
    super(props,context);    
    console.log('Props in ChangePasswordComponent : ', props);
    console.log('State in ChangePasswordComponent : ', this.state);
    let params;
    if(props.params && props.params.search){
      params = new URLSearchParams(props.params);
    }
    this.state = {
      oldPassword:params?params.get('password'):'',
      fromEmail:params?parseInt(params.get('mail')):0,
      userName:params?params.get('userName'):undefined,
      newPassword: '',
      disableOldPasswordInput: (params && params.get('password') && params.get('password').trim().length > 0)?true:false,
      confirmPassword: '',
      errors: {
        newPassword: '',
        confirmPassword: ''
      }
    }
    context.router.history.push('/changePassword');
  }

  //Used for page navigation
  static contextTypes = {
    router: PropTypes.object
  }

  redirectToTarget = (target) => {
    this.context.router.history.push(target)
  }

  handleChange = (event, key) => {
    this.state[key] = event.target.value
    this.setState(this.state)
    this.setErrors();
  }

  changePassword = () => {
    this.setErrors();
    if (this.state.errors['newPassword'] == '' || this.state.errors['confirmPassword'] == '' && !this.state.errors['oldPassword']) {
      this.props.changePassword(this.state.userName,this.state.oldPassword, this.state.newPassword, this.state.fromEmail, this.redirectToTarget, true)
    }
  }

  setErrors = () => {
    this.state.errors = {
      newPassword: '',
      confirmPassword: '',
      oldPassword:''
    }
    if (!this.state.oldPassword) {
      this.state.errors["oldPassword"] = 'Enter old password';
    }
    if (this.state.newPassword == '' || this.state.newPassword == undefined) {
      this.state.errors["newPassword"] = 'Enter new password';
    }
    if(this.state.oldPassword && this.state.newPassword && this.state.oldPassword == this.state.newPassword) {
      this.state.errors["newPassword"] = 'New password must be different';
    }
    if (this.state.confirmPassword == '' || this.state.confirmPassword == undefined) {
      this.state.errors["confirmPassword"] = 'Enter confirm password';
    }
    if (this.state.confirmPassword.length > 0 && this.state.newPassword != this.state.confirmPassword) {
      this.state.errors["confirmPassword"] = 'Password doesn\'t match';
    }
    this.setState(this.state);
  }

  render() {
    return (
      <div className="change-pass">
        <div className="bg_overlay"></div>
        <div className="container flex_box height_100">
          <div className="row login_container flex_box">
            <div className="col-md-7 login_right ">
              {this.props.errorMessage != '' &&
                <div className="error-msg">
                  <span>{this.props.errorMessage}</span>
                </div>
              }
              {this.props.successMessage != '' &&
                <div className="success-msg">
                  <span>{this.props.successMessage}</span>
                </div>
              }
              <div className="text-center">
                <h2>Choose your new password</h2>
              </div>
              <Spin spinning={this.props.loading} tip="Processing the change password request">
              <form>
                <div className="form-group col-md-12">
                  <label htmlFor="oldPassword">Old Password</label>
                  <input type="Password" className="form-control" id="oldPassword" aria-describedby="old-password" placeholder="" value={this.state.oldPassword} onChange={(e) => this.handleChange(e, 'oldPassword')} disabled={this.state.disableOldPasswordInput ? "disabled":""}/>
                  {this.state.errors["oldPassword"] &&
                    <span className="field-error">{this.state.errors["oldPassword"]}</span>
                  }
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="password">New Password</label>
                  <input type="Password" className="form-control" id="password" aria-describedby="password" placeholder="" value={this.state.newPassword} onChange={(e) => this.handleChange(e, 'newPassword')} />
                  {this.state.errors["newPassword"] != '' &&
                    <span className="field-error">{this.state.errors["newPassword"]}</span>
                  }
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="confirm-password">Confirm New Password</label>
                  <input type="Password" className="form-control" id="confirm-password" aria-describedby="confirm-password" placeholder="" value={this.state.confirmPassword} onChange={(e) => this.handleChange(e, 'confirmPassword')} />
                  {this.state.errors["confirmPassword"] != '' &&
                    <span className="field-error">{this.state.errors["confirmPassword"]}</span>
                  }
                </div>
                <div className="text-center">
                  <button type="button" className="reset-btn btn btn-default" onClick={() => this.changePassword()} >Change</button>
                </div>
              </form>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChangePassword
