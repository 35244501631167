import React from 'react'
import PropTypes from 'prop-types'
import logo from '../../images/logo.svg';
import logo1 from '../../images/logo2.png';
import { Link, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as EmailValidator from 'email-validator';
import MaskedInput from 'react-text-mask';
import { constants } from '../../constants';
import { apiConstants } from '../../constants/apiConstants'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import { userService } from "../../services/userService";
import { Spin } from 'antd';
export class SignUp extends React.Component {

    constructor(props) {
        super(props);
        console.log('Props in ForgotPasswordComponent : ', props);
        console.log('State in ForgotPasswordComponent : ', this.state);
        this.state = {
            errors: {
                userName: ''
            },
            disableSignUpButton: true,
            userEntity: {
                firstName: '',
                lastName: '',
                emailAddress: '',
                practiceName: '',
                roleId: 3,
                createdBy: 1,
                updatedBy: null,
                phoneNumber: '',
                occupationId: 2,
                otherOccupation: '',
                profilePicPath: '',
                isDisabled: 0
            },
            showOtherInput: false,
            showOccupationList: true,
            hasError: false,
            errorMessage: undefined,
            isLoading: false
        }
        this.verifyCallback = this.verifyCallback.bind(this);
    }
    componentDidMount() {
        // const { match: { params } } = this.props;
        this.props.loadOccupation(() => {
            console.log('OCCUPATION LOADED ::::', this.props.occupations);
        });
        loadReCaptcha(apiConstants.GOOGLE_CAPTCHA_SITE_KEY);        
    }
    rolesList = [
        { value: 2, label: 'Admin' },
        { value: 3, label: 'Doctor' }
    ];
    //Used for page navigation
    static contextTypes = {
        router: PropTypes.object
    }

    
    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
        console.log(recaptchaToken, "<= your recaptcha token")
        userService.verifyCaptchaAPI(recaptchaToken).then( response => {
            response.json().then(parsedData=>{
                this.setState({disableSignUpButton: !parsedData.valid})
            });
        });
        
    }
    redirectToTarget = (target) => {
        if (target === "") {
            if (this.props.errorMessage) {
                this.setState({ hasError: true, errorMessage: this.props.errorMessage });
            }
        } else {
            this.setState({ hasError: false, errorMessage: undefined });
            this.context.router.history.push(target);
        }

    }

    handleChange = (event, key) => {
        this.state.userEntity[key] = event.target.value
        if (key.toLowerCase() == constants.OCCUPATION_ID && parseInt(event.target.value) === constants.OTHER_OCCUPATION_ID) {
            this.state.showOtherInput = true
            this.state.showOccupationList = false
        } else if (key === constants.IS_DISABLED) {
            this.state.userEntity.isDisabled = event.target.checked ? 1 : 0;
        } else if (key.toLowerCase() == constants.OCCUPATION_ID) {
            this.state.showOtherInput = false;
            this.state.userEntity.otherOccupation = '';
        }
        this.setState(this.state);
    }

    resetOccupation = () => {
        this.setState({
            showOtherInput: false,
            showOccupationList: true
        });
    }

    setErrors = () => {
        var error = " Required fields:  ";
        var errorContent = "";
        if (!this.state.userEntity.firstName || this.state.userEntity.firstName.trim().length <= 0 ) {
            errorContent += ',First Name';

        }
        if (!this.state.userEntity.lastName || this.state.userEntity.lastName.trim().length <= 0) {
            errorContent += ',Last Name';
        }
        if (!this.state.userEntity.emailAddress) {
            errorContent += ',Email Address';
        }
        if (this.state.showOtherInput && (!this.state.userEntity.otherOccupation || this.state.userEntity.otherOccupation.trim().length < 1 ) ) {
            errorContent += ',Other Occupation';
        }
        if (!this.state.userEntity.phoneNumber || this.state.userEntity.phoneNumber.trim().length <= 0) {
            errorContent += ',Phone Number';
        }
        if (!this.state.userEntity.practiceName || this.state.userEntity.practiceName.trim().length <= 0) {
            errorContent += ',Name of the Practice';
        }
        if (errorContent) {
            //toast.error(() => <div>{error + errorContent.substring(1)}</div>);
            this.setState({ hasError: true, errorMessage: error + errorContent.substring(1) });
            return true;
        }
        if (this.state.userEntity.emailAddress && !EmailValidator.validate(this.state.userEntity.emailAddress)) {
            errorContent = ' Enter valid email address';
            this.setState({ hasError: true, errorMessage: errorContent });
            //toast.error(() => <div>{errorContent.substring(1)}</div>);
            return true;
        }
        if (this.state.userEntity.phoneNumber.trim().length != 14) {
        	errorContent = ' Enter valid Phone Number';
            this.setState({ hasError: true, errorMessage: errorContent });
            return true;
        }
        this.setState({ hasError: false, errorMessage: undefined });
        return false;
    }
    addUserSubmit = () => {
        let anyError = this.setErrors();
        //if (!anyError && !this.state.hasError) {
        if (!anyError) {
        	this.setState({isLoading: true})
            this.props.signupUser(this.state.userEntity, this.redirectToTarget);
        }
    }
    handleChangeSelect = (selectedOption, key) => {
        this.state.selectedRole = selectedOption;
        this.state.userEntity[key] = selectedOption.value;
        this.setState(this.state);
        console.log(`Option selected:`, selectedOption);
    }
    render() {
        const { firstName, lastName, emailAddress, practiceName, roleId, phoneNumber, occupationId, otherOccupation, isDisabled } = this.state.userEntity;
        return (
            <div className="login">
                <div className="bg_overlay"></div>
                <div className="container flex_box height_100">
                    <div className="row login_container">
                        <div className="col-md-12 login_section">
                            <div className="row height_100">
                                <div className="col-md-4 login_left">
                                    <div>
                                        <div className="logo-1">
                                            <Link to="/login"><img src={logo} alt="Avant logo" /></Link>
                                        </div>
                                        <div className="logo-2">
                                            <Link to="/login"><img src={logo1} alt="Avant logo" /></Link>
                                        </div>
                                    </div>

                                    <div className="login_footer"><span>contact@avant.com</span></div>
                                </div>
                                <div className="col-md-8 login_right ">
                                <Spin spinning={this.state.isLoading}>
                                	<div className="d-flex align-items-center justify-content-center flex-column h-100">
                                        <div className="panal_header_nav d-flex">
                                            <div className="title-txt primary">New User</div>
                                        </div>
                                        <div className="login-msg">
                                            {this.state.errorMessage && <span className="msg-error">{this.state.errorMessage}</span>}
                                            {this.state.successMessage && <span className="msg-success">{this.state.successMessage}</span>}
                                        </div>
                                        <div className="row pad_15 edit_patient_view">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="fistname">First Name</label>
                                                <input type="text" className="form-control field-strong" id="fistname" aria-describedby="fistname" placeholder="" value={firstName} onChange={(e) => this.handleChange(e, 'firstName')} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="lastname">Last Name</label>
                                                <input type="text" className="form-control field-strong" id="lastname" aria-describedby="lastname" placeholder="" value={lastName} onChange={(e) => this.handleChange(e, 'lastName')} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" className="form-control field-strong" id="email" aria-describedby="age" placeholder="" value={emailAddress} onChange={(e) => this.handleChange(e, 'emailAddress')} />
                                            </div>
                                            {/* <div className="form-group col-md-4">
                                                <label htmlFor="roleSelect">Role</label>
                                                <select className="form-control field-strong" id="role" placeholder="Select Role" value={roleId} onChange={(e) => this.handleChange(e, 'roleId')}>
                                                    {this.rolesList.map((role, i) => {
                                                        return <option key={i} value={role.value}>{role.label}</option>
                                                    })}
                                                </select>
                                            </div> */}
                                            <div className="form-group col-md-4">
                                                <label htmlFor="phonenumer">Phone Number</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className="form-control field-strong"
                                                    placeholder=""
                                                    guide={false}
                                                    id="phonenumber"
                                                    aria-describedby="phonenumber"
                                                    value={phoneNumber}
                                                    onChange={(e) => this.handleChange(e, 'phoneNumber')}
                                                />
                                            </div>
                                            {this.state.showOccupationList &&
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="occupation">Occupation</label>
                                                    <select className="form-control field-strong ffoxSelectArrowFix" id="sel1"  defaultValue={'2'} onChange={(e) => this.handleChange(e, 'occupationId')}>
                                                        {this.props.occupations.map((occupation, i) => {                                                            
                                                            return <option key={i} value={occupation.OccupationId}>{occupation.OccupationName}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            }
                                            {this.state.showOtherInput &&
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="others">Occupation</label>
                                                    <div className="flex_center">
                                                        <input type="text" className="form-control field-strong" id="others" aria-describedby="practice" placeholder="Enter Other Occupation" value={otherOccupation} onChange={(e) => this.handleChange(e, 'otherOccupation')} />
                                                        <a href="#" className="flex_box close-others" onClick={(e) => this.resetOccupation()}><img src={require('../../images/close.svg')} /></a>
                                                    </div>
                                                </div>
                                            }
                                            <div className="form-group col-md-4">
                                                <label htmlFor="practice">Name of the Practice</label>
                                                <input type="text" className="form-control field-strong" id="practice" aria-describedby="practice" placeholder="" value={practiceName} onChange={(e) => this.handleChange(e, 'practiceName')} />
                                            </div>

                                        </div>
                                        <ReCaptcha
                                            sitekey={apiConstants.GOOGLE_CAPTCHA_SITE_KEY}
                                            action='signup'
                                            verifyCallback={this.verifyCallback}
                                        />
                                        
                                        
                                        <div className="pad_15 flex_end">
                                            <button type="button" className="btn btn-default cancel-btn m-w-15 mr-2" onClick={() => this.redirectToTarget('/login')}>Cancel</button>
                                            <button type="button" className="btn btn-primary m-w-15" onClick={() => this.addUserSubmit()} disabled={this.state.disableSignUpButton }>Sign Up</button>
                                        </div>
                                        
                                    </div>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignUp
