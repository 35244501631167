import { dashboardConstants } from "../constants/dashboardConstants";
import { dashboardService } from "../services/dashboardService";
import {cleanStateAndRedirectToLogin} from "./userAction";

export const actionPatientStatusSummaryRequest = () => {
    return {
        type: dashboardConstants.PATIENT_STATUS_SUMMARY,
        payload: {}
    };
}

export const actionPatientStatusSummarySuccess = (data) => {
    console.log(data);
    return {
        type: dashboardConstants.PATIENT_STATUS_SUMMARY_SUCCESS,
        payload: data
    };
}

export const actionPatientStatusSummaryFailure = (errData) => {
    return {
        type: dashboardConstants.PATIENT_STATUS_SUMMARY_FAILED,
        payload: errData
    };
}
export const patientStatusSummary = (rCallback) => {
    return (dispatch) => {
        console.log('inside dashboard action');
        dispatch(actionPatientStatusSummaryRequest());
        dashboardService.patientStatusSummary().then(response => {
            console.log(response);
            if (response.status === 200) {
                response.json().then(parsedData => {
                    dispatch(actionPatientStatusSummarySuccess(parsedData));
                });
            } else if( response.status == 401 ) {
                response.json().then(parsedData => {
                    cleanStateAndRedirectToLogin(parsedData, dispatch, rCallback);
                });                
            } else {
                response.json().then(parsedData => {
                    dispatch(actionPatientStatusSummaryFailure(parsedData));
                });
            }
        }).catch(err => {
        });
    }
}